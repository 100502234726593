import React, { useContext, useEffect, useState } from 'react';
import Web3 from 'web3';
import { HYALIKO_ABI, HYALIKO_ADDRESS, HYALIKO_MERKLE_PROOFS_URL } from './constants';
// import { isMobile } from 'react-device-detect';
import './Gallery.css';
import { Web3AddressContext, Web3ModalContext } from './Web3Context';

const ERRORS = [
    'This can only be done after the project has been published for airdrop.',
    'This can only be done after the project has been published for whitelist.',
    'This can only be done after the project has been published.',
    'Ineligible to mint.',
    'Hyaliko cost 0.02 ETH each.',
    'Mint quantity exceeds maximum allowed.',
    'Sold out.',
    'Already claimed.',
    'Wrong merkle proof. Unlisted address or wrong quantity.'
];

function MintHyalikoAvatarSimple() {

    const [slideCount, setSlideCount] = useState(0);

    const [merkleProofs, setMerkleProofs] = useState(null);

    const { connectWeb3, web3 } = useContext(Web3ModalContext);
    const { web3Address, web3ENS } = useContext(Web3AddressContext);
    const displayWeb3Address = web3ENS || web3Address;

    // CONTRACT STUFF
    const [contract, setContract] = useState(null);
    const [airdropError, setAirdropError] = useState(null);
    const [whitelistError, setWhitelistError] = useState(null);
    const [publicSaleError, setPublicSaleError] = useState(null);
    const [numberToMint, setNumberToMint] = useState(1);
    const [loading, setLoading] = useState(false);
    const [awaitingApproval, setAwaitingApproval] = useState(false);
    const [awaitingTransaction, setAwaitingTransaction] = useState(false);
    const [awaitingModels, setAwaitingModels] = useState(false);
    // const [success, setSuccess] = useState(false);

    const formattedAirdropError = airdropError !== null ? (isNaN(airdropError) ? 'Insufficient funds or other unknown error.' : ERRORS[airdropError]) : null;
    const formattedWhitelistError = whitelistError !== null ? (isNaN(whitelistError) ? 'Insufficient funds or other unknown error.' : ERRORS[whitelistError]) : null;
    const formattedPublicSaleError = publicSaleError !== null ? (isNaN(publicSaleError) ? 'Insufficient funds or other unknown error.' : ERRORS[publicSaleError]) : null;

    useEffect(() => {
        if (web3 && web3Address) {
            setContract(new web3.eth.Contract(HYALIKO_ABI, HYALIKO_ADDRESS));
            setLoading(true);
            (fetch(`${HYALIKO_MERKLE_PROOFS_URL}?address=${web3Address}`).then(res => res.json())).then(({ merkleProofs }) => {
                setLoading(false);
                setMerkleProofs(merkleProofs);
            });
        }
    }, [web3, web3Address]);

    useEffect(() => {
        if (web3 && web3Address && contract) {
            if (merkleProofs?.airdrop?.merkleProof) {
                contract.methods.mintFromAirdrop(merkleProofs?.airdrop?.quantity, merkleProofs?.airdrop?.merkleProof).estimateGas({
                    maxPriorityFeePerGas: null,
                    maxFeePerGas: null,
                    from: web3Address
                })
                    .then(() => setAirdropError(null))
                    .catch((e: any) => {
                        console.error(e);
                        console.log(parseInt(e.message.substring('execution reverted: b:0'.length, 'execution reverted: b:0'.length + 1)) - 1);
                        setAirdropError(parseInt(e.message.substring('execution reverted: b:0'.length, 'execution reverted: b:0'.length + 1)) - 1);
                    });
            }

            if (merkleProofs?.whitelist?.merkleProof) {
                contract.methods.mintFromWhitelist(merkleProofs?.whitelist?.merkleProof).estimateGas({
                    maxPriorityFeePerGas: null,
                    maxFeePerGas: null,
                    from: web3Address
                })
                    .then(() => setWhitelistError(null))
                    .catch((e: any) => {
                        console.error(e);
                        console.log(parseInt(e.message.substring('execution reverted: b:0'.length, 'execution reverted: b:0'.length + 1)) - 1);
                        setWhitelistError(parseInt(e.message.substring('execution reverted: b:0'.length, 'execution reverted: b:0'.length + 1)) - 1);
                    });
            }

            if (numberToMint) {
                const weiValue = Web3.utils.toWei(`${0.02 * numberToMint}`);
                contract.methods.mintFromSale(numberToMint).estimateGas({
                    maxPriorityFeePerGas: null,
                    maxFeePerGas: null,
                    from: web3Address,
                    value: weiValue
                })
                    .then(() => setPublicSaleError(null))
                    .catch((e: any) => {
                        console.error(e);
                        console.log(parseInt(e.message.substring('execution reverted: b:0'.length, 'execution reverted: b:0'.length + 1)) - 1);
                        setPublicSaleError(parseInt(e.message.substring('execution reverted: b:0'.length, 'execution reverted: b:0'.length + 1)) - 1);
                    });
            }
        }
    }, [merkleProofs, numberToMint, contract, web3, web3Address]);

    useEffect(() => {
        for (let i = 1; i < 6; i++) {
            setTimeout(() => {
                setSlideCount(i);
            }, 250 * (i - 1));
        }

        // Prevent any sort of selection
        document.body.classList.add('no-select');
        const divs = document.getElementsByTagName('div');
        for (let i = 0; i < divs.length; i++) {
            divs[i].classList.add('no-select');
        }

        return () => {
            // Remove select limits
            document.body.classList.remove('no-select');
            const divs = document.getElementsByTagName('div');
            for (let i = 0; i < divs.length; i++) {
                divs[i].classList.remove('no-select');
            }
        };
    }, []);

    return (
        <div>
            <div className="intro" style={{ height: '100vh' }}>
                {(!web3Address || !web3) ? (
                    <button className="button" onClick={connectWeb3}>connect wallet to mint</button>
                ) : loading ? (
                    <div>loading...</div>
                ) : awaitingApproval ? (
                    <div>awaiting approval...</div>
                ) : awaitingTransaction ? (
                    <div>awaiting transaction...</div>
                ) : awaitingModels ? (
                    <div>finishing some things up...</div>
                ) : (
                    <>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <button className="button big-button intro-button opacity-transition" disabled={airdropError !== null || (merkleProofs?.airdrop?.quantity && merkleProofs?.airdrop?.quantity <= 0)} onClick={async () => {
                                const startingTokenIndex = await contract.methods.totalSupply().call();
                                const event = contract.methods.mintFromAirdrop(merkleProofs?.airdrop?.quantity, merkleProofs?.airdrop?.merkleProof).send({
                                    maxPriorityFeePerGas: null,
                                    maxFeePerGas: null,
                                    from: web3Address
                                });
                                setAwaitingApproval(true);

                                // go to the hyaliko minting animation
                                event.on('transactionHash', () => {
                                    // Transaction submitted. We're now awaiting approval.
                                    setAwaitingApproval(false)
                                    setAwaitingTransaction(true);
                                });
                                event.then(async () => {
                                    // Transaction has been confirmed.
                                    setAwaitingTransaction(false);
                                    setAwaitingApproval(false);
                                    setAwaitingModels(true);
                                    // setSuccess(true);

                                    // get the token IDs
                                    const tokenIds = (await contract.methods.tokensOfOwner(web3Address).call()).map((id: string) => parseInt(id)).filter((id: number) => id >= startingTokenIndex);

                                    const modelPromises = tokenIds.map((tokenId: number) => {
                                        return fetch(`https://api.hyaliko.com/hyaliko/generate-model/${tokenId}`).catch(() => { });
                                    });

                                    await Promise.all(modelPromises);
                                    setAwaitingModels(false);

                                    // Kick-off image generation
                                    tokenIds.forEach((tokenId: number) => {
                                        fetch(`https://api.hyaliko.com/hyaliko/generate-image/${tokenId}`).catch(() => { })
                                    });
                                }).catch((e: any) => {
                                    // Something went wrong. User rejected or something worse.
                                    console.error(e);
                                    setAwaitingApproval(false);
                                    setAwaitingTransaction(false);
                                });
                            }} style={{ opacity: slideCount >= 1 ? undefined : 0, transform: slideCount >= 1 ? 'translate(0, 0)' : 'translate(0, -10px)', marginBottom: 32 }}>mint airdrop</button>
                            {merkleProofs?.airdrop?.quantity && merkleProofs?.airdrop?.quantity > 0 && <div style={{ marginBottom: 32 }}>eligible to mint {merkleProofs?.airdrop?.quantity} hyalikos</div>}
                            {formattedAirdropError && <h2 className="sf-finalize-error">{formattedAirdropError}</h2>}
                        </div>
                        {false && <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <button className="button big-button intro-button opacity-transition" disabled={whitelistError !== null} onClick={() => { }} style={{ opacity: slideCount >= 1 ? undefined : 0, transform: slideCount >= 1 ? 'translate(0, 0)' : 'translate(0, -10px)', marginBottom: 32 }}>mint whitelist</button>
                            {formattedWhitelistError && <h2 className="sf-finalize-error">{formattedWhitelistError}</h2>}
                        </div>}
                        {false && <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <button className="button big-button intro-button opacity-transition" disabled={publicSaleError !== null} onClick={() => { }} style={{ opacity: slideCount >= 1 ? undefined : 0, transform: slideCount >= 1 ? 'translate(0, 0)' : 'translate(0, -10px)', marginBottom: 32 }}>mint public sale</button>
                            <input style={{ marginBottom: 32 }} autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" className="form-text-input big-text-input dialogue-box-input" value={numberToMint} onChange={e => setNumberToMint(parseInt(e.target.value || ''))} type="number" placeholder="# of identities"></input>
                            {formattedPublicSaleError && <h2 className="sf-finalize-error">{formattedPublicSaleError}</h2>}
                        </div>}
                    </>
                )}
                {displayWeb3Address && (
                    <div className="new-home-extra-small-button current-address-show-on-mobile" style={{ right: 16, display: 'flex !important' }}><span className="current-address-text">{displayWeb3Address}</span></div>
                )}
            </div>
        </div>
    );
}

export default MintHyalikoAvatarSimple;
