const colors : { [key : string]: string } = {
    LIGHT_PINK: '#FF95FF',
    PINK: '#ff67cf',
    GREEN: '#06ffa1',
    BLUE: '#0BCDFE',
    PURPLE: '#B867FF',
    WHITE: '#FFFFFF',
    BLACK: '#000000'
};

const BABYLON_COLORS = Object.keys(colors).reduce((acc: { [key: string]: BABYLON.Color3 }, colorKey: string) => {
    acc[colorKey] = BABYLON.Color3.FromHexString(colors[colorKey]);
    return acc;
}, {});

const LIGHT_PINK = BABYLON_COLORS.LIGHT_PINK.toColor4(0.9);

export { colors, BABYLON_COLORS, LIGHT_PINK };