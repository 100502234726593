import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import InputModal from './InputModal';
import trash from './img/trash.svg';
import bell from './img/bell.svg';
import './Home.css';
import './MobileHome.css';

const getBackground = (i: number) => {
    return `linear-gradient(${i * 23}deg, rgba(255,157,223,1) 0%, rgba(255,133,216,1) 15%, rgba(255,157,223,1) 62%, rgba(255,196,235,1) 120%)`;
}

const numLines = 6;

export default function MobileHome() {
    const history = useHistory();
    const [slideCount, setSlideCount] = useState(0);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [addModalValue, setAddModalValue] = useState('');
    const [address, setAddress] = useState('');
    const [pushToken, setPushToken] = useState(null);
    const [notifications, setNotifications] = useState([]);
    const inputRef = useRef(null);
    const scrollRef = useRef(null);

    useEffect(() => {
        const handleNotificationsRequest = (event: MessageEvent<any>) => {
            if (event.data && event.data.source === 'hyaliko') {
                const message = event.data;
                if (message.type === 'pushToken') {
                    if (message.content.status === 'granted') {
                        setPushToken(message.content.token);
                    }
                } else if (message.type === 'notificationsPermissionGranted') {
                    setPushToken(message.content.token);
                } else if (message.type === 'notificationsPermissionDenied') {
                    alert('notification permissions needed')
                }
            }
        };

        for (let i = 1; i < numLines; i++) {
            setTimeout(() => {
                setSlideCount(i);
            }, 250 * (i - 1));
        }

        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                type: 'requestPushToken'
            }));
        }

        window.addEventListener('message', handleNotificationsRequest);

        return () => {
            window.removeEventListener('message', handleNotificationsRequest);
        }
    }, []);

    const [galleries, setGalleries] = useState((JSON.parse(window.localStorage.getItem('galleries')) || []) as string[]);
    useEffect(() => {
        window.localStorage.setItem('galleries', JSON.stringify(galleries));
    }, [galleries]);
    useEffect(() => {
        if (pushToken) {
            fetch(`https://api.hyaliko.com/notifications?pushToken=${pushToken}`, {
                mode: 'cors',
                credentials: 'include'
            })
                .then(res => res.json())
                .then(json => {
                    setNotifications(json.map((item: any) => item.address))
                    // If there are notifications in here that aren't in the galleries, add them
                    const missingGalleries: any[] = [];
                    json.forEach((item: any) => {
                        if (!galleries.includes(item.address)) {
                            missingGalleries.push(item.address);
                        }
                    });
                    setGalleries([...galleries, ...missingGalleries]);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pushToken]);
    return (
        <>
            <div className="container mobile-home-container">
                <div className="inner-container mobile-home-galleries-container">
                    <h1 className="opacity-transition mobile-home-title" style={{ opacity: slideCount >= 1 ? 1 : 0, transform: slideCount >= 1 ? 'translate(0, 0)' : 'translate(0, -10px)' }}>hyaliko</h1>
                    <div className="mobile-home-galleries-list-container opacity-transition" style={{ opacity: slideCount >= 2 ? 1 : 0, transform: slideCount >= 2 ? 'translate(0, 0)' : 'translate(0, -10px)' }}>
                        <h2>visit your galleries</h2>
                        <div className="mobile-home-galleries-list" ref={scrollRef}>
                            {galleries.map((gallery, i) => (
                                <div className="mobile-home-gallery-container" key={gallery}>
                                    <div className="mobile-home-gallery-button" style={{ background: getBackground(i), backgroundSize: '400% 400%' }} onClick={() => history.push(`/${gallery}`)}>
                                        <div className="mobile-home-gallery">{gallery}</div>
                                    </div>
                                    <div className="mobile-home-gallery-button-row">
                                        <button className={`mobile-home-remove-button ${notifications.includes(gallery) ? '' : 'mobile-home-remove-button-inactive'}`} onClick={() => {
                                            if (!pushToken) {
                                                if (window.ReactNativeWebView) {
                                                    window.ReactNativeWebView.postMessage(JSON.stringify({
                                                        type: 'requestNotificationPermission'
                                                    }));
                                                }
                                            } else {
                                                let newNotifications;
                                                if (notifications.includes(gallery)) {
                                                    newNotifications = notifications.filter((e) => gallery !== e);
                                                } else {
                                                    newNotifications = [...notifications, gallery];
                                                }
                                                setNotifications(newNotifications);

                                                // Update on back-end
                                                fetch('https://api.hyaliko.com/setNotifications', {
                                                    method: 'POST',
                                                    mode: 'cors',
                                                    credentials: 'include',
                                                    cache: 'no-cache',
                                                    body: JSON.stringify({
                                                        pushToken,
                                                        addresses: newNotifications
                                                    })
                                                });
                                            }
                                        }}><img className="mobile-home-remove-image" src={bell} alt="" /></button>
                                        <button className="mobile-home-remove-button" onClick={() => {
                                            setGalleries(galleries.filter((e) => e !== gallery));
                                            const newNotifications = notifications.filter(e => e !== gallery);
                                            setNotifications(newNotifications);
                                            if (pushToken) {
                                                // Update on back-end
                                                fetch('https://api.hyaliko.com/setNotifications', {
                                                    method: 'POST',
                                                    mode: 'cors',
                                                    credentials: 'include',
                                                    cache: 'no-cache',
                                                    body: JSON.stringify({
                                                        pushToken,
                                                        addresses: newNotifications
                                                    })
                                                });
                                            }
                                        }}><img className="mobile-home-remove-image" src={trash} alt="" /></button>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <button className="mobile-home-gallery-add" onClick={() => { setAddModalOpen(true); setTimeout(() => inputRef.current.focus(), 100); }}>add a gallery</button>
                    </div>
                    <div className="mobile-home-another-gallery-container opacity-transition">
                        <h2 style={{ opacity: slideCount >= 3 ? 1 : 0, transform: slideCount >= 3 ? 'translate(0, 0)' : 'translate(0, -10px)' }} className="mobile-home-another-gallery-subtitle opacity-transition">visit another gallery</h2>
                        <div style={{ opacity: slideCount >= 3 ? 1 : 0, transform: slideCount >= 3 ? 'translate(0, 0)' : 'translate(0, -10px)' }} className="opacity-transition mobile-home-input-row">
                            <input autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" className="mobile-home-address-input big-text-input" value={address} maxLength={42} onChange={e => setAddress(e.target.value)} onKeyPress={e => e.key === 'Enter' && history.push(`/${address}`)} type="text" placeholder="ens / eth addr"></input>
                            <button onClick={() => history.push(`/${address}`)} disabled={!address} className="big-button continue-button"></button>
                        </div>
                    </div>
                </div>
            </div>
            <InputModal
                isOpen={addModalOpen}
                title="add a gallery"
                rightButtonOnClick={() => {
                    if (!(addModalValue.includes('.') || addModalValue.startsWith('0x'))) {
                        alert('must be an eth address or ens name');
                        return;
                    }
                    if (!galleries.includes(addModalValue)) {
                        setGalleries([...galleries, addModalValue]);
                        if (pushToken) {
                            setNotifications([...notifications, addModalValue]);
                            fetch('https://api.hyaliko.com/setNotifications', {
                                method: 'POST',
                                mode: 'cors',
                                credentials: 'include',
                                cache: 'no-cache',
                                body: JSON.stringify({
                                    pushToken,
                                    addresses: [...notifications, addModalValue]
                                })
                            });
                        }
                    }
                    setAddModalOpen(false);
                    setAddModalValue('');
                    setTimeout(() => { scrollRef.current.scrollTop = scrollRef.current.scrollHeight; }, 100);
                }}
                rightButtonTitle="add"
                leftButtonOnClick={() => { setAddModalOpen(false); setAddModalValue(''); }}
                leftButtonTitle="cancel"
                value={addModalValue}
                onChange={e => setAddModalValue(e.target.value)}
                placeholder="eth addr / ens"
                inputRef={inputRef}
            />
        </>
    );
}