import React, { useEffect, useRef, useContext, useState } from 'react';
import './BabylonContainer.css';
import * as BABYLON from 'babylonjs';
import 'babylonjs-loaders';
import 'pepjs';
import { isMobile, isFirefox } from 'react-device-detect';
import './index.css';
import { FullAnimatedGifTexture } from './AnimatedGifTexture/animatedGifTexture';
import { colors, BABYLON_COLORS, LIGHT_PINK } from './Colors';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import nipplejs from 'nipplejs';
import Menu from './Menu';
import { ChatContext, PeopleContext, ArtContext, CurateContext, CurateType } from './GalleryContext';
import { BABYLON4_BACKGROUND_COLOR_OPTIONS, BABYLON_COLOR_OPTIONS, COLOR_OPTIONS, NFT_LIMIT, NFT_BATCH_SIZE, NFT_SERVER_URL, PARTICLE_COLOR_OPTIONS, PARTICLE_SHAPE_FUNCTIONS, UPDATE_CANVASES_URL, encodeSvg, UPDATE_GALLERY_URL, CHECK_AUTHENTICATION_URL, SPACE_NAME_TO_ID } from './constants';
import { AuthenticationModalContext, Web3AddressContext } from './Web3Context';
import SwapModal from './SwapModal';
import { ErrorModalContext } from './AppContext';
// import { AsciiArtPostProcess } from 'babylonjs-post-process';

const PBRMaterialToStandardMaterial = (pbrMaterial: BABYLON.PBRMaterial, scene: BABYLON.Scene) => {
    const newMaterial = new BABYLON.StandardMaterial(pbrMaterial.name, scene);
    newMaterial.diffuseColor = pbrMaterial.albedoColor;
    newMaterial.diffuseTexture = pbrMaterial.albedoTexture;
    newMaterial.ambientColor = pbrMaterial.albedoColor;
    newMaterial.emissiveColor = pbrMaterial.emissiveColor;
    newMaterial.useAlphaFromDiffuseTexture = true;
    newMaterial.alpha = pbrMaterial.alpha;
    newMaterial.alphaMode = pbrMaterial.alphaMode;
    return newMaterial;
}

const MULTIPLAYER_ENABLED = true;

const EASE = new BABYLON.QuadraticEase();
EASE.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);

// const IS_MOBILE_APP = window.navigator.userAgent.includes('HyalikoApp');
const IS_MOBILE_APP = false;

const handleIPFS = (imageUrl: string) => {
    if (imageUrl.includes('ipfs://')) {
        imageUrl = IPFS_URL + (imageUrl.split("ipfs://ipfs/")[1] || imageUrl.split("ipfs://")[1]);
    }
    return imageUrl;
};

const getFormattedDate = () => {
    const date = new Date();
    let seconds = `${date.getSeconds()}`;
    seconds = `${seconds.length === 1 ? '0' : ''}${seconds}`;
    let minutes = `${date.getMinutes()}`;
    minutes = `${minutes.length === 1 ? '0' : ''}${minutes}`;
    return `${date.getHours()}:${minutes}:${seconds}`;
};

let DEBUG: boolean;
DEBUG = true;
DEBUG = false;

const HIGH_SPEED = false;

let hasAmmoLoaded = false;

const playerMaterials: { [key: string]: BABYLON.StandardMaterial } = {};
const faceMaterials: { [key: string]: BABYLON.StandardMaterial } = {};

const IS_MOBILE = window.innerWidth < 720;

const CAMERA_OFFSET = new BABYLON.Vector3(0, IS_MOBILE ? 10 : 12, IS_MOBILE ? -20 : -25);
const HEIGHT_OFFSET = new BABYLON.Vector3(0, IS_MOBILE ? 9 : 3.75, 0);
const CAMERA_INITIAL_ALPHA = -Math.PI / 2;
const CAMERA_INITIAL_BETA = (IS_MOBILE ? 1.8 : 1.6) * Math.PI / 4;

const MAX_ZOOM = 50;
// const DEFAULT_ZOOM = 25;
const MIN_ZOOM = 1;

// Cinematic
// const CAMERA_OFFSET = new BABYLON.Vector3(0, 2, -10);
// const HEIGHT_OFFSET = new BABYLON.Vector3(0, 0, 0);

const MAX_PLAYER_SPEED = HIGH_SPEED ? 108 : 27;
const MAX_PLAYER_VERTICAL_SPEED = HIGH_SPEED ? 1000 : 18;
const PLAYER_ACCELERATION = 1.5;
const PLAYER_STARTING_POSITION = new BABYLON.Vector3(-6, 8, 15);

const DASH_ANIMATION_LENGTH = 100;
const DASH_POWER = 100;

// const PAGE_SIZE = 5;

const CANVAS_SIZE = 12.34;
const BIG_CANVAS_SIZE = 29.6;
const CANVAS_COLLIDER_SCALE = 52;

const WEBSOCKET_URL = 'wss://www.server.oatsinteractive.net:443';
const THUMBOR_URL = 'https://www.thumbor.hyaliko.com';
const HYALIKO_TOKENS_URL = 'https://api.hyaliko.com/getHyalikoTokens';
const HYALIKO_SPACE_FACTORY_URL = 'https://api.hyaliko.com/space-factory/spaces';
const IPFS_URL = 'https://ipfs.io/ipfs/';

// These should definitely be refs
let colorToMaterial: { [key: string]: BABYLON.Material } = {};
let indexToParticleTexture: { [key: string]: BABYLON.Texture } = {};
let meshNamesToMetadata: { [key: string]: any };
let meshNamesToHoverAnimatable: { [key: string]: BABYLON.Animatable };
let meshNamesToCanvasData: { [key: string]: any } = {};
let meshNamesToTextures: { [key: string]: any } = {};
let videos: BABYLON.StandardMaterial[] = [];
const loadVideos = () => {
    while (videos.length > 0) {
        const videoMaterial = videos.pop();
        const video = videoMaterial.diffuseTexture as BABYLON.VideoTexture;
        try {
            video.video.play().catch(() => { });
            setTimeout(() => {
                video.video.pause();
                videoMaterial.alpha = 1;
            }, 500);
        } catch { }
    }
};

function getMaterialForColor(scene: BABYLON.Scene, color: string) {
    if (!colorToMaterial[color]) {
        const material = new BABYLON.StandardMaterial(color, scene);
        material.ambientColor = BABYLON.Color3.FromHexString(color);
        material.alpha = 1;
        material.alphaMode = BABYLON.Material.MATERIAL_ALPHABLEND;
        colorToMaterial[color] = material;
    }
    return colorToMaterial[color];
}

function getParticleTexture(scene: BABYLON.Scene, shape: number, color: string) {
    const key = shape + color;

    if (!indexToParticleTexture[shape]) {
        const particleTexture = BABYLON.Texture.LoadFromDataString('particleTexture' + key, PARTICLE_SHAPE_FUNCTIONS[shape](color), scene, true, true, true, BABYLON.Texture.NEAREST_SAMPLINGMODE);
        particleTexture.hasAlpha = true;
        indexToParticleTexture[key] = particleTexture;
    }
    return indexToParticleTexture[key];
}

// const createCanvas = (scene: BABYLON.Scene, isBig: boolean) => {

// };

const addLevelSegment = async function (scene: BABYLON.Scene, gallery: any, babylonMemory: BabylonMemory, levelTokens: LevelToken[], index: number, isFinal: boolean) {
    // If someone has more than one token, 
    const tokenIndex = index < levelTokens.length ? index : index % levelTokens.length;
    const tokenInstanceIndex = Math.floor(index / levelTokens.length);
    const token = levelTokens[tokenIndex];

    // Offset arrived at by adding all token offsets
    const offset = BABYLON.Vector3.Zero();
    for (let i = 0; i < index; i++) {
        const tokenOffsetIndex = i < levelTokens.length ? i : i % levelTokens.length;
        const tokenOffset = levelTokens[tokenOffsetIndex].offset;
        offset.addInPlace(new BABYLON.Vector3(tokenOffset.x, tokenOffset.y, tokenOffset.z));
    }

    const levelId = `${token.contractAddress}:${token.id}:${tokenInstanceIndex}`;
    babylonMemory.levelsById[levelId] = {
        offset
    };

    // Create canvas
    const rootFrame = scene.getMeshByName('Frame') as BABYLON.Mesh;
    const canvases = token.canvases;
    canvases.forEach((currentCanvas: any, i: number) => {
        let { x, y, z, rotW, rotX, rotY, rotZ, isBig } = currentCanvas;
        const canvasId = `${levelId}:${i}`;
        const hasCanvasPlacement = gallery.usedCanvasesMap && gallery.usedCanvasesMap[canvasId] && gallery.usedCanvasesMap[canvasId].position;
        const canvasName = `Canvas${canvasId}`;
        const plane = BABYLON.Mesh.CreatePlane(canvasName, 1, scene);
        let pos;
        if (hasCanvasPlacement) {
            const canvasPlacement = gallery.usedCanvasesMap[canvasId];
            x = canvasPlacement.position.x;
            y = canvasPlacement.position.y;
            z = canvasPlacement.position.z;
            isBig = canvasPlacement.scale === BIG_CANVAS_SIZE;
            rotW = canvasPlacement.rotation.w;
            rotX = canvasPlacement.rotation.x;
            rotY = canvasPlacement.rotation.y;
            rotZ = canvasPlacement.rotation.z;
        }
        pos = new BABYLON.Vector3(x, y, z);
        if (rotW !== undefined) {
            plane.rotation = new BABYLON.Quaternion(rotX, rotY, rotZ, rotW).toEulerAngles();
        }
        BABYLON.Tags.AddTagsTo(plane, "canvas");
        plane.scaling.normalize();
        plane.scaling.x = plane.scaling.x * (isBig ? BIG_CANVAS_SIZE : CANVAS_SIZE);
        plane.scaling.y = plane.scaling.y * (isBig ? BIG_CANVAS_SIZE : CANVAS_SIZE);
        const frame = rootFrame.createInstance(`Frame${canvasId}`);
        plane.isPickable = true;
        frame.isPickable = false;
        plane.isVisible = false;
        frame.isVisible = false;
        frame.scaling.x = 1.2;
        frame.scaling.y = 1.2;
        frame.scaling.z = 2;
        // frame.physicsImpostor = new BABYLON.PhysicsImpostor(frame, BABYLON.PhysicsImpostor.BoxImpostor, { mass: 0, restitution: 0, friction: 1 }, scene);
        plane.position = pos.add(offset);
        meshNamesToCanvasData[canvasName] = { isBig, x: plane.position.x, y: plane.position.y, z: plane.position.z, rotX: plane.rotation.x, rotY: plane.rotation.y, rotZ: plane.rotation.z, levelId: `${token.contractAddress}:${token.id}:${tokenInstanceIndex}`, levelCanvasIndex: i };
        if (!hasCanvasPlacement) {
            babylonMemory.canvases.push(canvasId);
        }
        frame.position = plane.forward.scaleInPlace(1.1);
        frame.parent = plane;
        // For starting animation
        // Might want to change to support vertical layouts
        plane.position.y -= 100;
        plane.cullingStrategy = BABYLON.AbstractMesh.CULLINGSTRATEGY_BOUNDINGSPHERE_ONLY;
    });

    let modifier = 'intermediate';
    if (index === 0) {
        modifier = 'initial';
    } else if (isFinal) {
        modifier = 'final';
    }
    const museumModelKey = `${modifier}LevelSegment`;
    const museumModel = token[museumModelKey as keyof LevelToken] as string;
    const initMesh = (mesh: BABYLON.AbstractMesh) => {
        mesh.parent = null;
        mesh.scaling = new BABYLON.Vector3(token.scale, token.scale, token.scale);
        mesh.position = BABYLON.Vector3.Zero().add(offset);
        // groundMaterial.alpha = 0.5;
        mesh.isPickable = false;
        if (!mesh.name.includes('NoPhysics')) {
            const levelPhysics = new BABYLON.PhysicsImpostor(mesh, BABYLON.PhysicsImpostor.MeshImpostor, { mass: 0, restitution: 0, friction: 0 }, scene);
            mesh.physicsImpostor = levelPhysics;
            mesh.isPickable = true;
        }
        mesh.cullingStrategy = BABYLON.AbstractMesh.CULLINGSTRATEGY_BOUNDINGSPHERE_ONLY;
    };
    const splitMuseumModelURL = museumModel.split('/');
    const rootURL = process.env.NODE_ENV === 'development' ? 'http://localhost:3000/spaces/' : splitMuseumModelURL.slice(0, splitMuseumModelURL.length - 1).join('/') + '/';
    const assetContainer = await BABYLON.SceneLoader.LoadAssetContainerAsync(rootURL, splitMuseumModelURL[splitMuseumModelURL.length - 1], scene);
    const meshes = assetContainer.meshes;
    meshes.forEach(mesh => {
        BABYLON.Tags.AddTagsTo(mesh, "level");
        mesh.position = BABYLON.Vector3.Zero().add(offset);
        mesh.material = getMaterialForColor(scene, token.isSpaceFactory ? COLOR_OPTIONS[token.terrain] : colors.PINK);
        initMesh(mesh);
    });
    assetContainer.addAllToScene();
}

const createPlayer = function (scene: BABYLON.Scene, isStatic: boolean, name: string, color: string, face: { type: string, image: string, uri: string, id: string }, id?: string) {
    const isManny = face.type === 'mannys.game';
    const isHyaliko = face.type === 'hyaliko particle forge';
    const isWizard = face.type === 'Forgotten Runes Wizards Cult';
    const isSprite = face.type === 'Spritely - Genesis Collection';
    const is2D = isWizard || isSprite;
    let model = "hyalikobasicnew.obj";
    if (isManny) {
        model = 'manny.glb';
    } else if (face.type === 'Cool Cats NFT') {
        model = 'TV.obj';
    } else if (isWizard) {
        model = 'wizard.obj';
    } else if (isSprite) {
        model = 'Sprite.obj';
    } else if (isHyaliko) {
        // Get the model based on the body type
        model = `https://api.hyaliko.com/hyaliko/models/${face.id}`;
    }

    const modelPromise = isHyaliko ? BABYLON.SceneLoader.ImportMeshAsync(
        "",
        model,
        "",
        scene,
        null,
        '.gltf'
    ) : BABYLON.SceneLoader.LoadAssetContainerAsync("/models/", model, scene);

    return modelPromise.then(async (result) => {
        if (isHyaliko) {
            result.meshes.forEach(mesh => {
                if (mesh.material && mesh.material.getClassName() === 'PBRMaterial') {
                    // Convert default GLTF PBR materials to standard materials for performance
                    mesh.material = PBRMaterialToStandardMaterial(mesh.material as BABYLON.PBRMaterial, scene);
                }
            });
        }

        const isImportedFace = face.type !== 'face';
        let playerMesh = (isHyaliko || isManny) ? BABYLON.MeshBuilder.CreateCylinder('temporaryName', { height: 1.5, diameter: 1.5 }) : result.meshes[1];

        if (isHyaliko) {
            result.meshes[0].parent = playerMesh;
            playerMesh.lookAt(playerMesh.forward.scale(-1));
            playerMesh.isVisible = false;
        }

        // Get the manny texture
        if (isManny) {
            const rootMesh = result.meshes[0];
            rootMesh.parent = playerMesh;
            rootMesh.lookAt(playerMesh.forward.scale(-1));
            rootMesh.position.y -= 1;
            rootMesh.scaling.scaleInPlace(2.5);
            
            
            playerMesh.isVisible = false;
            

            const mannyMesh = result.meshes[1].subMeshes[0].getMesh();
            fetch(face.uri).then(res => res.json()).then(json => {
                // (playerMesh.material as BABYLON.PBRMaterial).albedoTexture = new BABYLON.Texture(handleIPFS(json.texture_url), scene);
                const newTexture = new BABYLON.Texture(handleIPFS(json.texture_url), scene, false, false, BABYLON.Texture.EXPLICIT_MODE, () => { (mannyMesh.material as BABYLON.PBRMaterial).albedoTexture = newTexture });
            }).catch(() => { })
        }

        // Get the wizard texture
        if (is2D) {
            playerMesh = result.meshes[1].subMeshes[0].getMesh();

            // Tag the mesh to do rotation tricks later
            BABYLON.Tags.AddTagsTo(playerMesh, 'avatar2D');
            if (isWizard) {
                BABYLON.Tags.AddTagsTo(playerMesh, 'wizard');
            }
            if (isSprite) {
                BABYLON.Tags.AddTagsTo(playerMesh, 'sprite');
            }
            playerMesh.isVisible = false;

            let uri;
            if (isWizard) {
                uri = `https://www.forgottenrunes.com/api/art/wizards/${face.id}/spritesheet.png`;
            } else if (isSprite) {
                uri = `https://gateway.pinata.cloud/ipfs/QmQ3qnJKoWbR9CdxZPbQWWuqYVTj6Rz6HqVoCq9zkYT2Dr/${face.id}.svg`;
            }

            let newTexture: BABYLON.Texture;
            if (isSprite && isFirefox) {
                const res = await fetch(uri);
                let svgText = await res.text();
                const parser = new DOMParser();
                const svg = parser.parseFromString(svgText, "image/svg+xml").documentElement;
                const width = svg.getAttribute('width');
                const height = svg.getAttribute('height');
                const viewBox = svg.getAttribute('viewBox');
                if (!width && !height && viewBox) {
                    const splitViewBox = viewBox.split(" ");
                    const viewBoxWidth = splitViewBox[2];
                    const viewBoxHeight = splitViewBox[3];
                    svg.setAttribute('width', viewBoxWidth);
                    svg.setAttribute('height', viewBoxHeight);
                    svgText = svg.outerHTML;
                }
                newTexture = BABYLON.Texture.LoadFromDataString(uri, encodeSvg(svgText), scene, true, true, true, BABYLON.Texture.NEAREST_SAMPLINGMODE, () => { (playerMesh.material as BABYLON.StandardMaterial).diffuseTexture = newTexture; playerMesh.isVisible = true; });
            } else {
                newTexture = new BABYLON.Texture(uri, scene, false, true, BABYLON.Texture.EXPLICIT_MODE, () => { (playerMesh.material as BABYLON.StandardMaterial).diffuseTexture = newTexture; playerMesh.isVisible = true; });

            }
            newTexture.hasAlpha = true;
            if (isWizard) {
                newTexture.uScale = 1 / 20;
                newTexture.vScale = 1 / 20;
                // Start at 0.2 and then subtract 0.25
                newTexture.uOffset = 0.2;
                // Start at -0.25 and move in intervals of 0.25
                newTexture.vOffset = -0.25;
            }
        }

        result.meshes.forEach(mesh => BABYLON.Tags.AddTagsTo(mesh, 'player'));


        if (face.type !== 'mannys.game' && !is2D && !isHyaliko) { // TODO be more specific about the conditions here
            playerMesh.material = playerMaterials[color];
        }

        playerMesh.parent = null;
        playerMesh.name = `player${id || ''}`;

        // Particle system
        const particles = new BABYLON.ParticleSystem('particles', 200, scene);
        particles.maxScaleX = 0.5;
        particles.maxScaleY = 0.5;
        particles.minScaleX = 0.5;
        particles.minScaleY = 0.5;
        particles.particleTexture = new BABYLON.Texture('/textures/Flare.png', scene);
        if (isHyaliko) {
            particles.color1 = BABYLON.Color3.White().toColor4();
            particles.color2 = BABYLON.Color3.White().toColor4();
        } else {
            particles.color1 = BABYLON_COLORS[color].toColor4();
            particles.color2 = BABYLON_COLORS[color].toColor4();
        }
        
        if (face.type !== 'mannys.game' && !is2D) {
            particles.emitRate *= 10;
        }
        particles.maxLifeTime /= 100;
        particles.emitter = playerMesh;
        if (face.type === 'mannys.game' || isWizard) {
            particles.createBoxEmitter(BABYLON.Vector3.UpReadOnly, BABYLON.Vector3.UpReadOnly, new BABYLON.Vector3(-0.5, -0.5, -0.5), new BABYLON.Vector3(0.5, 1.5, 0.5));
        } else if (isSprite) {
            particles.createBoxEmitter(BABYLON.Vector3.UpReadOnly, BABYLON.Vector3.UpReadOnly, new BABYLON.Vector3(-0.5, 0.5, -0.5), new BABYLON.Vector3(0.5, 1.5, 0.5));
        } else if (isSprite) {
        } else {
            particles.createSphereEmitter(0.1, 1);
        }
        particles.start();

        const emoteParticles = new BABYLON.ParticleSystem(`emoteParticles${id || ''}`, 200, scene);
        emoteParticles.maxScaleX = 1.5;
        emoteParticles.maxScaleY = 1.5;
        emoteParticles.minScaleX = 1.5;
        emoteParticles.minScaleY = 1.5;
        emoteParticles.minEmitPower = 12;
        emoteParticles.maxEmitPower = 10;
        emoteParticles.particleTexture = new BABYLON.Texture('/textures/Heart.png', scene);
        emoteParticles.color1 = BABYLON.Color3.White().toColor4(1);
        emoteParticles.color2 = emoteParticles.color1;
        emoteParticles.emitRate *= 10;
        emoteParticles.targetStopDuration = 0.1;
        emoteParticles.emitter = playerMesh;
        emoteParticles.createSphereEmitter(0.1, 1);
        emoteParticles.start();


        // Create face 
        let playerFace = isHyaliko ? result.meshes[1] : result.meshes[2];
        if (!isImportedFace) {
            playerFace.visibility = 0;
            playerFace = BABYLON.Mesh.CreateDisc(`playerFace${id || ''}`, 0.56, 16, scene);
            playerFace.rotation.y = Math.PI;
            playerFace.rotation.z = Math.PI;
            playerFace.position.z += 1;
            playerFace.scaling = new BABYLON.Vector3(-1, 1, 1);
        }
        // If it's one of our faces, use the existing material. Otherwise, create a material and texture.
        if (!isImportedFace) {
            playerFace.material = faceMaterials[face.image]
        } else if (!isHyaliko) {
            // If it's a gif, use gif texture.
            const faceMaterial = new BABYLON.StandardMaterial(`playerFaceMaterial${id || ''}`, scene);
            faceMaterial.emissiveColor = BABYLON.Color3.White();
            faceMaterial.alphaMode = BABYLON.Material.MATERIAL_ALPHABLEND;
            let faceTexture: any;
            // TODO Determine gifness
            if (face.type === '0xmons') {
                faceTexture = new FullAnimatedGifTexture(face.image, scene.getEngine());
                faceMaterial.diffuseColor = BABYLON.Color3.Black();
                faceMaterial.specularColor = BABYLON.Color3.Black();
                faceMaterial.ambientColor = BABYLON.Color3.Black()
                playerMesh.material = playerMaterials['BLACK'];
                playerMesh.material.alpha = 1;
                (playerMesh.material as BABYLON.StandardMaterial).emissiveColor = BABYLON.Color3.Black();
                (playerMesh.material as BABYLON.StandardMaterial).diffuseColor = BABYLON.Color3.Black();
                (playerMesh.material as BABYLON.StandardMaterial).specularColor = BABYLON.Color3.Black();
                (playerMesh.material as BABYLON.StandardMaterial).ambientColor = BABYLON.Color3.Black();
            } else if (face.type === 'Blitmap' || face.type === 'bktr.io' || face.type === 'Pixelglyphs' || face.type === 'Generativemasks') {
                faceTexture = new BABYLON.Texture(face.image, scene, false, true, BABYLON.Texture.NEAREST_SAMPLINGMODE, () => {
                    const pixels = faceTexture.readPixels();
                    const color = new BABYLON.Color3(pixels[pixels.length - 4] / 255, pixels[pixels.length - 3] / 255, pixels[pixels.length - 2] / 255);
                    const material = new BABYLON.StandardMaterial('player' + face.image, scene);
                    material.diffuseColor = color;
                    material.emissiveColor = color;
                    // material.specularColor = color;
                    // material.ambientColor = color;
                    material.alpha = 1;
                    playerMesh.material = material;
                });
            } else if (face.type === 'Cool Cats NFT') {
                faceTexture = new BABYLON.Texture(face.image, scene, false, true, BABYLON.Texture.NEAREST_SAMPLINGMODE, () => {
                    playerFace.scaling.x = -1;
                    const color = BABYLON.Color3.FromHexString('#90CFF0');
                    const material = new BABYLON.StandardMaterial('player' + face.image, scene);
                    material.diffuseColor = color;
                    material.emissiveColor = color;
                    material.alpha = 1;
                    result.meshes[1].material = material;
                    result.meshes.forEach((m, i) => {
                        if (i > 1) {
                            m.parent = playerMesh;
                        }
                    })
                });
            } else if (face.type === 'mannys.game' || is2D) {
                const ambientColor = BABYLON.Color3.FromHexString('#FFFFFF');
                result.meshes.forEach((m, i) => {
                    if (m.material) {
                        (m.material as BABYLON.StandardMaterial).ambientColor = ambientColor;
                    }
                });
            }
            if (face.type !== 'mannys.game' && !is2D) {
                faceMaterial.diffuseTexture = faceTexture;
                playerFace.material = faceMaterial;
            }
            if (is2D) {
                (playerMesh.material as BABYLON.StandardMaterial).emissiveColor = BABYLON.Color3.FromHexString('#FFFFFF');
            }
        }

        if (playerFace && !isHyaliko && !isManny) {
            playerFace.parent = playerMesh;
        }

        const spotLight = new BABYLON.SpotLight("spot", new BABYLON.Vector3(0, -1, 0), BABYLON.Vector3.UpReadOnly.scale(-1), Math.PI, 1000, scene);
        spotLight.exponent = 16;
        spotLight.range = 100;
        spotLight.parent = playerMesh;

        let playerName = null;
        if (name) {
            const planeWidth = (name.length + 1) * 0.8;
            playerName = BABYLON.Mesh.CreatePlane(`playerName${id || ''}`, planeWidth, scene);
            const heightMultiplier = planeWidth / 1.5;
            playerName.scaling.y = playerName.scaling.y / heightMultiplier;

            playerName.isPickable = false;

            const playerNameMaterial = new BABYLON.StandardMaterial(`playerNameMaterial${id || ''}`, scene);
            // playerNameMaterial.useAlphaFromDiffuseTexture = true;
            playerNameMaterial.diffuseColor = BABYLON.Color3.White();
            playerNameMaterial.ambientColor = BABYLON.Color3.White();
            playerNameMaterial.emissiveColor = BABYLON.Color3.White();
            playerNameMaterial.alpha = 0.9;

            // const paddedPlayerName = `${' '.repeat(Math.floor((15 - name.length) / 2))}${name}${' '.repeat(Math.floor((15 - name.length) / 2))}`;
            const textureWidth = planeWidth * 70
            const playerNameTexture = new BABYLON.DynamicTexture('playerNameTexture', { width: textureWidth, height: textureWidth / heightMultiplier }, scene, false);
            const context = playerNameTexture.getContext();
            // context.textAlign = "center";
            // context.strokeStyle = 'black';
            // context.lineWidth = 1;
            context.save();

            playerNameTexture.hasAlpha = true;
            // playerNameTexture.clear()
            // playerNameTexture.getContext().clearRect(0, 0, 512, 512)
            playerNameTexture.drawText(' ' + name, 0, 75, 'bold 80px monospace', '#ffffff', '#ff67cf', true, true);
            // playerNameTexture.update();
            playerNameMaterial.diffuseTexture = playerNameTexture;

            playerName.material = playerNameMaterial;

            playerName.isVisible = false;
        }

        const playerScale = 1.25;
        playerMesh.scaling = new BABYLON.Vector3(playerScale, playerScale, playerScale);

        playerMesh.lookAt(playerMesh.forward.scale(-1));

        playerMesh.position = PLAYER_STARTING_POSITION.clone();
        // For the initial spawn, give a little offset
        if (!id) {
            playerMesh.position.x += 4 - (Math.random() * 8);
            playerMesh.position.y += (Math.random() * 5);
        }

        // if (!isStatic) {
        let playerPhysics
        if (isWizard) {
            playerPhysics = new BABYLON.PhysicsImpostor(playerMesh, BABYLON.PhysicsImpostor.CylinderImpostor, { mass: isStatic ? 0 : 1, restitution: 0, friction: 0 }, scene);
            playerPhysics.setDeltaPosition(new BABYLON.Vector3(0, -2, 0));
            playerMesh.physicsImpostor = playerPhysics;
            // playerMesh.scaling.x *= 1.7;
            // playerMesh.scaling.y *= 1.5;
            playerMesh.scaling.z *= 100;
            playerPhysics.setScalingUpdated();
            // playerMesh.scaling.x /= 1.7;
            // playerMesh.scaling.y /= 1.5;
            playerMesh.scaling.z /= 10000;
        } else if (isSprite) {
            playerPhysics = new BABYLON.PhysicsImpostor(playerMesh, BABYLON.PhysicsImpostor.CylinderImpostor, { mass: isStatic ? 0 : 1, restitution: 0, friction: 0 }, scene);
            playerPhysics.setDeltaPosition(new BABYLON.Vector3(0, -1, 0));
            playerMesh.physicsImpostor = playerPhysics;
            // playerMesh.scaling.x *= 1.7;
            // playerMesh.scaling.y *= 1.5;
            playerMesh.scaling.z *= 100;
            playerPhysics.setScalingUpdated();
            // playerMesh.scaling.x /= 1.7;
            // playerMesh.scaling.y /= 1.5;
            playerMesh.scaling.z /= 10000;
            playerMesh.scaling.x = -2;
            playerMesh.scaling.y = 2;
        } else if (isHyaliko) {
            // TODO make sure names make sense here
            const tempScaling = playerMesh.scaling.z;
            playerMesh.scaling.z = 0.25;
            let playerPhysics;
            playerPhysics = new BABYLON.PhysicsImpostor(playerMesh, BABYLON.PhysicsImpostor.CylinderImpostor, { mass: isStatic ? 0 : 1, restitution: 0, friction: 0 }, scene);
            playerMesh.physicsImpostor = playerPhysics;
            playerMesh.scaling.z = tempScaling;
        } else if (isManny) {
            playerPhysics = new BABYLON.PhysicsImpostor(playerMesh, BABYLON.PhysicsImpostor.CylinderImpostor, { mass: isStatic ? 0 : 1, restitution: 0, friction: 0 }, scene);
            const tempScaling = playerMesh.scaling.y;
            playerMesh.scaling.y *= 3;
            playerPhysics.setScalingUpdated();
            playerMesh.physicsImpostor = playerPhysics;
            playerMesh.scaling.y = tempScaling;
            playerPhysics.setDeltaPosition(new BABYLON.Vector3(0, -2, 0));
        } else {
            playerPhysics = new BABYLON.PhysicsImpostor(playerMesh, BABYLON.PhysicsImpostor.SphereImpostor, { mass: isStatic ? 0 : 1, restitution: 0, friction: 0 }, scene);
            playerMesh.physicsImpostor = playerPhysics;
        }

        // } else {
        //     playerMesh.rotationQuaternion = playerMesh.rotation.toQuaternion();
        // }

        if (!isHyaliko) {
            (result as any).addAllToScene();
        }

        playerMesh.isPickable = true;
        if (playerFace) {
            playerFace.isPickable = true;
        }

        if (isSprite) {
            playerMesh.material.transparencyMode = 2;
            (playerMesh.material as BABYLON.StandardMaterial).useAlphaFromDiffuseTexture = true;
        }

        return { playerMesh, playerNameMesh: playerName };
    });
};

// CreateScene function that creates and return the scene
const createScene = function (engine: BABYLON.Engine, babylonMemory: BabylonMemory, gallery: any, levelTokens: LevelToken[]) {
    // Create a basic BJS Scene object
    const scene = new BABYLON.Scene(engine);

    const gravityVector = new BABYLON.Vector3(0, -9.81 * 2, 0);

    const camera = new BABYLON.ArcRotateCamera('camera1', CAMERA_INITIAL_ALPHA, CAMERA_INITIAL_BETA, IS_MOBILE ? 20 : 25, PLAYER_STARTING_POSITION.add(CAMERA_OFFSET), scene);
    camera.inputs.addMouseWheel();
    camera.attachControl();
    camera.inputs.remove(camera.inputs.attached.pointers);
    camera.inputs.remove(camera.inputs.attached.keyboard);
    camera.lowerRadiusLimit = MIN_ZOOM;
    camera.upperRadiusLimit = MAX_ZOOM;
    new BABYLON.PassPostProcess("resolution", 0.4, camera);
    // new AsciiArtPostProcess("AsciiArt", camera, {
    //     font: "20px Monospace",
    //     characterSet: "かけくこなねにのはひへほられりろいえおうふをわさしせそたちてと"
    // });
    camera.fov = IS_MOBILE ? 1.6 : 1.2;
    camera.maxZ = 500;

    const inspectCamera = new BABYLON.FreeCamera('inspectCamera', PLAYER_STARTING_POSITION.add(CAMERA_OFFSET), scene);
    if (IS_MOBILE) {
        inspectCamera.fov = 1.6;
    }
    inspectCamera.maxZ = 500;

    // @ts-ignore
    return (hasAmmoLoaded ? Promise.resolve() : window.Ammo()).then(() => {
        hasAmmoLoaded = true;
        const physicsPlugin = new BABYLON.AmmoJSPlugin(false);
        scene.enablePhysics(gravityVector, physicsPlugin);

        // const options = new BABYLON.SceneOptimizerOptions();
        // options.addOptimization(new BABYLON.HardwareScalingOptimization(0, 1));

        // // Optimizer
        // const optimizer = new BABYLON.SceneOptimizer(scene, options);


        const light = new BABYLON.DirectionalLight('light1', new BABYLON.Vector3(-1, -1, 0), scene);
        light.intensity = 0.5;
        scene.ambientColor = BABYLON_COLORS.PINK;
        scene.clearColor = LIGHT_PINK;
        scene.fogEnabled = true;
        scene.fogMode = BABYLON.Scene.FOGMODE_EXP;
        scene.fogColor = BABYLON.Color3.White();
        scene.fogDensity = 0.005;

        // Create materials
        Object.keys(colors).forEach(colorKey => {
            const color = BABYLON_COLORS[colorKey];
            const playerSphereMaterial = new BABYLON.StandardMaterial(`playerSphereMaterial${colorKey}`, scene);
            playerSphereMaterial.diffuseColor = color;
            playerSphereMaterial.alpha = 0.9;
            playerSphereMaterial.alphaMode = BABYLON.Material.MATERIAL_ALPHABLEND;
            playerSphereMaterial.ambientColor = color;
            playerSphereMaterial.emissiveColor = color;
            playerMaterials[colorKey] = playerSphereMaterial;
        });

        [1, 2, 3, 4].forEach(i => {
            const faceLabel = `face${i}`;
            const playerFaceMaterial = new BABYLON.StandardMaterial(`playerFaceMaterial${faceLabel}`, scene);
            const playerFaceTexture = new BABYLON.Texture(`/textures/${faceLabel}.png`, scene);
            playerFaceTexture.hasAlpha = true;
            playerFaceMaterial.diffuseColor = BABYLON.Color3.White();
            playerFaceMaterial.emissiveColor = BABYLON.Color3.White();
            playerFaceMaterial.diffuseTexture = playerFaceTexture;
            faceMaterials[faceLabel] = playerFaceMaterial;
        });

        const frameMaterial = new BABYLON.StandardMaterial("frameMaterial", scene);
        frameMaterial.ambientColor = BABYLON.Color3.White();
        frameMaterial.emissiveColor = BABYLON.Color3.White();
        frameMaterial.alpha = 0.48;
        frameMaterial.alphaMode = BABYLON.Material.MATERIAL_ALPHABLEND;
        const frame = BABYLON.Mesh.CreateBox('Frame', 1, scene);
        frame.position.x = -1000;
        frame.material = frameMaterial;
        frame.isVisible = false;

        return addLevelSegment(scene, gallery, babylonMemory, levelTokens, 0, false).then(() => scene);
    });
}

const HOVER_FRAME_RATE = 30;

async function getThumborUrl(imageUrl: string) {
    if (imageUrl.includes('ipfs://')) {
        imageUrl = IPFS_URL + (imageUrl.split("ipfs://ipfs/")[1] || imageUrl.split("ipfs://")[1]);
    }

    let thumborUrl = `${THUMBOR_URL}/unsafe/fit-in/100x100/${encodeURIComponent(imageUrl)}`;
    let highResThumborUrl = `${THUMBOR_URL}/unsafe/fit-in/2000x2000/${encodeURIComponent(imageUrl)}`;
    let header = null;
    try {
        header = await fetch(thumborUrl, { method: 'HEAD' });
    } catch {
        header = null;
    }
    let dontThumbor = false;
    let isGif = false;
    let isVideo = false;
    let isImage = true;
    let isSvg = false;
    if (header) {
        const contentType = header.headers.get('Content-Type');
        const contentLength = parseInt(header.headers.get('content-length'));
        isGif = contentType.includes('gif');
        if (contentLength > 999999) {
            isGif = false;
        }
        isVideo = contentType.includes('video');
        isImage = contentType.startsWith('image');
        isSvg = contentType.includes('svg');
    }
    isGif = isGif || imageUrl.includes('gif');
    isVideo = isVideo || imageUrl.includes('mp4') || imageUrl.includes('webm');
    isSvg = isSvg || imageUrl.includes('svg');
    dontThumbor = dontThumbor || isSvg || isVideo || !isImage;

    if (dontThumbor) {
        thumborUrl = imageUrl;
        highResThumborUrl = null;
    }

    if (isGif) {
        thumborUrl = `${THUMBOR_URL}/unsafe/fit-in/50x50/${encodeURIComponent(imageUrl)}`;
        highResThumborUrl = `${THUMBOR_URL}/unsafe/fit-in/500x500/${encodeURIComponent(imageUrl)}`;
    }

    return { thumborUrl, highResThumborUrl, dontThumbor, isGif, isVideo, isSvg };
}

async function initializeTexture(scene: BABYLON.Scene, plane: BABYLON.AbstractMesh, nftId: string, thumborUrl: string, isGif: boolean, isVideo: boolean, isSvg: boolean, isHighRes: boolean, onLoadTexture: (loadedTexture: any) => void) {
    const engine = scene.getEngine();
    let texture: any = null;
    if (isGif) {
        if (isHighRes) {
            texture = new FullAnimatedGifTexture(thumborUrl, engine, () => onLoadTexture(texture));
        } else {
            texture = new FullAnimatedGifTexture(thumborUrl, engine, () => onLoadTexture(texture));
        }
        // gifCount++;
    } else if (isVideo) {
        texture = new BABYLON.VideoTexture(thumborUrl, thumborUrl, scene, true, false, BABYLON.Texture.NEAREST_SAMPLINGMODE, { autoPlay: false, muted: true, loop: true, autoUpdateTexture: true, poster: '/textures/play-circle.svg' });
        onLoadTexture(texture);
        plane.material.alpha = 0;
        videos.push(plane.material as BABYLON.StandardMaterial);
    } else if (isSvg && isFirefox) {
        // Sad hack for firefox
        const res = await fetch(thumborUrl);
        let svgText = await res.text();
        const parser = new DOMParser();
        const svg = parser.parseFromString(svgText, "image/svg+xml").documentElement;
        const width = svg.getAttribute('width');
        const height = svg.getAttribute('height');
        const viewBox = svg.getAttribute('viewBox');
        if (!width && !height && viewBox) {
            const splitViewBox = viewBox.split(" ");
            const viewBoxWidth = splitViewBox[2];
            const viewBoxHeight = splitViewBox[3];
            svg.setAttribute('width', viewBoxWidth);
            svg.setAttribute('height', viewBoxHeight);
            svgText = svg.outerHTML;
        }
        texture = BABYLON.Texture.LoadFromDataString(thumborUrl, encodeSvg(svgText), scene, true, true, true, BABYLON.Texture.NEAREST_SAMPLINGMODE, () => onLoadTexture(texture));
        texture.hasAlpha = true;
    } else if (nftId.startsWith('0xda22422592ee3623c8d3c40fe0059cdecf30ca79')) {
        // Sad hack for some animated svgs (pak's censored)
        const res = await fetch(thumborUrl);
        let svgText = await res.text();
        svgText = svgText.replace('<rect class=\'b f a\' />', '');
        texture = BABYLON.Texture.LoadFromDataString(thumborUrl, encodeSvg(svgText), scene, true, true, true, BABYLON.Texture.NEAREST_SAMPLINGMODE, () => onLoadTexture(texture));
        texture.hasAlpha = true;
    } else {
        texture = new BABYLON.Texture(thumborUrl, scene, false, true, BABYLON.Texture.NEAREST_SAMPLINGMODE, () => onLoadTexture(texture));
        texture.hasAlpha = true;
    }

    return texture;
}

function getFullCanvasScaling(scaling: BABYLON.Vector3, texture: BABYLON.Texture, isBig: boolean, isGif: boolean, isVideo: boolean, dontThumbor: boolean, expandedState: boolean) {
    const textureWidth = texture._texture.width;
    const textureHeight = texture._texture.height;
    if (!dontThumbor) {
        const imageBoundingDimension = isGif ? 50 : 100;
        scaling.x = textureWidth / imageBoundingDimension;
        scaling.y = textureHeight / imageBoundingDimension;
    } else if (dontThumbor && !isVideo) {
        scaling.x = textureWidth > textureHeight ? 1 : textureWidth / textureHeight;
        scaling.y = textureHeight > textureWidth ? 1 : textureHeight / textureWidth;
    }
    return getCanvasScaling(scaling, isBig, expandedState);
}

function getCanvasScaling(scaling: BABYLON.Vector3, isBig: boolean, expandedState: boolean) {
    const newScaling = scaling.normalizeToNew();
    newScaling.x = newScaling.x * (expandedState ? 1.466 : 1) * (isBig ? BIG_CANVAS_SIZE : CANVAS_SIZE);
    newScaling.y = newScaling.y * (expandedState ? 1.466 : 1) * (isBig ? BIG_CANVAS_SIZE : CANVAS_SIZE);
    newScaling.z = scaling.z;
    return newScaling;
}

function formatMetadata(metadata: any) {
    const useAnimationUrl = metadata.animation_url && metadata.animation_url.includes('opensea');
    return {
        name: metadata.name,
        tokenId: metadata.token_id,
        collectionName: metadata.collection_name,
        contractAddress: metadata.contract_address,
        nftId: `${metadata.contract_address}:${metadata.token_id}`,
        rawImageUrl: metadata.image_url,
        imageUrl: useAnimationUrl ? metadata.animation_url : metadata.image_url,
        description: metadata.description,
        permalink: metadata.permalink,
        thumborUrl: '',
        highResThumborUrl: '',
        dontThumbor: false,
        isGif: false,
        isVideo: false,
        isSvg: false,
        meshName: '',
        imageThumbnailUrl: metadata.image_thumbnail_url
    };
}

async function addNftToCanvas(scene: BABYLON.Scene, babylonMemory: BabylonMemory, nftId: string, plane: BABYLON.AbstractMesh, setMenuArt: (art: any) => void, onLoadTextureParam: (loadedTexture: BABYLON.Texture) => void) {
    const canvasId = plane.name.substring('Canvas'.length);
    let formattedMetadata = babylonMemory.nftIdsToMetadata[nftId];
    formattedMetadata.meshName = plane.name;

    setMenuArt(formattedMetadata);
    const frame = scene.getMeshByName(`Frame${canvasId}`);

    let imageUrl = formattedMetadata.imageUrl;

    if (!imageUrl) {
        return;
    }

    let { thumborUrl, highResThumborUrl, dontThumbor, isGif, isVideo, isSvg } = await getThumborUrl(imageUrl);
    formattedMetadata = {
        ...formattedMetadata,
        thumborUrl,
        highResThumborUrl,
        dontThumbor,
        isGif,
        isVideo,
        isSvg
    };
    babylonMemory.nftIdsToMetadata[nftId] = formattedMetadata;
    meshNamesToMetadata[plane.name] = formattedMetadata;

    if (thumborUrl) {
        const material = new BABYLON.StandardMaterial(`${nftId}Materials`, scene);
        material.emissiveColor = BABYLON.Color3.White();
        const onLoadTexture = (loadedTexture: any) => {
            if (loadedTexture) {
                // Store texture for swapping in high res version later
                meshNamesToTextures[plane.name] = loadedTexture;
                const isBig = meshNamesToCanvasData[plane.name].isBig;
                plane.isVisible = true;
                frame.isVisible = true;
                const textureWidth = loadedTexture._texture.width;
                const textureHeight = loadedTexture._texture.height;
                if (!dontThumbor) {
                    const imageBoundingDimension = isGif ? 50 : 100;
                    plane.scaling.x = textureWidth / imageBoundingDimension;
                    plane.scaling.y = textureHeight / imageBoundingDimension;
                } else if (dontThumbor && !isVideo) {
                    plane.scaling.x = textureWidth > textureHeight ? 1 : textureWidth / textureHeight;
                    plane.scaling.y = textureHeight > textureWidth ? 1 : textureHeight / textureWidth;
                }
                const newScaling = getCanvasScaling(plane.scaling, isBig, false);
                plane.scaling = newScaling;
                material.diffuseTexture = loadedTexture;
                plane.material = material;
                // frame.physicsImpostor.setScalingUpdated();

                onLoadTextureParam(loadedTexture);
            }
        };
        return await initializeTexture(scene, plane, nftId, thumborUrl, isGif, isVideo, isSvg, false, onLoadTexture);
    }
}

async function addNFTsToScene(nfts: any[], scene: BABYLON.Scene, babylonMemory: BabylonMemory, gallery: any, canvasIndex: number, onZoomAreaEnter: (mesh: BABYLON.AbstractMesh) => void, onZoomAreaExit: () => void, setMenuArt: (art: any) => void) {
    // Gifs are a memory issue, so let's limit the number
    // let gifCount = 0;
    nfts.filter((metadata: string | null) => (metadata)).forEach(async (metadata: any, i: number) => {
        // Determine which canvases to put these on
        // If this nft has a canvas, put it on that one.
        // If not, use canvas index and then increment it if you use it
        const nftId = `${metadata.contract_address}:${metadata.token_id}`;
        let canvasPlacement = gallery.canvases && gallery.canvases[nftId];
        let canvasId;
        if (canvasPlacement) {
            canvasId = `${canvasPlacement.originalSpaceId}:${canvasPlacement.originalLevelCanvasIndex}`;
        } else {
            canvasId = babylonMemory.canvases[canvasIndex];
        }
        let plane = scene.getMeshByName(`Canvas${canvasId}`);
        // This happens in the case that the space is hidden or has been sold
        if (canvasPlacement && !plane) {
            canvasPlacement = false;
            canvasId = babylonMemory.canvases[canvasIndex];
            plane = scene.getMeshByName(`Canvas${canvasId}`);
        } else if (!plane) {
            return;
        }

        // Add offsets to placed canvases who are in different spaces
        if (canvasPlacement && canvasPlacement.spaceId !== canvasPlacement.originalSpaceId) {
            const newSpace = babylonMemory.levelsById[canvasPlacement.spaceId];
            if (!newSpace) {
                canvasPlacement = false;
            } else {
                const pos = new BABYLON.Vector3(canvasPlacement.position.x, canvasPlacement.position.y, canvasPlacement.position.z);
                pos.addInPlace(newSpace.offset);
                plane.position = pos;
                meshNamesToCanvasData[plane.name].x = pos.x;
                meshNamesToCanvasData[plane.name].x = pos.y;
                meshNamesToCanvasData[plane.name].x = pos.z;
            }
        }


        const onLoadTexture = () => {
            const planeY = meshNamesToCanvasData[plane.name].y;
            setTimeout(() => {
                BABYLON.Animation.CreateAndStartAnimation('rise', plane, 'position.y', 60, 120, planeY - 100, planeY, BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE, EASE, () => {
                    // Loop a hover function
                    const hoverAnimation = new BABYLON.Animation("hover", "position.y", HOVER_FRAME_RATE, BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE);
                    const keyFrames = [];
                    keyFrames.push({
                        frame: 0,
                        value: planeY,
                    });
                    keyFrames.push({
                        frame: 1.5 * HOVER_FRAME_RATE,
                        value: planeY - 1,
                    });

                    keyFrames.push({
                        frame: 3 * HOVER_FRAME_RATE,
                        value: planeY,
                    });
                    hoverAnimation.setKeys(keyFrames);
                    hoverAnimation.setEasingFunction(EASE);
                    plane.animations.push(hoverAnimation);
                    meshNamesToHoverAnimatable[plane.name] = scene.beginAnimation(plane, 0, 3 * HOVER_FRAME_RATE, true);
                });
            }, Math.random() * 500);
        };

        // Don't await this even though it's async
        addNftToCanvas(scene, babylonMemory, nftId, plane, setMenuArt, onLoadTexture);

        if (!canvasPlacement) {
            canvasIndex++;
        }

        const frame = scene.getMeshByName(`Frame${canvasId}`);
        const zoomSphere = BABYLON.Mesh.CreateBox(`${plane.name}Trigger`, 1, scene);
        BABYLON.Tags.AddTagsTo(zoomSphere, "canvasHitBox");
        zoomSphere.position = frame.position.clone();
        zoomSphere.scaling = frame.scaling.clone();
        zoomSphere.parent = plane;
        zoomSphere.scaling.y *= 2;
        zoomSphere.scaling.z = CANVAS_COLLIDER_SCALE;
        zoomSphere.position.z -= 2 + zoomSphere.scaling.z / 2;
        zoomSphere.isVisible = false;
        zoomSphere.isPickable = false;

        meshNamesToMetadata[plane.name] = babylonMemory.nftIdsToMetadata[nftId];
        babylonMemory.nftIdsToCanvases[nftId] = plane;

        babylonMemory.meshNamesToNftIds[plane.name] = nftId;
    });
}

// async function loadPage(page: number, scene: BABYLON.Scene, onZoomAreaEnter: (mesh: BABYLON.AbstractMesh) => void, onZoomAreaExit: () => void, address: string, canvasIndex: number) {
//     return fetch(`${NFT_SERVER_URL}?addresses=${address}&page=${page}&pageSize=${PAGE_SIZE}`)
//         .then(res => res.json())
//         .then(json => addNFTsToScene(json.nfts || [], scene, canvasIndex, onZoomAreaEnter, onZoomAreaExit))
// }

type BabylonContainerProps = {
    inspectButtonVisible: boolean,
    moving: boolean,
    moveButtonVisible: boolean,
    headerVisible: boolean,
    menuVisible: boolean,
    setReady: (value: boolean) => void,
    setInfo: (value: { [key: string]: string }) => void,
    setInspectButtonVisible: (value: boolean) => void,
    setMoving: (value: boolean) => void,
    setMoveButtonVisible: (value: boolean) => void,
    setHeaderVisible: (value: boolean) => void,
    setMenuVisible: (value: boolean) => void,
    setDescriptionExpanded: (value: boolean) => void,
    playerName: string,
    selectedImportedAvatar: { type: string, image: string, uri: string, id: string },
    gameState: number,
    resolvedAddress: string,
    gallery: any,
    galleryLoaded: boolean
};

type GalleryURLParams = {
    addresses: string
}

type Position = {
    x: number,
    y: number,
    z: number
}

type LevelToken = {
    name: string,
    description: string,
    canvases: Position[],
    offset: Position,
    scale: number,
    initialLevelSegment: string,
    intermediateLevelSegment: string,
    finalLevelSegment: string,
    isSpaceFactory: boolean,
    terrain: number,
    background: number,
    particleShape: number,
    particleColor: number,
    id: string,
    contractAddress: string
}

type BabylonMemory = {
    canvases: any,
    meshNamesToNftIds: any,
    nftIdsToCanvases: any,
    nftIdsToMetadata: any,
    levelsById: any
}

function BabylonContainer(props: BabylonContainerProps) {
    const { resolvedAddress, gallery, galleryLoaded, headerVisible, menuVisible, inspectButtonVisible, moving, moveButtonVisible, setReady, setInfo, setInspectButtonVisible, setMoveButtonVisible, setMoving, setHeaderVisible, setMenuVisible, setDescriptionExpanded, playerName, selectedImportedAvatar, gameState } = props;
    const [unreadChats, setUnreadChats] = useState(false);
    const [chatPreview, setChatPreview] = useState(null);
    const [swapModalOpen, setSwapModalOpen] = useState(null);
    const [muted, setMuted] = useState(false);
    const { chat, setChat } = useContext(ChatContext);
    const { people, setPeople } = useContext(PeopleContext);
    const { art, setArt } = useContext(ArtContext);
    const { curate, setCurate } = useContext(CurateContext);
    const { web3Address, web3ENS } = useContext(Web3AddressContext);
    const { authenticateWithModal } = useContext(AuthenticationModalContext);
    const { openErrorModal, closeErrorModal } = useContext(ErrorModalContext);
    const displayWeb3Address = web3ENS || web3Address;

    const hiddenIdsMap = gallery && gallery.hiddenIdsMap;
    // const hasNotCurated = !gallery || (gallery.canvases === undefined);

    const { addresses: addressesString } = useParams() as GalleryURLParams;
    const ownsGallery = web3Address && resolvedAddress && (web3Address.toLowerCase() === resolvedAddress.toLowerCase());
    // useEffect(() => {
    //     if (addressesString === 'test') {
    //         DEBUG = true;
    //     }
    // }, [addressesString]);

    const sceneReference: React.MutableRefObject<BABYLON.Scene> = useRef(null);
    const webSocketReference = useRef(null);

    // Check auth and clear cookies if anything is weird
    useEffect(() => {
        if (resolvedAddress) {
            try {
                fetch(CHECK_AUTHENTICATION_URL, {
                    method: 'POST',
                    mode: 'cors',
                    credentials: 'include',
                    cache: 'no-cache',
                    body: JSON.stringify({ address: resolvedAddress })
                });
            } catch { }

        }
    }, [web3Address, resolvedAddress]);

    const onQuitInspect = () => {
        if (activeImageRef.current && isMobile) {
            const mesh = activeImageRef.current;
            const metadata = meshNamesToMetadata[mesh.name];
            if (meshNamesToMetadata[mesh.name].highResThumborUrl) {
                const textureToDispose = metadata.highResTexture;
                (mesh.material as BABYLON.StandardMaterial).diffuseTexture = meshNamesToTextures[mesh.name];
                if (textureToDispose) {
                    textureToDispose.dispose();
                }
                sceneReference.current.cleanCachedTextureBuffer();
                metadata.highResTexture = null;
            }
        }
        activeImageRef.current = null;
        document.getElementById('renderCanvas').focus();

        // Lower resolution
        const postProcess = sceneReference.current.getPostProcessByName('resolution');
        sceneReference.current.getCameraByName('camera1').attachPostProcess(postProcess);
    };

    const onInspect = async (paramMesh: BABYLON.AbstractMesh | null) => {
        // Don't inspect if moving
        if (moveImageRef.current) {
            return;
        }

        // Raise resolution
        const postProcess = sceneReference.current.getPostProcessByName('resolution');
        sceneReference.current.getCameraByName('camera1').detachPostProcess(postProcess);

        if (activeImageRef.current) {
            onQuitInspect();
            return;
        }

        const mesh = paramMesh || inspectImageRef.current;
        if (mesh) {
            activeImageRef.current = mesh;
            cameraAnimationTimerRef.current = 0;
        }
        document.getElementById('renderCanvas').focus();

        if (isMobile) {
            const metadata = meshNamesToMetadata[mesh.name];
            const highResThumborUrl = metadata.highResThumborUrl;
            if (highResThumborUrl) {
                metadata.highResTexture = await initializeTexture(sceneReference.current, mesh, metadata.nftId, highResThumborUrl, metadata.isGif, metadata.isVideo, metadata.isSvg, true, loadedTexture => {
                    (mesh.material as BABYLON.StandardMaterial).diffuseTexture = loadedTexture;
                });
            };
        }

        setHeaderVisible(true);
    };

    const levelTokensRef = useRef([]);
    const initialSpaceInputRef = useRef(null);
    // const intermediateSpaceInputRef = useRef(null);
    // const finalSpaceInputRef = useRef(null);

    const url = window.location.href;
    const history = useHistory();
    const query = useRef(new URLSearchParams(useLocation().search));

    const chatRef = useRef([]);
    const peopleRef = useRef([]);
    const artRef = useRef([]);
    const curateDefault: CurateType = { nfts: {}, collaborators: [], spaces: [] };
    const curateRef = useRef(curateDefault);

    // Global game variables
    const isDraggingRef = useRef(false);
    const hasEmotedRef = useRef(false);
    const hasDashedRef = useRef(false);
    const chatMessageRef = useRef(null);
    const lastDashedRef = useRef(0);
    const dashAnimationTimerRef = useRef(DASH_ANIMATION_LENGTH);
    const keysRef: React.MutableRefObject<{ [key: string]: boolean }> = useRef({
        ArrowUp: false,
        ArrowDown: false,
        ArrowLeft: false,
        ArrowRight: false,
        w: false,
        a: false,
        s: false,
        d: false,
        ' ': false
    });
    const joystickMoveRef = useRef({ x: 0, y: 0 });
    const joystickLookRef = useRef({ x: 0, y: 0 });
    const cameraAlphaRef = useRef(0);
    const cameraBetaRef = useRef(0);
    // Kind of hacky way to track rotation about y axis. This is for wizards and sprites moving canvases
    const playerRotationRef = useRef(0);
    // const cameraZoomRef = useRef(0);

    // GLOBAL VARIABLES
    const inspectImageRef: React.MutableRefObject<BABYLON.AbstractMesh | null> = useRef(null);
    const activeImageRef: React.MutableRefObject<BABYLON.AbstractMesh | null> = useRef(null);
    const moveImageRef: React.MutableRefObject<BABYLON.AbstractMesh | null> = useRef(null);
    const swapImageRef: React.MutableRefObject<BABYLON.AbstractMesh | null> = useRef(null);
    const particleSystemsRef: React.MutableRefObject<{ [name: string]: BABYLON.ParticleSystem } | null> = useRef({});
    const cameraAnimationTimerRef = useRef(0);

    // Use this for general global references
    const babylonMemoryDefault: BabylonMemory = {
        canvases: [],
        meshNamesToNftIds: {},
        nftIdsToCanvases: {},
        nftIdsToMetadata: {},
        levelsById: {}
    };
    const babylonMemoryRef = useRef(babylonMemoryDefault);

    const clientIdRef: React.MutableRefObject<string> = useRef(null);
    const playersRef: React.MutableRefObject<{ [key: string]: BABYLON.AbstractMesh }> = useRef({});
    // Hold onto these to iterate over and keep rotated towards the camera.
    const playerIdsToNameMeshesRef: React.MutableRefObject<{ [key: string]: BABYLON.AbstractMesh }> = useRef({});
    const playerIdsToInfoRef: React.MutableRefObject<{ [key: string]: { name: string, avatar: string, animationTimer: number } }> = useRef({});

    // Refocus canvas after closing menu
    useEffect(() => {
        if (!menuVisible) {
            document.getElementById('renderCanvas').focus();
        }
    }, [menuVisible]);


    // This is super hacky but it's because I can't read state in those callback functions
    useEffect(() => {
        if (unreadChats && menuVisible) {
            setUnreadChats(false);
            setChatPreview(null);
        }
    }, [unreadChats, menuVisible]);

    useEffect(() => {
        if (chatPreview) {
            const chatPreviewEl = document.getElementById('chat-preview');
            if (chatPreviewEl) {
                chatPreviewEl.classList.add('chat-preview-active');
                setTimeout(() => {
                    chatPreviewEl.classList.remove('chat-preview-active');
                }, 1000);
            }
        }
    }, [chatPreview]);

    const onZoomAreaEnter = async (mesh: BABYLON.AbstractMesh) => {
        // This will be called repeatedly while in the zoom area. Return quickly.
        if (inspectImageRef.current && inspectImageRef.current.name === mesh.name) {
            return;
        } else if (inspectImageRef.current) {
            // If we're already inspecting another canvas, first make sure to exit that one
            onZoomAreaExit();
        }
        // Animation
        const canvasData = meshNamesToCanvasData[mesh.name];
        const planeY = canvasData.y;
        const isBig = canvasData.isBig;
        const newScaling = getCanvasScaling(mesh.scaling, isBig, true);
        BABYLON.Animation.CreateAndStartAnimation('swell', mesh, 'scaling', 60, 30, mesh.scaling, newScaling, BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE, EASE);
        meshNamesToHoverAnimatable[mesh.name] && meshNamesToHoverAnimatable[mesh.name].pause();
        BABYLON.Animation.CreateAndStartAnimation('displayRise', mesh, 'position.y', 60, 30, mesh.position.y, planeY + 4, BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE, EASE);
        inspectImageRef.current = mesh;
        setInspectButtonVisible(true);
        setMoveButtonVisible(true);
        const metadata = meshNamesToMetadata[mesh.name];

        // Begin fetching higher res version
        if (!isMobile) {
            const highResThumborUrl = metadata.highResThumborUrl;
            if (highResThumborUrl) {
                metadata.highResTexture = await initializeTexture(sceneReference.current, mesh, metadata.nftId, highResThumborUrl, metadata.isGif, metadata.isVideo, metadata.isSvg, true, loadedTexture => {
                    (mesh.material as BABYLON.StandardMaterial).diffuseTexture = loadedTexture;
                });
            };
        }

        // Play a little sound
        const r = 100 * Math.random();
        let randomIndex;
        if (r < 20) {
            randomIndex = 1;
        } else if (r < 40) {
            randomIndex = 3;
        } else if (r < 60) {
            randomIndex = 5;
        } else if (r < 70) {
            randomIndex = 2;
        } else if (r < 80) {
            randomIndex = 4;
        } else if (r < 90) {
            randomIndex = 6;
        } else if (r < 95) {
            randomIndex = 7;
        } else if (r <= 100) {
            randomIndex = 8;
        }
        const audio = document.getElementById(`sound${randomIndex}`) as HTMLAudioElement;
        if (audio) {
            audio.currentTime = 0;
            if (audio.paused) {
                audio.play();
            }
        }

        setInfo({
            name: metadata.name,
            collectionName: metadata.collectionName,
            tokenId: metadata.tokenId,
            description: metadata.description,
            link: metadata.permalink
        });
        setHeaderVisible(true);
    };
    const disposeTexturesForCanvas = (mesh: BABYLON.AbstractMesh) => {
        const metadata = meshNamesToMetadata[mesh.name];
        if (meshNamesToMetadata[mesh.name].highResThumborUrl) {
            const textureToDispose = metadata.highResTexture;
            (mesh.material as BABYLON.StandardMaterial).diffuseTexture = meshNamesToTextures[mesh.name];
            // isLoaded trick for long gifs
            if (textureToDispose && (textureToDispose.isLoaded ? textureToDispose.isLoaded() : true)) {
                textureToDispose.dispose();
            }
            sceneReference.current.cleanCachedTextureBuffer();
        }
        metadata.highResTexture = null;
    };
    const onZoomAreaExit = () => {
        const mesh = activeImageRef.current || inspectImageRef.current;

        if (mesh) {
            // Replace high res with original low res if we have a high res link
            if (!isMobile) {
                disposeTexturesForCanvas(mesh);
            }

            // Animation
            if (moveImageRef.current && (moveImageRef.current.id === mesh.id)) {
                // If moving the canvas, make the transformations instant
                const canvasData = meshNamesToCanvasData[mesh.name];
                const planeY = canvasData.y;
                const isBig = canvasData.isBig;
                const newScaling = getCanvasScaling(mesh.scaling, isBig, false);
                mesh.scaling = newScaling;
                mesh.position.y = planeY;
                const animatable = meshNamesToHoverAnimatable[mesh.name];
                if (animatable) {
                    meshNamesToHoverAnimatable[mesh.name].goToFrame(0);
                    meshNamesToHoverAnimatable[mesh.name].restart();
                }
            } else {
                const canvasData = meshNamesToCanvasData[mesh.name];
                const planeY = canvasData.y;
                const isBig = canvasData.isBig;
                const newScaling = getCanvasScaling(mesh.scaling, isBig, false);
                BABYLON.Animation.CreateAndStartAnimation('fall', mesh, 'scaling', 60, 30, mesh.scaling, newScaling, BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE, EASE);
                BABYLON.Animation.CreateAndStartAnimation('displayFall', mesh, 'position.y', 60, 30, mesh.position.y, planeY, BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE, EASE, () => {
                    const animatable = meshNamesToHoverAnimatable[mesh.name];
                    if (animatable) {
                        meshNamesToHoverAnimatable[mesh.name].goToFrame(0);
                        meshNamesToHoverAnimatable[mesh.name].restart();
                    }
                });

                mesh.beginAnimation('hover', true);
            }


            // Pause video if possible
            const material = mesh && (mesh.material as BABYLON.StandardMaterial);
            const videoTexture = material && (material.diffuseTexture as BABYLON.VideoTexture);
            if (videoTexture && videoTexture.video) {
                videoTexture.video.pause();
            }
        }

        setHeaderVisible(false);
        setInspectButtonVisible(false);
        setMoveButtonVisible(false);
        setDescriptionExpanded(false);
        activeImageRef.current = null;
        inspectImageRef.current = null;
    };

    const getLevelFromZPos = (z: number) => {
        let positionCounter = 0;
        let levelIndex = 0;
        let i = 0;
        while (z >= positionCounter) {
            const mapIndex = i % levelTokensRef.current.length;
            positionCounter += levelTokensRef.current[mapIndex].offset.z;
            if (z < positionCounter) {
                if (levelIndex !== i) {
                    levelIndex = i;
                }
                break;
            }
            i++;
        }

        const levelInstanceIndex = Math.floor(i / levelTokensRef.current.length);
        return { levelIndex, levelInstanceIndex };
    };

    const getOffsetFromLevel = (index: number) => {
        // Offset arrived at by adding all token offsets
        const levelTokens = levelTokensRef.current;
        const offset = BABYLON.Vector3.Zero();
        for (let i = 0; i < index; i++) {
            const tokenOffsetIndex = i < levelTokens.length ? i : i % levelTokens.length;
            const tokenOffset = levelTokens[tokenOffsetIndex].offset;
            offset.addInPlace(new BABYLON.Vector3(tokenOffset.x, tokenOffset.y, tokenOffset.z));
        }
        return offset;
    };

    const onMove = () => {
        authenticateWithModal(web3Address, () => {
            // const camera = sceneReference.current.getCameraByName('camera1') as BABYLON.ArcRotateCamera;
            // BABYLON.Animation.CreateAndStartAnimation('cameraZoom', camera, 'radius', 60, 15, camera.radius, MAX_ZOOM, BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE);
            playerIdsToNameMeshesRef.current[clientIdRef.current].isVisible = false;
            moveImageRef.current = inspectImageRef.current;
            // Disable canvas trigger while moving
            moveImageRef.current.getChildren()[1].setEnabled(false);
            onZoomAreaExit();
            const playerMesh = playersRef.current[clientIdRef.current];
            const canvasBounding = moveImageRef.current.getBoundingInfo()
            const canvasSize = canvasBounding.boundingBox.maximumWorld.y - canvasBounding.boundingBox.minimumWorld.y;
            moveImageRef.current.rotation = playerMesh.rotationQuaternion.toEulerAngles();
            // TODO Make this some sort of linear interpolation to support any canvas size
            const canvasMovingZOffset = meshNamesToCanvasData[moveImageRef.current.name].isBig ? 10 : 0;
            moveImageRef.current.position = new BABYLON.Vector3(playerMesh.position.x, playerMesh.position.y + (canvasSize / 2), playerMesh.position.z + canvasMovingZOffset).add(playerMesh.forward.scale(7));
            moveImageRef.current.material.alpha = 0.1;
            setMoving(true);
            const moveParticleSystem = particleSystemsRef.current.MOVE_CANVAS;
            moveParticleSystem.emitter = moveImageRef.current;
            const bounds = moveImageRef.current.getBoundingInfo();
            moveParticleSystem.minEmitBox.x = bounds.minimum.x;
            moveParticleSystem.maxEmitBox.x = bounds.maximum.x;
            moveParticleSystem.minEmitBox.y = bounds.minimum.y;
            moveParticleSystem.maxEmitBox.y = bounds.maximum.y;
            moveParticleSystem.minEmitBox.z = -2;
            moveParticleSystem.maxEmitBox.z = 2;
            moveParticleSystem.start();

            const burstMoveParticles = particleSystemsRef.current.BURST_MOVE_CANVAS;
            burstMoveParticles.emitter = moveImageRef.current;
            setTimeout(() => {
                burstMoveParticles.manualEmitCount = 100;
            }, 200);
            // burstMoveParticles.minEmitBox.x = bounds.minimum.x;
            // burstMoveParticles.maxEmitBox.x = bounds.maximum.x;
            // burstMoveParticles.minEmitBox.y = bounds.minimum.y;
            // burstMoveParticles.maxEmitBox.y = bounds.maximum.y;
            // burstMoveParticles.minEmitBox.z = -2;
            // burstMoveParticles.maxEmitBox.z = 2;
        }, () => { });
    };

    const onSwap = () => {
        const authenticationFailureCallback = () => {
            openErrorModal('error', 'looks like something went wrong while authenticating. please contact us on discord or twitter!', 'okay', closeErrorModal, null, null);
        };

        authenticateWithModal(web3Address, () => {
            setSwapModalOpen(true);
            swapImageRef.current = inspectImageRef.current;
        }, authenticationFailureCallback);
    };

    const onSwapSelect = async (nft: any) => {
        const babylonMemory = babylonMemoryRef.current;
        const currentCanvas = swapImageRef.current;
        const currentCanvasNftId = babylonMemory.meshNamesToNftIds[currentCanvas.name];
        if (currentCanvasNftId && currentCanvasNftId === nft.id) {
            setSwapModalOpen(false);
            return;
        }
        const authenticationSuccessCallback = async () => {
            // This canvas is swapImageRef
            // Get this texture by nft ID if it exists, otherwise initialize it using the image URL
            const selectedNftId = nft.id;
            const selectedNftCanvas = babylonMemory.nftIdsToCanvases[selectedNftId];

            // Get the canvas of the nft to swap by nft id if it exists
            // If it exists, set its texture to the texture of this current canvas (swapImageRef)
            const selectedNftInScene = !!selectedNftCanvas;

            disposeTexturesForCanvas(currentCanvas);
            if (selectedNftInScene) {
                disposeTexturesForCanvas(selectedNftCanvas);
            }

            const selectedNftMetadata = babylonMemory.nftIdsToMetadata[selectedNftId];
            if (selectedNftInScene) {
                // Selected nft already has a canvas and texture, then swap them
                const selectedNftMaterial = selectedNftCanvas.material;
                selectedNftCanvas.material = currentCanvas.material;
                currentCanvas.material = selectedNftMaterial;

                // Swap the mappings
                meshNamesToMetadata[selectedNftCanvas.name] = meshNamesToMetadata[currentCanvas.name];
                meshNamesToMetadata[currentCanvas.name] = selectedNftMetadata;

                const selectedNftTexture = meshNamesToTextures[selectedNftCanvas.name];
                meshNamesToTextures[selectedNftCanvas.name] = meshNamesToTextures[currentCanvas.name];
                meshNamesToTextures[currentCanvas.name] = selectedNftTexture;

                babylonMemory.nftIdsToCanvases[currentCanvasNftId] = selectedNftCanvas;
                babylonMemory.nftIdsToCanvases[selectedNftId] = currentCanvas;

                babylonMemory.meshNamesToNftIds[currentCanvas.name] = selectedNftId;
                babylonMemory.meshNamesToNftIds[selectedNftCanvas.name] = currentCanvasNftId;

                // Update current canvas ratio
                currentCanvas.scaling = getFullCanvasScaling(currentCanvas.scaling, meshNamesToTextures[currentCanvas.name], meshNamesToCanvasData[currentCanvas.name].isBig, meshNamesToMetadata[currentCanvas.name].isGif, meshNamesToMetadata[currentCanvas.name].isVideo, meshNamesToMetadata[currentCanvas.name].dontThumbor, true);
                selectedNftCanvas.scaling = getFullCanvasScaling(selectedNftCanvas.scaling, meshNamesToTextures[selectedNftCanvas.name], meshNamesToCanvasData[selectedNftCanvas.name].isBig, meshNamesToMetadata[selectedNftCanvas.name].isGif, meshNamesToMetadata[selectedNftCanvas.name].isVideo, meshNamesToMetadata[selectedNftCanvas.name].dontThumbor, false);
            } else {
                // If the selected nft is hidden, then initialize the texture
                const onAddNft = (newArt: any) => {
                    artRef.current.push(newArt);
                    setArt(artRef.current.slice());
                };
                const onLoadTexture = (loadedTexture: BABYLON.Texture) => {
                    currentCanvas.scaling = getFullCanvasScaling(
                        currentCanvas.scaling,
                        loadedTexture,
                        meshNamesToCanvasData[currentCanvas.name].isBig,
                        babylonMemory.nftIdsToMetadata[selectedNftId].isGif,
                        babylonMemory.nftIdsToMetadata[selectedNftId].isVideo,
                        babylonMemory.nftIdsToMetadata[selectedNftId].dontThumbor,
                        true
                    );
                }
                // TODO In the future, maybe check if the texture is available
                const newTexture = await addNftToCanvas(sceneReference.current, babylonMemory, selectedNftId, currentCanvas, onAddNft, onLoadTexture);

                // Clear old mappings and set new ones
                meshNamesToMetadata[currentCanvas.name] = selectedNftMetadata;

                meshNamesToTextures[currentCanvas.name] = newTexture;

                babylonMemory.nftIdsToCanvases[currentCanvasNftId] = undefined;
                babylonMemory.nftIdsToCanvases[selectedNftId] = currentCanvas;

                babylonMemory.meshNamesToNftIds[currentCanvas.name] = selectedNftId;

                // Update the hidden mapping. Hide the swapped out NFT
                const body: any = {
                    address: resolvedAddress,
                    hidden: [...Object.keys(curate.nfts).filter(curateId => curate.nfts[curateId].hidden && curateId !== selectedNftId).map(curateId => curateId), currentCanvasNftId]
                };
                const bodyJson = JSON.stringify(body);
                try {
                    const response = await fetch(UPDATE_GALLERY_URL, {
                        method: 'POST',
                        mode: 'cors',
                        credentials: 'include',
                        cache: 'no-cache',
                        body: bodyJson
                    });
                    if (response.status === 401) {
                        openErrorModal('error', 'looks like something went wrong while authenticating. please try again.', 'okay', () => {
                            closeErrorModal();
                        }, null, null);
                    } else if (response.status !== 200) {
                        throw new Error();
                    }
                } catch {
                    openErrorModal('error', 'looks like something went wrong while saving. please contact us on discord or twitter!', 'okay', () => {
                        closeErrorModal();
                    }, null, null);
                }
            }

            // Update the backend
            const hasCuration = !!gallery.canvases && Object.keys(gallery.canvases).length > 0;
            let canvasMeshes;
            // If first curation, save all canvas locations
            if (!hasCuration) {
                canvasMeshes = sceneReference.current.getMeshesByTags("canvas");
            } else {
                canvasMeshes = selectedNftInScene ? [currentCanvas, selectedNftCanvas] : [currentCanvas];
            }
            saveCanvasLocations(canvasMeshes);

            const burstMoveParticles = particleSystemsRef.current.BURST_MOVE_CANVAS;
            burstMoveParticles.emitter = currentCanvas;
            setTimeout(() => {
                burstMoveParticles.manualEmitCount = 100;
            }, 200);

            // Update header
            setInfo({
                name: selectedNftMetadata.name,
                collectionName: selectedNftMetadata.collectionName,
                tokenId: selectedNftMetadata.tokenId,
                description: selectedNftMetadata.description,
                link: selectedNftMetadata.permalink
            });
        }

        const authenticationFailureCallback = () => {
            openErrorModal('error', 'looks like something went wrong while authenticating. please contact us on discord or twitter!', 'okay', closeErrorModal, null, null);
        };

        await authenticateWithModal(web3Address, authenticationSuccessCallback, authenticationFailureCallback);
    };

    const stopMoving = () => {
        // Disable canvas trigger while moving
        // const camera = sceneReference.current.getCameraByName('camera1') as BABYLON.ArcRotateCamera;
        // if (camera.radius === MAX_ZOOM) {
        //     BABYLON.Animation.CreateAndStartAnimation('cameraZoom', camera, 'radius', 60, 15, camera.radius, DEFAULT_ZOOM, BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE);
        // }
        moveImageRef.current.getChildren()[1].setEnabled(true);
        setMoving(false);
        const isBig = meshNamesToCanvasData[moveImageRef.current.name].isBig;
        const newScaling = getCanvasScaling(moveImageRef.current.scaling, isBig, false);
        moveImageRef.current.scaling = newScaling;
        moveImageRef.current.material.alpha = 1;
        particleSystemsRef.current.MOVE_CANVAS.stop();
        playerIdsToNameMeshesRef.current[clientIdRef.current].isVisible = true;
        const burstMoveParticles = particleSystemsRef.current.BURST_MOVE_CANVAS;
        burstMoveParticles.emitter = moveImageRef.current;
        setTimeout(() => {
            burstMoveParticles.manualEmitCount = 100;
        }, 200);
        moveImageRef.current.beginAnimation('hover', true);
        moveImageRef.current = null;
    };

    const onCancelMove = () => {
        if (moveImageRef) {
            const canvasData = meshNamesToCanvasData[moveImageRef.current.name];
            moveImageRef.current.position = new BABYLON.Vector3(canvasData.x, canvasData.y, canvasData.z);
            moveImageRef.current.rotation = new BABYLON.Vector3(canvasData.rotX || 0, canvasData.rotY || 0, canvasData.rotZ || 0);
            stopMoving();
        }
    };

    const saveCanvasLocations = async (canvasMeshes: BABYLON.AbstractMesh[], onSuccess?: () => void, onFailure?: () => void) => {
        const canvases = canvasMeshes.map((canvas: BABYLON.AbstractMesh) => {
            const nftMetadata = meshNamesToMetadata[canvas.name];
            const canvasData = meshNamesToCanvasData[canvas.name];
            if (!nftMetadata) {
                return null;
            }
            // Get NFT ID, scale, rotation, position relative to space
            const quat = canvas.rotationQuaternion || canvas.rotation.toQuaternion();
            const { levelIndex, levelInstanceIndex } = getLevelFromZPos(canvas.position.z);
            const offset = getOffsetFromLevel(levelIndex);
            const pos = new BABYLON.Vector3(canvasData.x, canvasData.y, canvasData.z).subtract(offset);
            const levelToken = levelTokensRef.current[levelIndex];
            const levelId = `${levelToken.contractAddress}:${levelToken.id}:${levelInstanceIndex}`;
            const nftId = `${nftMetadata.contractAddress}:${nftMetadata.tokenId}`;
            const isBig = meshNamesToCanvasData[canvas.name].isBig;
            const canvasPositionData = {
                position: { x: pos.x, y: pos.y, z: pos.z },
                rotation: { w: quat.w, x: quat.x, y: quat.y, z: quat.z },
                spaceId: levelId,
                scale: isBig ? BIG_CANVAS_SIZE : CANVAS_SIZE,
                id: nftId,
                originalSpaceId: canvasData.levelId,
                originalLevelCanvasIndex: canvasData.levelCanvasIndex
            };
            return canvasPositionData;
        }).filter(canvas => canvas !== null);
        try {
            const response = await fetch(UPDATE_CANVASES_URL, {
                method: 'POST',
                mode: 'cors',
                credentials: 'include',
                cache: 'no-cache',
                body: JSON.stringify({
                    address: resolvedAddress,
                    canvases
                })
            });
            if (response.status === 200) {
                onSuccess();
            } else {
                throw new Error();
            }
        } catch {
            onFailure();
        }
    }

    const onConfirmMove = async () => {
        if (moveImageRef) {
            const moveCanvas = moveImageRef.current;
            moveCanvas.position = moveCanvas.position.clone();
            const moveCanvasData = meshNamesToCanvasData[moveCanvas.name];
            moveCanvasData.x = moveCanvas.position.x;
            moveCanvasData.y = moveCanvas.position.y;
            moveCanvasData.z = moveCanvas.position.z;
            moveCanvasData.rotX = moveCanvas.rotation.x;
            moveCanvasData.rotY = moveCanvas.rotation.y;
            moveCanvasData.rotZ = moveCanvas.rotation.z;

            stopMoving();

            await authenticateWithModal(web3Address, () => {
                const hasCuration = !!gallery.canvases && Object.keys(gallery.canvases).length > 0;
                let canvasMeshes;
                // If first curation, save all canvas locations
                if (!hasCuration) {
                    canvasMeshes = sceneReference.current.getMeshesByTags("canvas");
                } else {
                    canvasMeshes = [moveCanvas];
                }
                const onSaveCanvasesSuccess = () => { };
                const onSaveCanvasesFailure = () => {
                    openErrorModal('error', 'looks like something went wrong while saving canvases. please try again!', 'okay', closeErrorModal, null, null);
                };
                saveCanvasLocations(canvasMeshes, onSaveCanvasesSuccess, onSaveCanvasesFailure);
            }, () => {
                openErrorModal('error', 'looks like something went wrong while authenticating. please contact us on discord or twitter!', 'okay', closeErrorModal, null, null);
            });
        }
    };

    // Apply customizations after submitting the form
    useEffect(() => {
        const initializeLevel = async (scene: BABYLON.Scene) => {
            let offset = 0;
            let nftsRemaining = true;
            let totalNfts = 0;
            let totalNftsAdded = 0;
            let levelsAdded = 0;

            // Fetch all NFTs from OpenSea
            const isLoadingSpecificLevel = !!SPACE_NAME_TO_ID[addressesString];
            let addressToLoadFrom = addressesString;
            if (addressesString === 'test') {
                addressToLoadFrom = 'dom.eth';
            } else if (isLoadingSpecificLevel) {
                addressToLoadFrom = 'hyaliko.eth';
            }
            while (nftsRemaining) {
                const result = await fetch(`${NFT_SERVER_URL}?addresses=${addressToLoadFrom}&offset=${offset}`, {
                    mode: 'cors',
                    credentials: 'same-origin'
                });
                const json = await result.json();
                let nftBatch = json.nfts || [];
                totalNfts += nftBatch.length;
                offset += nftBatch.length;

                const hiddenIds = hiddenIdsMap || {};

                const showNfts = totalNftsAdded < NFT_LIMIT;
                nftBatch.forEach((nft: any) => {
                    const nftId = `${nft.contract_address}:${nft.token_id}`;
                    nft.id = nftId;
                    curateRef.current.nfts[nftId] = {
                        name: nft.name || `${nft.collection_name}: ${nft.token_id}`,
                        id: nftId,
                        hidden: !showNfts || hiddenIds[nftId],
                        imageThumbnailUrl: nft.image_thumbnail_url,
                        imageUrl: nft.image_url
                    }
                    // TODO: This is redundant. Figure out how to reuse this step in the addNftsToScene
                    babylonMemoryRef.current.nftIdsToMetadata[nftId] = formatMetadata(nft);
                });
                setCurate({ nfts: curateRef.current.nfts, collaborators: curate.collaborators, spaces: curate.spaces });

                if (nftBatch.length < NFT_BATCH_SIZE) {
                    nftsRemaining = false;
                }

                // Filter out hidden NFTs and add canvas location data
                if (hiddenIds) {
                    nftBatch = nftBatch.filter((nft: any) => !hiddenIds[`${nft.contract_address}:${nft.token_id}`]);
                    nftBatch.forEach((nft: any) => {
                        const nftId = `${nft.contract_address}:${nft.token_id}`;
                        let canvasLocation = null;
                        if (gallery.canvases && gallery.canvases[nftId]) {
                            nft.canvasLocation = gallery.canvases[nftId];
                        }
                        nft.canvasLocation = canvasLocation;
                    });
                }


                // Add NFTs only up to NFT_LIMIT
                if (totalNftsAdded < NFT_LIMIT) {
                    totalNftsAdded += nftBatch.length;
                    // Add levels if necessary
                    const contractCount = totalNfts;
                    // Load appropriate number of levels and then load the rest of the NFTs (make it at least 1, zero doesn't make sense)
                    const levelTokens = levelTokensRef.current;
                    let numberOfMuseumsToAdd = -1; // (minus one to account for the initial one that's already created)
                    let contractCounter = contractCount;
                    let levelTokenIndex = 0;
                    while (contractCounter > 0) {
                        numberOfMuseumsToAdd++;
                        contractCounter -= levelTokens[levelTokenIndex].canvases.length;
                        levelTokenIndex++;
                        if (levelTokenIndex >= levelTokens.length) {
                            levelTokenIndex = 0;
                        }
                    }
                    numberOfMuseumsToAdd = Math.max(numberOfMuseumsToAdd, 1);
                    numberOfMuseumsToAdd = numberOfMuseumsToAdd - levelsAdded;
                    const levelLoadPromises: Promise<void>[] = [];
                    for (let i = 0; i < numberOfMuseumsToAdd; i++) {
                        levelLoadPromises.push(addLevelSegment(scene, gallery, babylonMemoryRef.current, levelTokens, levelsAdded + i + 1, false));
                    }
                    await Promise.all(levelLoadPromises);
                    levelsAdded = numberOfMuseumsToAdd + levelsAdded;
                    addNFTsToScene(nftBatch, scene, babylonMemoryRef.current, gallery, totalNftsAdded - nftBatch.length, onZoomAreaEnter, onZoomAreaExit, newArt => { artRef.current.push(newArt); setArt(artRef.current.slice()); });
                    if (!nftsRemaining || totalNftsAdded >= NFT_LIMIT) {
                        addLevelSegment(scene, gallery, babylonMemoryRef.current, levelTokens, levelsAdded + 1, true);
                    }
                }
            }
        };

        if (gameState === 1) {
            // const onInspect = (paramMesh: BABYLON.AbstractMesh | null) => {
            //     const mesh = paramMesh || inspectImageRef.current;
            //     if (mesh) {
            //         // Play video if possible
            //         const material = mesh.material as BABYLON.StandardMaterial;
            //         const videoTexture = material && (material.diffuseTexture as BABYLON.VideoTexture);
            //         if (videoTexture && videoTexture.video) {
            //             videoTexture.video.play().catch(() => { });
            //         }
            //         activeImageRef.current = mesh;
            //         cameraAnimationTimerRef.current = 0;
            //     }
            //     document.getElementById('renderCanvas').focus();
            // };
            // const onCloseHeader = () => {
            //     const mesh = activeImageRef.current;
            //     if (mesh) {
            //         const material = mesh.material as BABYLON.StandardMaterial;
            //         const videoTexture = material && (material.diffuseTexture as BABYLON.VideoTexture);
            //         if (videoTexture && videoTexture.video) {
            //             videoTexture.video.pause();
            //         }
            //     }
            //     setHeaderVisible(false);
            //     activeImageRef.current = null;
            //     document.getElementById('renderCanvas').focus();
            // };
            if (DEBUG) {
                sceneReference.current.debugLayer.show();
            }

            // sceneReference.current.debugLayer.show();
            const addresses = addressesString ? addressesString.split(',') : [];
            const canvas: HTMLCanvasElement = document.getElementById('renderCanvas') as HTMLCanvasElement;
            canvas.focus();
            const scene: BABYLON.Scene = sceneReference.current;
            const engine = scene.getEngine();
            if (scene) {
                // Create player
                const colors = Object.keys(playerMaterials).filter(key => key !== 'BLACK' && key !== 'WHITE' && key !== 'LIGHT_PINK');
                const faces = Object.keys(faceMaterials);
                const color = colors[Math.floor(Math.random() * colors.length)];
                const face = selectedImportedAvatar ? selectedImportedAvatar : { type: 'face', image: faces[Math.floor(Math.random() * faces.length)], uri: null, id: null };
                createPlayer(scene, false, playerName, color, face).then(({ playerMesh }) => {

                    // Create camera target
                    const cameraTarget = new BABYLON.AbstractMesh('cameraTarget');
                    cameraTarget.position = playerMesh.position.add(HEIGHT_OFFSET);
                    playerMesh.addChild(cameraTarget);
                    // Create follow camera
                    const camera = scene.getCameraByName('camera1') as BABYLON.ArcRotateCamera;
                    // Target the camera to scene origin
                    camera.lockedTarget = cameraTarget;
                    const inspectCamera = scene.getCameraByName('inspectCamera') as BABYLON.FreeCamera;

                    // Ambient particle system
                    const particles = new BABYLON.ParticleSystem('ambientParticles', 2000, scene);
                    particles.emitRate *= 40;
                    particles.maxScaleX = 1;
                    particles.maxScaleY = 1;
                    particles.minScaleX = 1;
                    particles.minScaleY = 1;
                    particles.minEmitPower = 10;
                    particles.particleTexture = getParticleTexture(scene, levelTokensRef.current[0].isSpaceFactory ? levelTokensRef.current[0].particleShape : 0, levelTokensRef.current[0].isSpaceFactory ? PARTICLE_COLOR_OPTIONS[levelTokensRef.current[0].particleColor] : '#ffffff');
                    particles.blendMode = BABYLON.ParticleSystem.BLENDMODE_MULTIPLYADD;
                    particles.color1 = BABYLON.Color3.White().toColor4(1);
                    particles.color2 = BABYLON.Color3.White().toColor4(1);
                    particles.colorDead = new BABYLON.Color4(1, 1, 1, 0);
                    particles.emitter = scene.getCameraByName('camera1').position;
                    particles.createBoxEmitter(BABYLON.Vector3.UpReadOnly, BABYLON.Vector3.UpReadOnly, new BABYLON.Vector3(-1, -1, -1).scale(100), new BABYLON.Vector3(1, 1, 1).scale(100));
                    particles.start();

                    // Particle syystem for moving canvases
                    const movingCanvasParticles = new BABYLON.ParticleSystem('movingCanvasParticles', 200, scene);
                    particleSystemsRef.current.MOVE_CANVAS = movingCanvasParticles;
                    movingCanvasParticles.maxScaleX = 0.5;
                    movingCanvasParticles.maxScaleY = 0.5;
                    movingCanvasParticles.minScaleX = 0.5;
                    movingCanvasParticles.minScaleY = 0.5;
                    movingCanvasParticles.minEmitPower = 20;
                    movingCanvasParticles.particleTexture = new BABYLON.Texture('/textures/Flare.png', scene);
                    movingCanvasParticles.color1 = BABYLON_COLORS.WHITE.toColor4();
                    movingCanvasParticles.color2 = BABYLON_COLORS.WHITE.toColor4();
                    movingCanvasParticles.emitRate *= 10;
                    movingCanvasParticles.maxLifeTime /= 100;

                    const burstMovingCanvasParticles = movingCanvasParticles.clone('burstMovingCanvasParticles', null);
                    burstMovingCanvasParticles.createSphereEmitter(3, 1);
                    burstMovingCanvasParticles.emitRate = 0;

                    movingCanvasParticles.createBoxEmitter(BABYLON.Vector3.UpReadOnly, BABYLON.Vector3.UpReadOnly, new BABYLON.Vector3(0, 0, 0), new BABYLON.Vector3(0, 0, 0));


                    burstMovingCanvasParticles.minEmitPower = 30;
                    burstMovingCanvasParticles.start();
                    particleSystemsRef.current.BURST_MOVE_CANVAS = burstMovingCanvasParticles;

                    const toggleCanvasScale = (canvas: BABYLON.AbstractMesh) => {
                        const isBig = meshNamesToCanvasData[canvas.name].isBig;
                        // If it's big, change to small and vice verse
                        const newScaling = getCanvasScaling(canvas.scaling, !isBig, false);
                        canvas.scaling = newScaling;
                        meshNamesToCanvasData[canvas.name].isBig = !meshNamesToCanvasData[canvas.name].isBig;
                    };

                    scene.onPointerObservable.add((pointerInfo) => {
                        switch (pointerInfo.type) {
                            case BABYLON.PointerEventTypes.POINTERTAP: {
                                let mesh = pointerInfo.pickInfo.pickedMesh;
                                if (mesh && (BABYLON.Tags.MatchesQuery(mesh, 'player'))) {
                                    hasEmotedRef.current = true;
                                }
                                else if (mesh && mesh.name && mesh.name.startsWith('Canvas')) {
                                    if (moveImageRef.current && mesh.name === moveImageRef.current.name) {
                                        // Change the image size
                                        toggleCanvasScale(mesh);
                                        return;
                                    }
                                    if (moveImageRef.current) {
                                        return;
                                    }
                                    const isReadyToInspect = inspectImageRef.current && (inspectImageRef.current.name === mesh.name);
                                    const alreadyInspecting = activeImageRef.current;
                                    if (alreadyInspecting) {
                                        onQuitInspect();
                                    } else if (isReadyToInspect) {
                                        onInspect(null);
                                    } else {
                                        if (!isReadyToInspect) {
                                            // Warp if we're not already there
                                            playerMesh.position.x = mesh.position.x;
                                            playerMesh.position.y = mesh.position.y;
                                            playerMesh.position.z = mesh.position.z - 3;
                                        }
                                        onInspect(mesh);
                                    }
                                } else if (activeImageRef.current) {
                                    onQuitInspect();
                                }
                                break;
                            }
                            case BABYLON.PointerEventTypes.POINTERDOWN: {
                                loadVideos();
                                let mesh = pointerInfo.pickInfo.pickedMesh;
                                if (mesh && mesh.name && mesh.name.startsWith('Canvas')) {
                                    const alreadyInspecting = activeImageRef.current && (activeImageRef.current.name === mesh.name);
                                    if (!alreadyInspecting) {
                                        onQuitInspect();
                                    }
                                } else if (activeImageRef.current) {
                                    onQuitInspect();
                                }
                                isDraggingRef.current = true;
                                break;
                            }
                            case BABYLON.PointerEventTypes.POINTERUP: {
                                isDraggingRef.current = false;
                                break;
                            }
                        }
                    });

                    scene.onAfterPhysicsObservable.add(() => {
                        playerMesh.physicsImpostor.setAngularVelocity(BABYLON.Vector3.ZeroReadOnly);
                        const playerRotationEuler = playerMesh.rotationQuaternion.toEulerAngles();
                        playerMesh.rotationQuaternion = BABYLON.Quaternion.FromEulerAngles(0, playerRotationEuler.y, 0);
                    });

                    // Bind keys
                    scene.onKeyboardObservable.add((kbInfo) => {
                        loadVideos();
                        if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'w', 'a', 's', 'd', 'c', ' '].includes(kbInfo.event.key)) {
                            switch (kbInfo.type) {

                                case BABYLON.KeyboardEventTypes.KEYDOWN:
                                    keysRef.current[kbInfo.event.key] = true;
                                    break;
                                case BABYLON.KeyboardEventTypes.KEYUP:
                                    keysRef.current[kbInfo.event.key] = false;
                                    break;
                            }
                        }
                    });

                    const frameMaterial = scene.getMaterialByName('frameMaterial') as BABYLON.StandardMaterial;

                    let fallingTimer = 0;
                    const playerGroundVector = BABYLON.Vector3.UpReadOnly.scale(-1);
                    let levelIndexChange = true;
                    let levelIndex = 0;
                    scene.registerBeforeRender(() => {
                        // Check which level they're currently in and change background and colors
                        const playerZ = playerMesh.position.z;
                        let positionCounter = 0;
                        let i = 0;
                        while (playerZ >= positionCounter) {
                            const mapIndex = i % levelTokensRef.current.length;
                            positionCounter += levelTokensRef.current[mapIndex].offset.z;
                            if (playerZ < positionCounter) {
                                if (levelIndex !== i) {
                                    levelIndex = i;
                                    levelIndexChange = true;
                                }
                                break;
                            }
                            i++;
                        }
                        const distanceToNextLayout = positionCounter - playerZ;
                        if (distanceToNextLayout < 200 && positionCounter > 0) {
                            const currentLevel = levelTokensRef.current[levelIndex % levelTokensRef.current.length];
                            const nextLevel = levelTokensRef.current[(levelIndex + 1) % levelTokensRef.current.length];
                            const fromTerrain = currentLevel.isSpaceFactory ? BABYLON_COLOR_OPTIONS[currentLevel.terrain] : BABYLON_COLORS.PINK;
                            const toTerrain = nextLevel.isSpaceFactory ? BABYLON_COLOR_OPTIONS[nextLevel.terrain] : BABYLON_COLORS.PINK;
                            const fromBackground = currentLevel.isSpaceFactory ? BABYLON4_BACKGROUND_COLOR_OPTIONS[currentLevel.background] : LIGHT_PINK;
                            const toBackground = nextLevel.isSpaceFactory ? BABYLON4_BACKGROUND_COLOR_OPTIONS[nextLevel.background] : LIGHT_PINK;
                            scene.ambientColor = BABYLON.Color3.Lerp(fromTerrain, toTerrain, (200 - distanceToNextLayout) / 200);
                            scene.clearColor = BABYLON.Color4.Lerp(fromBackground, toBackground, (200 - distanceToNextLayout) / 200);

                            // Make frames pink
                            if (nextLevel.isSpaceFactory && (nextLevel.terrain === 0 || nextLevel.background === 0)) {
                                frameMaterial.diffuseColor = BABYLON.Color3.Lerp(frameMaterial.diffuseColor, BABYLON_COLORS.PINK, (200 - distanceToNextLayout) / 200);
                                frameMaterial.ambientColor = BABYLON.Color3.Lerp(frameMaterial.diffuseColor, BABYLON_COLORS.PINK, (200 - distanceToNextLayout) / 200);
                                frameMaterial.emissiveColor = BABYLON.Color3.Lerp(frameMaterial.diffuseColor, BABYLON_COLORS.PINK, (200 - distanceToNextLayout) / 200);
                            } else {
                                frameMaterial.diffuseColor = BABYLON.Color3.Lerp(frameMaterial.diffuseColor, BABYLON_COLORS.WHITE, (200 - distanceToNextLayout) / 200);
                                frameMaterial.ambientColor = BABYLON.Color3.Lerp(frameMaterial.diffuseColor, BABYLON_COLORS.WHITE, (200 - distanceToNextLayout) / 200);
                                frameMaterial.emissiveColor = BABYLON.Color3.Lerp(frameMaterial.diffuseColor, BABYLON_COLORS.WHITE, (200 - distanceToNextLayout) / 200);
                            }
                        }

                        if (levelIndexChange) {
                            const level = levelTokensRef.current[levelIndex % levelTokensRef.current.length];
                            const terrain = level.isSpaceFactory ? BABYLON_COLOR_OPTIONS[level.terrain] : BABYLON_COLORS.PINK;
                            const background = level.isSpaceFactory ? BABYLON4_BACKGROUND_COLOR_OPTIONS[level.background] : LIGHT_PINK;
                            const particleTexture = level.isSpaceFactory ? getParticleTexture(scene, level.particleShape, PARTICLE_COLOR_OPTIONS[level.particleColor]) : getParticleTexture(scene, 0, '#ffffff');
                            scene.ambientColor = terrain;
                            scene.clearColor = background;
                            particles.particleTexture = particleTexture;

                            // Make frames pink
                            if (level.isSpaceFactory && (level.terrain === 0 || level.background === 0)) {
                                frameMaterial.diffuseColor = BABYLON_COLORS.PINK;
                                frameMaterial.ambientColor = BABYLON_COLORS.PINK;
                                frameMaterial.emissiveColor = BABYLON_COLORS.PINK;
                            } else {
                                frameMaterial.diffuseColor = BABYLON_COLORS.WHITE;
                                frameMaterial.ambientColor = BABYLON_COLORS.WHITE;
                                frameMaterial.emissiveColor = BABYLON_COLORS.WHITE;
                            }

                            levelIndexChange = false;
                        }
                        const keys = keysRef.current;

                        if (keys[' ']) {
                            hasEmotedRef.current = true;
                        }
                        // Emote
                        if (hasEmotedRef.current) {
                            playerMesh.getConnectedParticleSystems().forEach(particles => {
                                if (particles.name.includes('emote')) {
                                    particles.start();
                                }
                            });
                        }

                        // Camera controls
                        if (keys.w || keys.a || keys.s || keys.d) {
                            const alphaRotation = keys.a ? 0.02 : keys.d ? -0.02 : 0;
                            cameraAlphaRef.current += alphaRotation;
                            const betaRotation = keys.w ? 0.02 : keys.s ? -0.02 : 0;
                            cameraBetaRef.current += betaRotation;
                            loadVideos();
                            if (activeImageRef.current) {
                                onQuitInspect();
                            }
                        }
                        if (keys.c) {
                            setMenuVisible(true);
                        }
                        if (joystickLookRef.current.x !== 0 || joystickLookRef.current.y !== 0) {
                            const alphaRotation = -joystickLookRef.current.x / 50;
                            cameraAlphaRef.current += alphaRotation;
                            const betaRotation = joystickLookRef.current.y / 50;
                            cameraBetaRef.current += betaRotation;
                        }
                        cameraBetaRef.current = Math.min(cameraBetaRef.current, Math.PI / 2);
                        cameraBetaRef.current = Math.max(cameraBetaRef.current, -Math.PI / 2);

                        // Move
                        let velocityVector = null;

                        // Joystick controls
                        if (joystickMoveRef.current.x !== 0 || joystickMoveRef.current.y !== 0) {
                            loadVideos();
                            velocityVector = new BABYLON.Vector3(joystickMoveRef.current.x, 0, joystickMoveRef.current.y).normalize().scaleInPlace(PLAYER_ACCELERATION);
                        }

                        if (isDraggingRef.current && scene.pointerX && scene.pointerY && !isMobile) {
                            loadVideos();
                            const cameraViewport = camera.viewport.toGlobal(
                                engine.getRenderWidth(),
                                engine.getRenderHeight()
                            );
                            const playerScreenPosition = BABYLON.Vector3.Project(
                                playerMesh.getAbsolutePosition(),
                                BABYLON.Matrix.IdentityReadOnly,
                                scene.getTransformMatrix(),
                                cameraViewport
                            );
                            const pointerPlayerDelta = new BABYLON.Vector2(-(playerScreenPosition.x - scene.pointerX), playerScreenPosition.y - scene.pointerY);
                            if (pointerPlayerDelta.length() > 50) {
                                velocityVector = new BABYLON.Vector3(pointerPlayerDelta.x, 0, pointerPlayerDelta.y).normalize().scaleInPlace(PLAYER_ACCELERATION);
                            }
                        } else if (keys.ArrowUp || keys.ArrowDown || keys.ArrowLeft || keys.ArrowRight) {
                            const xVelocity = keys.ArrowLeft ? -1 : keys.ArrowRight ? 1 : 0;
                            const yVelocity = keys.ArrowDown ? -1 : keys.ArrowUp ? 1 : 0;
                            velocityVector = new BABYLON.Vector3(xVelocity, 0, yVelocity).normalize().scaleInPlace(PLAYER_ACCELERATION);
                        }

                        // If there's a velocity vector, rotate it around camera
                        let unrotatedVelocity = null;
                        if (velocityVector) {
                            unrotatedVelocity = velocityVector.clone();
                            velocityVector.rotateByQuaternionAroundPointToRef(BABYLON.Quaternion.FromEulerAngles(0, -cameraAlphaRef.current, 0), BABYLON.Vector3.ZeroReadOnly, velocityVector);
                        }

                        let currentVelocity = playerMesh.physicsImpostor.getLinearVelocity();
                        // Add movement speed
                        const isWizard = BABYLON.Tags.MatchesQuery(playerMesh, 'wizard');
                        const isSprite = BABYLON.Tags.MatchesQuery(playerMesh, 'sprite');
                        if (velocityVector) {
                            // If it's a wizard, update the sprite, otherwise rotate
                            if (isWizard) {
                                const playerInfo = playerIdsToInfoRef.current[clientIdRef.current];
                                if (playerInfo) {
                                    // Update sprite depending on velocity
                                    const playerTexture = (playerMesh.material as BABYLON.StandardMaterial).diffuseTexture as BABYLON.Texture;
                                    if (unrotatedVelocity.z > 0) {
                                        // Start at -0.25 and move in intervals of 0.25
                                        playerTexture.vOffset = 0.25
                                    } else if (unrotatedVelocity.z < 0) {
                                        playerTexture.vOffset = -0.25
                                    }
                                    if (unrotatedVelocity.x > 1) {
                                        playerTexture.vOffset = 0.5;
                                    } else if (unrotatedVelocity.x < -1) {
                                        playerTexture.vOffset = 0;
                                    }
                                    // Sprite animate
                                    if (playerInfo.animationTimer > 200) {
                                        // Next frame
                                        if (playerTexture.uOffset <= -0.55) {
                                            playerTexture.uOffset = 0.2;
                                        } else {
                                            playerTexture.uOffset -= 0.25;
                                        }
                                        playerInfo.animationTimer = 0;
                                    } else {
                                        playerInfo.animationTimer += scene.deltaTime;
                                    }
                                }
                            } else if (isSprite) {
                                if (unrotatedVelocity.x >= 0) {
                                    playerMesh.scaling.x = 2;
                                } else if (unrotatedVelocity.x < 0) {
                                    playerMesh.scaling.x = -2;
                                }
                            } else {
                                playerMesh.lookAt(playerMesh.position.add(velocityVector));
                            }
                            currentVelocity.addInPlace(velocityVector);
                            playerRotationRef.current = BABYLON.Vector3.GetAngleBetweenVectors(new BABYLON.Vector3(velocityVector.x, 0, velocityVector.z), new BABYLON.Vector3(0, 0, 1), new BABYLON.Vector3(0, -1, 0));
                        } else if (isWizard) {
                            const playerInfo = playerIdsToInfoRef.current[clientIdRef.current];
                            if (playerInfo) {
                                playerInfo.animationTimer = 0;
                                const playerTexture = (playerMesh.material as BABYLON.StandardMaterial).diffuseTexture as BABYLON.Texture;
                                playerTexture.uOffset = 0.2;
                            }
                        }
                        // Clamp speed
                        const currentVelocityNoY = currentVelocity.clone();
                        currentVelocityNoY.y = 0;
                        const clampedVelocityMagnitude = Math.min(currentVelocityNoY.length(), MAX_PLAYER_SPEED);
                        const finalClampedVelocity = currentVelocityNoY.normalize().scaleInPlace(clampedVelocityMagnitude);
                        finalClampedVelocity.y = currentVelocity.y;
                        if (currentVelocity.y > MAX_PLAYER_VERTICAL_SPEED) {
                            finalClampedVelocity.y = MAX_PLAYER_VERTICAL_SPEED;
                        }
                        playerMesh.physicsImpostor.setLinearVelocity(finalClampedVelocity);

                        currentVelocity = playerMesh.physicsImpostor.getLinearVelocity();
                        const fieldRay = new BABYLON.Ray(playerMesh.position.add(playerMesh.forward), playerGroundVector, 10);
                        const hit = scene.pickWithRay(fieldRay, mesh => mesh.isPickable && !BABYLON.Tags.MatchesQuery(mesh, 'player') && !mesh.name.startsWith('Canvas'));
                        if (hit && hit.pickedPoint) {
                            fallingTimer = null;
                            const height = playerMesh.position.y - hit.pickedPoint.y - 0.25;
                            currentVelocity.y += Math.min(1, (2.2 / (height * height)));
                        } else {
                            // if no picked point for 5 seconds, check if player is falling and reset
                            if (fallingTimer === null) {
                                fallingTimer = Date.now();
                            } else {
                                if (Date.now() - fallingTimer > 5000) {
                                    // Reset player if falling
                                    const deathHit = scene.pickWithRay(new BABYLON.Ray(playerMesh.position.add(playerMesh.forward), BABYLON.Vector3.UpReadOnly.scale(-100)), mesh => mesh.isPickable && !BABYLON.Tags.MatchesQuery(mesh, 'player') && !mesh.name.startsWith('Canvas') && !mesh.name.endsWith('Trigger'));
                                    if ((!deathHit || !deathHit.pickedPoint) && !HIGH_SPEED) {
                                        playerMesh.position = PLAYER_STARTING_POSITION;
                                        playerMesh.physicsImpostor.setLinearVelocity(BABYLON.Vector3.Zero());
                                    } else {
                                        fallingTimer = null;
                                    }
                                }
                            }
                        }
                        currentVelocity.y *= 0.99;
                        if (HIGH_SPEED && isDraggingRef.current) {
                            currentVelocity.y = 20;
                        }
                        playerMesh.physicsImpostor.setLinearVelocity(currentVelocity);

                        // Apply friction
                        if (!velocityVector) {
                            playerMesh.physicsImpostor.setLinearVelocity(new BABYLON.Vector3(currentVelocity.x * 0.9, currentVelocity.y, currentVelocity.z * 0.9));
                        }

                        // Dash
                        if (hasDashedRef.current) {
                            if (!lastDashedRef.current || (Date.now() - lastDashedRef.current > 2000)) {
                                playerMesh.physicsImpostor.setLinearVelocity(playerMesh.physicsImpostor.getLinearVelocity().add(playerMesh.forward.scale(DASH_POWER)));
                                hasEmotedRef.current = true;
                                hasDashedRef.current = false;
                                lastDashedRef.current = Date.now();
                                dashAnimationTimerRef.current = 0;
                                // TODO: UI stuff. Disabled button etc.
                            } else {
                                hasDashedRef.current = false;
                            }
                        }

                        // Rotate name meshes towards camera
                        const players = playersRef.current;
                        const playerIdsToNameMeshes = playerIdsToNameMeshesRef.current;
                        Object.keys(playerIdsToNameMeshes).forEach(id => {
                            const playerMesh = players[id];
                            const nameMesh = playerIdsToNameMeshes[id];
                            // Player has been deleted, delete the name
                            if (!playerMesh && nameMesh) {
                                scene.removeMesh(nameMesh);
                                delete playerIdsToNameMeshes[id];
                            } else if (playerMesh && nameMesh) {
                                nameMesh.position.x = playerMesh.position.x;
                                nameMesh.position.y = playerMesh.getHierarchyBoundingVectors().max.y + 2;
                                nameMesh.position.z = playerMesh.position.z;
                                nameMesh.rotation.y = -cameraAlphaRef.current;
                            }
                        });

                        // Rotate 2D avatars to face the camera
                        scene.getMeshesByTags('avatar2D').forEach(avatar => {
                            avatar.lookAt(new BABYLON.Vector3(camera.position.x, avatar.position.y, camera.position.z))
                        });

                        // Update camera
                        if (activeImageRef.current === null) {
                            camera.lockedTarget = playerMesh.getChildren(node => node.name === 'cameraTarget')[0];
                            camera.alpha = BABYLON.Scalar.Lerp(camera.alpha, CAMERA_INITIAL_ALPHA + cameraAlphaRef.current, 0.1);
                            camera.beta = BABYLON.Scalar.Lerp(camera.beta, CAMERA_INITIAL_BETA + cameraBetaRef.current, 0.1);
                            scene.activeCamera = camera;
                            inspectCamera.position = camera.position;
                            inspectCamera.rotation = camera.rotation;
                            // const newCameraPosition = playerMesh.position;
                            // const playerVelocity = playerMesh.physicsImpostor.getLinearVelocity();
                            // camera.position.y = newCameraPosition.y;
                            // if (playerVelocity.z < 0) {
                            //     newCameraPosition.z -= ((playerVelocity.z * playerVelocity.z) * (IS_MOBILE ? 0.0125 : 0.025));
                            // }
                            // camera.position = newCameraPosition;
                        } else {
                            const picture = activeImageRef.current;
                            if (picture) {
                                const canvasData = meshNamesToCanvasData[picture.name];
                                const isBig = canvasData && canvasData.isBig;
                                inspectCamera.lockedTarget = picture;
                                const bigOffset = -50;
                                const offset = isMobile ? -20 : -30;
                                const newCameraPosition = picture.position.clone().add(picture.forward.scale(isBig ? bigOffset : offset));
                                cameraAnimationTimerRef.current += engine.getDeltaTime() / 1000;
                                BABYLON.Vector3.LerpToRef(inspectCamera.position, newCameraPosition, Math.min(Math.max(cameraAnimationTimerRef.current, 0), 1), inspectCamera.position);
                                scene.activeCamera = inspectCamera;
                            }
                        }

                        // Dash animation and camera shake
                        if (dashAnimationTimerRef.current < DASH_ANIMATION_LENGTH) {
                            camera.position.x += 0.25 - (0.5 * Math.random());
                            camera.position.y += 0.25 - (0.5 * Math.random());
                            dashAnimationTimerRef.current += engine.getDeltaTime();
                        }

                        if (moveImageRef.current) {
                            const canvasBounding = moveImageRef.current.getBoundingInfo()
                            const canvasSize = canvasBounding.boundingBox.maximumWorld.y - canvasBounding.boundingBox.minimumWorld.y;
                            moveImageRef.current.rotation.y = Math.round((playerRotationRef.current / (Math.PI / 4))) * (Math.PI / 4);
                            moveImageRef.current.position = new BABYLON.Vector3(playerMesh.position.x, playerMesh.position.y + (canvasSize / 2), playerMesh.position.z).add(moveImageRef.current.forward.scale(7 + Math.max(0, canvasSize - 9)));
                            // moveImageRef.current.lookAt(new BABYLON.Vector3(-playerMesh.forward.x * 5, moveImageRef.current.position.y, -playerMesh.forward.z * 5));
                            // moveImageRef.current.position.add(new BABYLON.Vector3(playerMesh.forward.x * 5, moveImageRef.current.position.y, playerMesh.forward.z * 5));
                            // moveImageRef.current.position.y = playerMesh.position.y + canvasSize + 1;
                            // moveImageRef.current.rotation.y = -cameraAlphaRef.current;
                        }

                        // Check canvas collisions
                        const canvasHitBoxes = sceneReference.current.getMeshesByTags("canvasHitBox");
                        let closestCanvas: BABYLON.AbstractMesh = null;
                        let closestCanvasDistance: number = null;
                        canvasHitBoxes.forEach(canvasHitBox => {
                            if (!canvasHitBox.isEnabled()) {
                                return;
                            }
                            const intersectsPlayer = canvasHitBox.intersectsMesh(playerMesh);
                            if (intersectsPlayer) {
                                const canvas = canvasHitBox.parent as BABYLON.AbstractMesh;
                                if (!closestCanvas) {
                                    closestCanvas = canvas;
                                    closestCanvasDistance = BABYLON.Vector3.Distance(playerMesh.position, canvas.position);
                                } else {
                                    const currentCanvasDistance = BABYLON.Vector3.Distance(playerMesh.position, canvas.position);
                                    if (currentCanvasDistance < closestCanvasDistance) {
                                        closestCanvas = canvas;
                                        closestCanvasDistance = currentCanvasDistance;
                                    }
                                }
                            }
                        });
                        if (closestCanvas) {
                            const texture = meshNamesToTextures[closestCanvas.name];
                            if (texture && (texture as BABYLON.VideoTexture).video) {
                                try {
                                    (texture as BABYLON.VideoTexture).video.play().catch(() => { });
                                } catch { }
                            }
                            onZoomAreaEnter(closestCanvas);
                        } else {
                            onZoomAreaExit();
                        }
                    });

                    // Start fetching NFTs
                    if (addresses.length > 0 && addresses[0]) {
                        initializeLevel(scene);
                    }

                    scene.registerAfterRender(() => {
                        hasEmotedRef.current = false;
                    });

                    // Create websocket
                    const webSocket = new WebSocket(WEBSOCKET_URL);
                    webSocket.onopen = () => {
                        webSocket.onmessage = (rawMessage) => {
                            const message = JSON.parse(rawMessage.data);
                            const players = playersRef.current;
                            const playerIdsToNameMeshes = playerIdsToNameMeshesRef.current;
                            const playerIdsToInfo = playerIdsToInfoRef.current;
                            switch (message.type) {
                                case 'AssignId': {
                                    const clientId = message.contents.clientId;
                                    clientIdRef.current = clientId;
                                    players[clientId] = playerMesh;
                                    playerIdsToNameMeshes[clientId] = scene.getMeshByName('playerName');
                                    playerIdsToInfo[clientId] = { name: playerName, avatar: selectedImportedAvatar ? selectedImportedAvatar.type : 'face', animationTimer: 0 };
                                    peopleRef.current.push({ id: clientId, name: playerName });
                                    setPeople(peopleRef.current.slice());
                                    break;
                                }
                                case 'NewPlayers': {
                                    Object.keys(message.contents).forEach(newPlayerId => {
                                        if (newPlayerId in players) {
                                            return;
                                        }
                                        const newPlayer = message.contents[newPlayerId];
                                        setChatPreview({ message: `${newPlayer.name} joined!` });
                                        peopleRef.current.push({ id: newPlayerId, name: newPlayer.name });
                                        createPlayer(scene, true, newPlayer.name, newPlayer.avatar.color, { type: newPlayer.avatar.faceType, image: newPlayer.avatar.image, uri: newPlayer.avatar.uri, id: newPlayer.avatar.id }, newPlayerId).then(({ playerMesh, playerNameMesh }) => {
                                            playerMesh.position.z = -1000;
                                            players[newPlayerId] = playerMesh
                                            playerIdsToInfo[newPlayerId] = { name: newPlayer.name, avatar: newPlayer.avatar.faceType, animationTimer: 0 };
                                            if (playerNameMesh) {
                                                playerIdsToNameMeshes[newPlayerId] = playerNameMesh;
                                            }
                                        });
                                    });
                                    setUnreadChats(true);
                                    setPeople(peopleRef.current.slice());
                                    break;
                                }
                                case 'RoomUpdate': {
                                    const playerStates = message.contents;
                                    // Update existing players
                                    if (playerStates) {
                                        const newChats: any[] = [];
                                        Object.keys(playerStates).forEach((playerId: string) => {
                                            if (!(playerId in players)) {
                                                // If we're missing a player, we might have missed an update.
                                                // Request the players again.
                                                MULTIPLAYER_ENABLED && webSocket.send(JSON.stringify({
                                                    type: 'RequestPlayers',
                                                    contents: {}
                                                }));
                                                return;
                                            }
                                            if (playerId === clientIdRef.current) {
                                                return;
                                            }
                                            const playerData = playerStates[playerId];
                                            const playerMesh = players[playerId];
                                            const playerInfo = playerIdsToInfo[playerId];
                                            if (playerMaterials)
                                                if (playerMesh) {
                                                    const player = players[playerId];
                                                    if (playerInfo.avatar !== 'Forgotten Runes Wizards Cult' && playerInfo.avatar !== 'Spritely - Genesis Collection') {
                                                        player.rotationQuaternion.w = playerData.rotationW;
                                                        player.rotationQuaternion.x = playerData.rotationX;
                                                        player.rotationQuaternion.y = playerData.rotationY;
                                                        player.rotationQuaternion.z = playerData.rotationZ;
                                                    } else {
                                                        const oldPos = player.position.subtract(camera.position);
                                                        const newPos = new BABYLON.Vector3(playerData.x, playerData.y, playerData.z).subtract(camera.position);
                                                        const cameraView = camera.getViewMatrix();
                                                        const rotatedOldPos = BABYLON.Vector3.TransformCoordinates(oldPos, cameraView);
                                                        const rotatedNewPos = BABYLON.Vector3.TransformCoordinates(newPos, cameraView);
                                                        const relativeMovement = rotatedNewPos.subtract(rotatedOldPos);
                                                        relativeMovement.y = 0;
                                                        // TODO make sure the movement is above a certain threshold to count
                                                        const playerTexture = (playerMesh.material as BABYLON.StandardMaterial).diffuseTexture as BABYLON.Texture;
                                                        if (relativeMovement.length() > 0.07) {
                                                            if (relativeMovement.z > 0.1) {
                                                                // Start at -0.25 and move in intervals of 0.25
                                                                playerTexture.vOffset = 0.25
                                                            } else if (relativeMovement.z < -0.1) {
                                                                playerTexture.vOffset = -0.25
                                                            }
                                                            if (relativeMovement.x > 0.2) {
                                                                playerTexture.vOffset = 0.5;
                                                            } else if (relativeMovement.x < -0.2) {
                                                                playerTexture.vOffset = 0;
                                                            }

                                                            // Sprite animate
                                                            if (playerInfo.animationTimer > 200) {
                                                                // Next frame
                                                                if (playerTexture.uOffset <= -0.55) {
                                                                    playerTexture.uOffset = 0.2;
                                                                } else {
                                                                    playerTexture.uOffset -= 0.25;
                                                                }
                                                                playerInfo.animationTimer = 0;
                                                            } else {
                                                                playerInfo.animationTimer += scene.deltaTime;
                                                            }
                                                        } else {
                                                            playerInfo.animationTimer = 0;
                                                            playerTexture.uOffset = 0.2;
                                                        }

                                                    }
                                                    player.position.x = playerData.x;
                                                    player.position.y = playerData.y;
                                                    player.position.z = playerData.z;
                                                    if (playerData.emoting) {
                                                        player.getConnectedParticleSystems().forEach(particles => {
                                                            if (particles.name.includes('emote')) {
                                                                particles.start();
                                                            }
                                                        });
                                                    }

                                                    if (playerData.chat) {
                                                        newChats.push({ id: playerId, name: playerIdsToInfo[playerId].name, message: playerData.chat, timestamp: Date.now(), formattedTimestamp: getFormattedDate() });
                                                    }
                                                }
                                        });
                                        if (newChats.length > 0) {
                                            setUnreadChats(true);
                                            setChatPreview(newChats[newChats.length - 1]);
                                            chatRef.current.push(...newChats);
                                            setChat(chatRef.current.slice());
                                        }
                                    }
                                    break;
                                }
                                case 'DeletePlayers': {
                                    const deletedPlayers = message.contents;
                                    if (deletedPlayers && Array.isArray(deletedPlayers)) {
                                        deletedPlayers.forEach((playerId: string) => {
                                            const deleteMesh = players[playerId];
                                            if (deleteMesh) {
                                                deleteMesh.physicsImpostor.dispose();
                                                deleteMesh.getConnectedParticleSystems().forEach(system => system.dispose());
                                                scene.removeMesh(deleteMesh, true);
                                                delete players[playerId];
                                            }

                                            // Find in people ref and update menu
                                            peopleRef.current.splice(peopleRef.current.findIndex(person => person.id === playerId), 1);
                                            setPeople(peopleRef.current.slice());
                                        });
                                    }
                                    break;
                                }
                                default:
                                    break;
                            }
                        };
                        if (addresses.length > 0 && addresses[0]) {
                            MULTIPLAYER_ENABLED && webSocket.send(JSON.stringify({ type: 'CreateOrJoinRoom', contents: { name: playerName, avatar: { color, faceType: face.type, image: face.image, uri: face.uri, id: face.id }, roomCode: `${addressesString}${query.current.get("levels") ? query.current.get("levels") : ''}` } }));
                        }
                    };
                    webSocketReference.current = webSocket;

                    // TODO: Only be sending if there is someone else in the space
                    if (addresses.length > 0 && addresses[0]) {
                        scene.registerBeforeRender(() => {
                            // Send position update
                            if (webSocket.readyState === WebSocket.OPEN) {
                                MULTIPLAYER_ENABLED && webSocket.send(JSON.stringify({
                                    type: 'UpdatePlayer',
                                    contents: {
                                        x: playerMesh.position.x,
                                        y: playerMesh.position.y,
                                        z: playerMesh.position.z,
                                        rotationW: playerMesh.rotationQuaternion.w,
                                        rotationX: playerMesh.rotationQuaternion.x,
                                        rotationY: playerMesh.rotationQuaternion.y,
                                        rotationZ: playerMesh.rotationQuaternion.z,
                                        emoting: hasEmotedRef.current,
                                        chat: chatMessageRef.current
                                    }
                                }));
                                if (chatMessageRef.current) {
                                    chatRef.current.push({ id: clientIdRef.current, name: 'me', message: chatMessageRef.current, timestamp: Date.now(), formattedTimestamp: getFormattedDate() });
                                    setChat(chatRef.current.slice());
                                    chatMessageRef.current = null;
                                }
                            }
                        });
                    }
                });
            }
        }
        // Don't mess with these dependencies lol
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playerName, setInfo, setInspectButtonVisible, setHeaderVisible, setDescriptionExpanded, gameState, addressesString, history, url]);

    // Mount Babylon
    useEffect(() => {
        if (!galleryLoaded) {
            return;
        }

        // Initialize variables
        meshNamesToMetadata = {};
        meshNamesToHoverAnimatable = {};
        meshNamesToCanvasData = {};

        // Get the canvas DOM element
        const canvas: HTMLCanvasElement = document.getElementById('renderCanvas') as HTMLCanvasElement;
        // Load the 3D engine
        const engine = new BABYLON.Engine(canvas, false, {
            preserveDrawingBuffer: true, stencil: true, xrCompatible: false
            // doNotHandleContextLost: true,
            // deterministicLockstep: true,
            // lockstepMaxSteps: 4,
        }, false);
        engine.enableOfflineSupport = false;

        const isLoadingSpecificLevel = !!SPACE_NAME_TO_ID[addressesString];

        // Check the level layout for this address
        // Get the tokens to determine layout  
        const spacePromises = [];
        if (!isLoadingSpecificLevel) {
            // Load in space factory spaces if not looking at a specific level
            if (!query.current.get("levels")) {
                spacePromises.push(fetch(`${HYALIKO_SPACE_FACTORY_URL}?addresses=${addressesString}`, {
                    mode: 'cors',
                    credentials: 'same-origin'
                }));
            }
            spacePromises.push(fetch(`${HYALIKO_TOKENS_URL}?addresses=${addressesString}${query.current.get("levels") ? `&tokenIDs=${query.current.get("levels")}` : ''}`, {
                mode: 'cors',
                credentials: 'same-origin'
            }));
        } else {
            spacePromises.push(fetch(`${HYALIKO_TOKENS_URL}?tokenIDs=${SPACE_NAME_TO_ID[addressesString]}`, {
                mode: 'cors',
                credentials: 'same-origin'
            }));
        }

        Promise.all(spacePromises).catch(() => null)
            .then(async ([res1, res2]) => {
                const formattedResponse = {
                    spaceTokens: [] as any[],
                    spaceFactoryTokens: [] as any[]
                };
                // If res2, we have hyaliko space factory response
                if (res2) {
                    formattedResponse.spaceFactoryTokens = (await res1.json()).tokens || [];
                    formattedResponse.spaceTokens = (await res2.json()).tokens || [];
                } else {
                    formattedResponse.spaceTokens = (await res1.json()).tokens || [];
                }
                return formattedResponse;
            })
            .then(({ spaceTokens, spaceFactoryTokens }) => {
                let tokens = [...spaceFactoryTokens, ...spaceTokens];
                tokens = tokens.filter(nft => isLoadingSpecificLevel || nft.name);
                tokens.forEach(token => {
                    token.id = `${token.contract_address}:${token.token_id}`;
                });
                // Sort tokens by curation
                if (gallery.hyalikoSpaces) {
                    const spaces = gallery.hyalikoSpaces;
                    tokens.forEach((nft: any) => nft.hidden = (spaces[nft.id] !== undefined && spaces[nft.id].hidden));
                    tokens = tokens.sort((nftA: any, nftB: any) => {
                        if (!spaces[nftA.id]) {
                            return 1;
                        }
                        if (!spaces[nftB.id]) {
                            return -1;
                        }
                        return spaces[nftA.id].order - spaces[nftB.id].order;
                    });
                }
                setCurate({ ...curate, spaces: tokens });
                tokens = tokens.filter(space => !space.hidden);
                if (!tokens || tokens.length === 0) {
                    tokens = [{ uri: 'https://www.hyaliko.com/tokens/default', token_id: 0, contract_address: 'default', id: 'default:0', hidden: false }];
                }
                const tokenPromises = tokens.map(({ uri }: { uri: string }) => {
                    const replaceString = 'https://www.hyaliko.com';
                    let parsedUri = uri;
                    if (parsedUri.startsWith(replaceString)) {
                        parsedUri = uri.substring(replaceString.length, uri.length);
                    }
                    return fetch(parsedUri).then(res => res.json()).catch(() => null);
                })

                Promise.all(tokenPromises)
                    .then((tokenMetadata: any[]) => {
                        tokenMetadata = tokenMetadata.map((token, i) => ({
                            ...token,
                            id: tokens[i].token_id,
                            contractAddress: tokens[i].contract_address,
                            isSpaceFactory: token.terrain !== null && token.terrain !== undefined
                        }));
                        tokenMetadata.filter(token => token).forEach(token => levelTokensRef.current.push(token));
                        // call the createScene function
                        createScene(engine, babylonMemoryRef.current, gallery, levelTokensRef.current).then((scene: BABYLON.Scene) => {
                            sceneReference.current = scene;

                            // run the render loop
                            engine.runRenderLoop(function () {
                                scene.render();
                            });

                            // the canvas/window resize event handler
                            window.addEventListener('resize', function () {
                                engine.resize();
                            });

                            setReady(true);
                        });
                    });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressesString, galleryLoaded]);

    const openMenu = () => {
        setMenuVisible(true);
        setUnreadChats(false);
    };

    const audio = document.getElementById('soundtrack') as HTMLAudioElement;

    const uploadSpace = (fileInputRef: any, segmentPlace: number) => {
        const scene = sceneReference.current;
        const spaceUrl = URL.createObjectURL(fileInputRef.current.files[0]);
        scene.getMeshesByTags("level").forEach(mesh => {
            mesh.physicsImpostor && mesh.physicsImpostor.dispose();
            mesh.dispose();
        });
        for (let i = 0; i < 10; i++) {
            BABYLON.SceneLoader.ImportMeshAsync(
                "",
                spaceUrl,
                "",
                scene,
                null,
                '.obj'
            ).then(result => {
                result.meshes.forEach(mesh => {
                    mesh.position.z += (1000 * i);
                    BABYLON.Tags.AddTagsTo(mesh, "level");
                    if (!mesh.name.includes('NoPhysics')) {
                        const levelPhysics = new BABYLON.PhysicsImpostor(mesh, BABYLON.PhysicsImpostor.MeshImpostor, { mass: 0, restitution: 0, friction: 0 }, scene);
                        mesh.physicsImpostor = levelPhysics;
                        mesh.isPickable = true;
                    }
                    mesh.cullingStrategy = BABYLON.AbstractMesh.CULLINGSTRATEGY_BOUNDINGSPHERE_ONLY;
                });
            });
        }
    };

    useEffect(() => {
        return () => {
            meshNamesToMetadata = {};
            meshNamesToHoverAnimatable = {};
            meshNamesToCanvasData = {};
            meshNamesToTextures = {};
            colorToMaterial = {};
            indexToParticleTexture = {};
            videos = [];
            sceneReference.current && sceneReference.current.dispose();
            webSocketReference.current && webSocketReference.current.close();
        };
    }, []);

    useEffect(() => {
        let rightManager: nipplejs.JoystickManager = null;
        let leftManager: nipplejs.JoystickManager = null;
        if (gameState === 1) {
            const sharedOptions = {
                mode: 'static' as 'static',
                position: { top: '50%', left: '50%' },
                restOpacity: 1
            };
            const leftOptions = {
                zone: document.getElementById('leftJoystick'),
                ...sharedOptions
            };
            const rightOptions = {
                zone: document.getElementById('rightJoystick'),
                ...sharedOptions
            };

            leftManager = nipplejs.create(leftOptions);
            leftManager.on('move' as nipplejs.JoystickManagerEventTypes, (e, data) => {
                loadVideos();
                if (activeImageRef.current) {
                    onQuitInspect();
                }
                if (Math.sqrt(data.vector.x ** 2 + data.vector.y ** 2) > 0.5) {
                    joystickMoveRef.current.x = data.vector.x;
                    joystickMoveRef.current.y = data.vector.y;
                } else {
                    joystickMoveRef.current.x = 0;
                    joystickMoveRef.current.y = 0;
                }
            });
            leftManager.on('end' as nipplejs.JoystickManagerEventTypes, (e, data) => {
                joystickMoveRef.current.x = 0;
                joystickMoveRef.current.y = 0;
            });
            const leftFront = leftManager.get(undefined).ui.front;
            const leftBack = leftManager.get(undefined).ui.back;
            leftFront.classList.add('joystick-front');
            leftBack.classList.add('joystick-back');
            leftFront.innerHTML = 'move';

            rightManager = nipplejs.create(rightOptions);
            rightManager.on('move' as nipplejs.JoystickManagerEventTypes, (e, data) => {
                loadVideos();
                if (activeImageRef.current) {
                    onQuitInspect();
                }
                if (Math.sqrt(data.vector.x ** 2 + data.vector.y ** 2) > 0.5) {
                    joystickLookRef.current.x = data.vector.x;
                    joystickLookRef.current.y = data.vector.y;
                } else {
                    joystickLookRef.current.x = 0;
                    joystickLookRef.current.y = 0;
                }
            });
            rightManager.on('end' as nipplejs.JoystickManagerEventTypes, (e, data) => {
                joystickLookRef.current.x = 0;
                joystickLookRef.current.y = 0;
            });
            const rightFront = rightManager.get(undefined).ui.front;
            const rightBack = rightManager.get(undefined).ui.back;
            rightFront.classList.add('joystick-front');
            rightBack.classList.add('joystick-back');
            rightFront.innerHTML = 'look';
        }

        return () => {
            rightManager && rightManager.destroy();
            leftManager && leftManager.destroy();
        };
    }, [gameState]);

    const bottomRightContent = (
        <>
            {!isMobile && gameState === 1 && !menuVisible && <button className="game-button emote-button" onClick={() => { hasEmotedRef.current = true; document.getElementById('renderCanvas').focus(); }}></button>}
            {gameState === 1 && !menuVisible && <button className={`game-button inspect-button ${isMobile ? 'top-button-joystick' : 'top-button'} ${inspectButtonVisible ? '' : 'game-button-hidden'}`} onClick={() => onInspect(null)}></button>}
            {gameState === 1 && !menuVisible && <button className={`game-button move-button ${isMobile ? 'upper-top-button-joystick' : 'upper-top-button'} ${(moveButtonVisible && ownsGallery) ? '' : 'game-button-hidden'}`} onClick={onMove}></button>}
            {gameState === 1 && !menuVisible && <button className={`game-button swap-button ${isMobile ? 'swap-button-joystick' : ''} ${(moveButtonVisible && ownsGallery) ? '' : 'game-button-hidden'}`} onClick={onSwap}></button>}
        </>
    );

    const movingContent = (
        <>
            {gameState === 1 && !menuVisible && <button className="game-button cancel-button center-button-left" onClick={onCancelMove}></button>}
            {gameState === 1 && !menuVisible && <button className={`game-button confirm-button center-button-right`} onClick={onConfirmMove}></button>}
        </>
    );

    return (
        <>
            <canvas id="renderCanvas" className="no-select" tabIndex={1}></canvas>
            {gameState === 1 && !headerVisible && !menuVisible && (
                <button className={`game-button mute-button ${muted ? 'mute-button-muted' : ''}`} onClick={() => {
                    if (muted) {
                        setMuted(false);
                        audio.play();
                    } else {
                        setMuted(true);
                        audio.pause();
                    }
                    document.getElementById('renderCanvas').focus();
                }}></button>
            )}
            {gameState === 1 && !headerVisible && !menuVisible && chatPreview && !IS_MOBILE_APP && (
                <div className="chat-preview" id="chat-preview" onClick={openMenu} style={chatPreview.name ? {} : { alignItems: 'center' }}>
                    <div className="chat-preview-message"><b>{chatPreview.name || chatPreview.message}</b></div>
                    {chatPreview.name && <div className="chat-preview-message" >{chatPreview.message}</div>}
                </div>
            )}
            {gameState === 1 && !headerVisible && !menuVisible && displayWeb3Address && (
                <div className="new-home-extra-small-button current-address"><span className="current-address-text">{displayWeb3Address}</span></div>
            )}
            {gameState === 1 && !headerVisible && !menuVisible && (
                <>
                    <button className="game-button open-menu-button" onClick={openMenu}></button>
                    {unreadChats && <div className="notification-badge" onClick={openMenu} />}
                </>
            )}
            {addressesString === 'test' && gameState === 1 && !menuVisible && !headerVisible && (
                <div className="space-input">
                    <div>
                        <input
                            type="file"
                            id="space"
                            name="space"
                            accept=".obj"
                            ref={initialSpaceInputRef}
                        />
                        <button onClick={() => uploadSpace(initialSpaceInputRef, 0)}>upload</button>
                    </div>
                    {/* <div>
                        <input
                            type="file"
                            id="space"
                            name="space"
                            accept=".obj"
                            ref={intermediateSpaceInputRef}
                        />
                        <button onClick={() => uploadSpace(intermediateSpaceInputRef, 1)}>upload</button>
                    </div>
                    <div>
                        <input
                            type="file"
                            id="space"
                            name="space"
                            accept=".obj"
                            ref={finalSpaceInputRef}
                        />
                        <button onClick={() => uploadSpace(finalSpaceInputRef, 2)}>upload</button>
                    </div> */}
                </div>
            )}
            {moving ? movingContent : bottomRightContent}
            <div className="joystick left-joystick" id="leftJoystick" style={(menuVisible || !(gameState === 1 && isMobile)) ? { visibility: 'hidden' } : {}}></div>
            <div className="joystick right-joystick" id="rightJoystick" style={(menuVisible || !(gameState === 1 && isMobile)) ? { visibility: 'hidden' } : {}}></div>
            {!DEBUG && (<Menu resolvedAddress={resolvedAddress} visible={menuVisible} chat={chat} sendChat={newChat => { chatMessageRef.current = newChat }} people={people} clickPerson={person => {
                if (!playersRef.current[person]) { return; }
                playersRef.current[clientIdRef.current].position = playersRef.current[person].position.clone();
                playersRef.current[clientIdRef.current].position.y += 3 + (Math.random() * 8);
                document.getElementById('renderCanvas').focus();
            }} art={art} clickArt={art => {
                playersRef.current[clientIdRef.current].position = new BABYLON.Vector3(meshNamesToCanvasData[art].x, meshNamesToCanvasData[art].y, meshNamesToCanvasData[art].z);
                playersRef.current[clientIdRef.current].position.z -= 3;
                playersRef.current[clientIdRef.current].position.x += 4 - (Math.random() * 8);
                document.getElementById('renderCanvas').focus();
            }} onClose={() => setMenuVisible(false)} />)}
            <SwapModal isOpen={swapModalOpen} onClose={() => setSwapModalOpen(false)} onSelect={nft => {
                onSwapSelect(nft);
                setSwapModalOpen(false);
            }} />
            {/* {gameState === 1 && <button className={`game-button top-button close-button ${headerVisible ? '' : 'game-button-hidden'}`} onClick={onQuitInspect}></button>} */}
        </>
    );
}

export default BabylonContainer;
