import './Modal.css';
import './InputModal.css';
import { MutableRefObject } from 'react';

type ModalProps = {
    title: string,
    placeholder: string,
    value: string,
    onChange: React.ChangeEventHandler<HTMLInputElement>,
    leftButtonTitle: string,
    leftButtonOnClick: () => void,
    rightButtonTitle: string,
    rightButtonOnClick: () => void,
    isOpen: boolean,
    inputRef: MutableRefObject<any>
};

function InputModal(props: ModalProps) {
    const { title, placeholder, value, onChange, leftButtonTitle, leftButtonOnClick, rightButtonTitle, rightButtonOnClick, isOpen, inputRef } = props;

    return (
        <div className="modal-container" style={isOpen ? {} : { visibility: 'hidden' }}>
            <div className="modal-body">
                <div className="modal-content">
                    <span className="modal-title"><b>{title}</b></span>
                    <input ref={inputRef} autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" type="text" className="big-text-input modal-input" onChange={onChange} value={value} placeholder={placeholder} />
                </div>
                <div className="modal-button-row">
                    <button className="modal-button" onClick={leftButtonOnClick}>{leftButtonTitle}</button>
                    <button className="modal-button" onClick={rightButtonOnClick}>{rightButtonTitle}</button>
                </div>
            </div>
        </div>
    );
}

export default InputModal;
