
import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Helmet } from 'react-helmet';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import Web3Modal from "web3modal";
import Web3 from 'web3';
import WalletConnectProvider from "@walletconnect/web3-provider";
import './App.css';
import MobileHome from './MobileHome';
import Redemption from './Redemption';
import Gallery from './Gallery';
import About from './About';
import HyalikoAvatars from './HyalikoAvatars';
import HyalikoAvatarView from './HyalikoAvatarView';
import HyalikoAvatarTraitsView from './HyalikoAvatarTraitsView';
import ViewAvatar from './ViewAvatar';
import HyalikoAvatarAdmin from './HyalikoAvatarAdmin';
import MintHyalikoAvatarIntro from './MintHyalikoAvatarIntro';
import MintSimple from './MintSimple';
import SpaceFactory from './SpaceFactory';
import SpaceFactoryIntro from './SpaceFactoryIntro';
import SpaceFactoryCustomize from './SpaceFactoryCustomize';
import SpaceFactoryFinalize from './SpaceFactoryFinalize';
import { Onboarding, OnboardingGalleries, OnboardingNotifications } from './Onboarding';
import ViewSpace from './ViewSpace';
import { ArtContext, ChatContext, CurateContext, PeopleContext } from './GalleryContext';
import { Web3AddressContext, Web3ModalContext, AuthenticationModalContext } from './Web3Context';
import { ErrorModalContext } from './AppContext';
import SpaceFactorySelect from './SpaceFactorySelect';
import Privacy from './Privacy';
import NewHome from './NewHome';
import Marketplace from './Marketplace';
import { REVERSE_ENS_URL } from './constants';
import Modal from './Modal';
import { authenticate, checkAuthentication } from './auth';
import RandomHyalikoAvatars from './RandomHyalikoAvatars';

const MINT_ROUTES = [{ route: '', Component: SpaceFactory }, { route: 'intro', Component: SpaceFactoryIntro }, { route: 'customize', Component: SpaceFactoryCustomize }, { route: 'select-space', Component: SpaceFactorySelect }, { route: 'finalize', Component: SpaceFactoryFinalize }];
const ONBOARDING_ROUTES = [{ route: '', Component: Onboarding }, { route: 'galleries', Component: OnboardingGalleries }, { route: 'notifications', Component: OnboardingNotifications }];

function App() {
  const [people, setPeople] = useState([]);
  const [chat, setChat] = useState([]);
  const [art, setArt] = useState([]);
  const [curate, setCurate] = useState({ nfts: {}, collaborators: [], spaces: [] });
  const [web3Address, setWeb3Address] = useState(null);
  const [web3ENS, setWeb3ENS] = useState(null);
  const [web3Modal, setWeb3Modal] = useState(null);
  const [web3, setWeb3] = useState(null);
  const [addressToAuthenticate, setAddressToAuthenticate] = useState(null);
  const [authenticationModalOpen, setAuthenticationModalOpen] = useState(false);
  const [authenticationModalSuccessCallback, setAuthenticationModalSuccessCallback] = useState(null);
  const [authenticationModalFailureCallback, setAuthenticationModalFailureCallback] = useState(null);

  const errorModalStateDefault = {
    isOpen: false,
    title: '',
    text: '',
    leftButtonTitle: '',
    leftButtonOnClick: () => { },
    rightButtonTitle: '',
    rightButtonOnClick: () => { }
  };
  const [errorModalState, setErrorModalState] = useState(errorModalStateDefault);
  const openErrorModal = (title: string, text: string, leftButtonTitle: string, leftButtonOnClick: () => void, rightButtonTitle: string, rightButtonOnClick: () => void) => {
    setErrorModalState({
      isOpen: true,
      title,
      text,
      leftButtonTitle,
      leftButtonOnClick,
      rightButtonTitle,
      rightButtonOnClick
    });
  };
  const closeErrorModal = () => setErrorModalState(errorModalStateDefault);

  const authenticateWithModal = (address: string, successCallback: () => any, failureCallback: () => any) => {
    const validCookie = checkAuthentication(address, web3, web3Address);
    // If we have a valid cookie, no action needed
    if (validCookie) {
      successCallback();
    } else {
      setAddressToAuthenticate(address);
      // These need to be set as functions that return functions for completely unknown reasons to me
      // https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react
      setAuthenticationModalSuccessCallback(() => successCallback);
      setAuthenticationModalFailureCallback(() => failureCallback);
      setAuthenticationModalOpen(true);
    }
  };

  const resetAuthenicationModalState = () => {
    setAddressToAuthenticate(null);
    setAuthenticationModalSuccessCallback(null);
    setAuthenticationModalFailureCallback(null);
    setAuthenticationModalOpen(false);
  };

  const getENSFromAddress = (address: string) => {
    return fetch(`${REVERSE_ENS_URL}?address=${address}`)
      .then(res => res.json())
      .then(reverseJson => {
        return reverseJson && reverseJson.name;
      });
  }

  const initializeWeb3 = async (provider: any) => {
    // Subscribe to accounts change
    provider.on("accountsChanged", (accounts: string[]) => {
      setWeb3Address(accounts[0]);
      getENSFromAddress(accounts[0]).then(name => setWeb3ENS(name));
    });

    // Subscribe to chainId change
    provider.on("chainChanged", (chainId: number) => {
    });

    // Subscribe to session disconnection
    provider.on("disconnect", (code: number, reason: string) => {
      setWeb3Address(null);
      setWeb3ENS(null);
    });
  }

  const connectWeb3 = async () => {
    if (web3Modal) {
      const provider = await web3Modal.connect();
      const web3 = new Web3(provider);
      setWeb3(web3);
      initializeWeb3(provider);
      const address = provider.selectedAddress || provider.accounts[0];
      setWeb3Address(address);
      getENSFromAddress(address).then(name => setWeb3ENS(name));

      return { web3, web3Address: address };
    }
  }

  useEffect(() => {
    setWeb3Modal(new Web3Modal({
      network: "mainnet",
      cacheProvider: true,
      providerOptions: {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            infuraId: '9db311cc29ec45c9a263a74778fe0676'
          }
        }
      },
      theme: {
        background: "rgb(255, 133, 216)",
        main: "rgb(255, 255, 255)",
        secondary: "rgb(255, 255, 255)",
        border: "rgba(255, 255, 255)",
        hover: "rgba(255, 157, 223, 1)"
      }
    }));

    // Set address based on web3 when window loads and metamask is injected
    window.onload = () => {
      if (window.ethereum || window.web3) {
        const provider = window.ethereum || window.web3.currentProvider;
        if (provider) {
          initializeWeb3(provider);
          const address = provider.selectedAddress || (provider.accounts ? provider.accounts[0] : null);
          if (address) {
            setWeb3Address(address);
            getENSFromAddress(address).then(name => setWeb3ENS(name));
          }
          const web3 = new Web3(provider);
          setWeb3(web3);
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let address = null;
  let splitAddress = window.location.href.split('/');
  if (splitAddress.length === 4) {
    address = splitAddress[3];
  }

  return (
    <ErrorModalContext.Provider value={{ openErrorModal, closeErrorModal }}>
      <Web3AddressContext.Provider value={{ web3Address, setWeb3Address, web3ENS, setWeb3ENS }}>
        <Web3ModalContext.Provider value={{ web3, setWeb3, web3Modal, setWeb3Modal, connectWeb3 }}>
          <AuthenticationModalContext.Provider value={{ authenticateWithModal, addressToAuthenticate, setAddressToAuthenticate, authenticationModalOpen, setAuthenticationModalOpen, authenticationModalSuccessCallback, setAuthenticationModalSuccessCallback, authenticationModalFailureCallback, setAuthenticationModalFailureCallback }}>
            <ChatContext.Provider value={{ chat, setChat }}>
              <PeopleContext.Provider value={{ people, setPeople }}>
                <ArtContext.Provider value={{ art, setArt }}>
                  <CurateContext.Provider value={{ curate, setCurate }}>
                    <Router>
                      <Helmet>
                        <title>hyaliko - the social spatial nft gallery</title>
                        <meta name="description" content="hyaliko - the social spatial nft gallery. view pre-curated galleries of any ethereum address in the world of hyaliko." />
                        <meta name="og:title" content="hyaliko - the social spatial nft gallery" />
                        <meta name="og:description" content="hyaliko - the social spatial nft gallery. view pre-curated galleries of any ethereum address in the world of hyaliko." />
                        <meta name="og:image" content="https://www.hyaliko.com/og-image.png" />
                      </Helmet>
                      <Switch>
                        <Route path="/mobile" exact>
                          <MobileHome />
                        </Route>
                        <Route path="/redemption" exact>
                          <Redemption />
                        </Route>
                        <Route path="/mintSpace">
                          <Helmet>
                            <title>the hyaliko space factory - design and forge your space</title>
                            <meta name="description" content="the hyaliko space factory. design and forge your own hyaliko space to transform your gallery." />
                            <meta name="og:title" content="the hyaliko space factory" />
                            <meta name="og:description" content="the hyaliko space factory. design and forge your own hyaliko space to transform your gallery." />
                            <meta name="og:image" content="https://www.hyaliko.com/og-image.png" />
                          </Helmet>
                          <audio id="soundtrack" src="/sounds/hyaliko.mp3" loop></audio>
                          <audio id="sound1" src="/sounds/01.wav"></audio>
                          <audio id="sound2" src="/sounds/02.wav"></audio>
                          <audio id="sound3" src="/sounds/03.wav"></audio>
                          <audio id="sound4" src="/sounds/04.wav"></audio>
                          <audio id="sound5" src="/sounds/05.wav"></audio>
                          <audio id="sound6" src="/sounds/06.wav"></audio>
                          <audio id="sound7" src="/sounds/07.wav"></audio>
                          <audio id="sound8" src="/sounds/08.wav"></audio>
                          {MINT_ROUTES.map(({ route, Component }) => (
                            <Route key={route} exact path={`/mint/${route}`}>
                              {({ match }) => (
                                <CSSTransition
                                  in={match != null}
                                  timeout={1000}
                                  classNames="page"
                                  unmountOnExit
                                >
                                  <div className="page">
                                    <Component />
                                  </div>
                                </CSSTransition>
                              )}
                            </Route>
                          ))}
                        </Route>
                        <Route exact path="/about">
                          <Helmet>
                            <title>hyaliko - about</title>
                            <meta name="description" content="hyaliko - the social spatial nft gallery. view pre-curated galleries of any ethereum address in the world of hyaliko." />
                            <meta name="og:title" content="hyaliko - about" />
                            <meta name="og:description" content="hyaliko - the social spatial nft gallery. view pre-curated galleries of any ethereum address in the world of hyaliko." />
                            <meta name="og:image" content="https://www.hyaliko.com/og-image.png" />
                          </Helmet>
                          <About />
                        </Route>
                        <Route exact path="/privacy">
                          <Privacy />
                        </Route>
                        <Route path="/onboarding">
                          {ONBOARDING_ROUTES.map(({ route, Component }) => (
                            <Route key={route} exact path={`/onboarding/${route}`}>
                              {({ match }) => (
                                <CSSTransition
                                  in={match != null}
                                  timeout={1000}
                                  classNames="page"
                                  unmountOnExit
                                >
                                  <div className="page">
                                    <Component />
                                  </div>
                                </CSSTransition>
                              )}
                            </Route>
                          ))}
                        </Route>
                        <Route exact path="/marketplace">
                          <Marketplace />
                        </Route>
                        <Route exact path="/hyalikoAvatars">
                          <HyalikoAvatars />
                        </Route>
                        <Route exact path="/randomHyalikoAvatars">
                          <RandomHyalikoAvatars />
                        </Route>
                        <Route exact path="/hyalikoAvatarView/:body/:face/:color/:size/:opacity/:animation">
                          <HyalikoAvatarView />
                        </Route>
                        <Route exact path="/hyalikoAvatarView">
                          <HyalikoAvatarView />
                        </Route>
                        <Route exact path="/hyalikoAvatarTraitsView/:body/:face/:color/:size/:opacity/:animation">
                          <HyalikoAvatarTraitsView />
                        </Route>
                        <Route exact path="/viewAvatar/:tokenId/:body/:face/:color/:size/:opacity/:animation">
                          <ViewAvatar />
                        </Route>
                        <Route exact path="/hyalikoAvatarAdmin">
                          <HyalikoAvatarAdmin />
                        </Route>
                        <Route exact path="/mint">
                          <Helmet>
                            <title>the hyaliko particle forge - mint your hyaliko</title>
                            <meta name="description" content="the hyaliko particle forge. mint your hyaliko." />
                            <meta name="og:title" content="the hyaliko particle forge" />
                            <meta name="og:description" content="the hyaliko particle forge. mint your hyaliko." />
                            <meta name="og:image" content="https://www.hyaliko.com/og-image.png" />
                          </Helmet>
                          <MintHyalikoAvatarIntro />
                        </Route>
                        <Route exact path="/mint-simple">
                          <Helmet>
                            <title>the hyaliko particle forge - mint your hyaliko</title>
                            <meta name="description" content="the hyaliko particle forge. mint your hyaliko." />
                            <meta name="og:title" content="the hyaliko particle forge" />
                            <meta name="og:description" content="the hyaliko particle forge. mint your hyaliko." />
                            <meta name="og:image" content="https://www.hyaliko.com/og-image.png" />
                          </Helmet>
                          <MintSimple/>
                        </Route>
                        <Route exact path="/:addresses">
                          <Helmet>
                            <title>{`hyaliko - ${address}'s hyaliko gallery`}</title>
                            <meta name="description" content={`explore ${address}'s hyaliko space`} />
                            <meta name="og:title" content={`${address}'s hyaliko gallery`} />
                            <meta name="og:description" content={`explore ${address}'s hyaliko space`} />
                            <meta name="og:image" content="https://www.hyaliko.com/og-image.png" />
                          </Helmet>
                          <Gallery />
                        </Route>
                        <Route exact path="/view/:space/:terrain/:background/:particleShape/:particleColor">
                          <ViewSpace />
                        </Route>
                        <Route path="/">
                          <Helmet>
                            <title>hyaliko - the social spatial nft gallery</title>
                            <meta name="description" content="hyaliko - the social spatial nft gallery. view pre-curated galleries of any ethereum address in the world of hyaliko." />
                            <meta name="og:title" content="hyaliko - the social spatial nft gallery" />
                            <meta name="og:description" content="hyaliko - the social spatial nft gallery. view pre-curated galleries of any ethereum address in the world of hyaliko." />
                            <meta name="og:image" content="https://www.hyaliko.com/og-image.png" />
                          </Helmet>
                          <NewHome />
                        </Route>
                      </Switch>
                    </Router>
                    <Modal isOpen={authenticationModalOpen} title="save changes" text=" in order to save your changes, we just need you to prove it’s really you by signing a message with your wallet" leftButtonTitle="open wallet" leftButtonOnClick={async () => {
                      const authStatus = await authenticate(web3, addressToAuthenticate);
                      if (authStatus && authenticationModalSuccessCallback) {
                        authenticationModalSuccessCallback();
                      } else if (!authStatus && authenticationModalFailureCallback) {
                        authenticationModalFailureCallback();
                      }
                      resetAuthenicationModalState();
                    }} rightButtonTitle="close" rightButtonOnClick={() => setAuthenticationModalOpen(false)} />
                    <Modal isOpen={errorModalState.isOpen} title={errorModalState.title} text={errorModalState.text} leftButtonTitle={errorModalState.leftButtonTitle} leftButtonOnClick={errorModalState.leftButtonOnClick} rightButtonTitle={errorModalState.rightButtonTitle} rightButtonOnClick={errorModalState.rightButtonOnClick} />
                  </CurateContext.Provider>
                </ArtContext.Provider>
              </PeopleContext.Provider>
            </ChatContext.Provider>
          </AuthenticationModalContext.Provider>
        </Web3ModalContext.Provider>
      </Web3AddressContext.Provider>
    </ErrorModalContext.Provider>
  );
}

export default App;
