import React, { useContext, useEffect, useRef, useState } from 'react';
import cloneDeep from 'lodash.clonedeep';
import { ErrorModalContext } from './AppContext';
import Curate from './Curate';
import { CurateContext } from './GalleryContext';
import './Menu.css';
import { Web3AddressContext } from './Web3Context';

// const IS_MOBILE_APP = window.navigator.userAgent.includes('HyalikoApp');
const IS_MOBILE_APP = false;

type MenuProps = {
    resolvedAddress: string,
    visible: boolean,
    onClose: any,
    chat: any[],
    sendChat: (chat: string) => void,
    people: any[],
    clickPerson: (id: string) => void,
    art: any[],
    clickArt: (meshName: string) => void
};

function renderChat(chats: any[], chatBox: string, setChatBox: (value: string) => void, sendChat: (value: string) => void, clickPerson: any, chatScrollRef: any, chatInputRef: any, onClose: () => void) {
    const submitChat = () => { chatBox && sendChat(chatBox); setChatBox(''); chatInputRef.current && chatInputRef.current.focus() };
    const onKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            submitChat();
        }
    }
    return (
        <div className="menu-body chat-body">
            <div className="chat-messages" ref={chatScrollRef}>
                {chats.map(chat => (
                    <div className={`chat-message ${chat.name === 'me' ? 'chat-message-me' : ''}`} key={`${chat.message}:${chat.timestamp}`}>
                        <div className="chat-message-header">
                            {chat.name === 'me' ? <div className="chat-message-name">{chat.name}</div> : <button className="chat-message-name" onClick={() => clickPerson(chat.id)}>{chat.name}</button>}
                            <span>{chat.formattedTimestamp}</span>
                        </div>
                        <div className="chat-message-body">
                            {chat.message}
                        </div>
                    </div>
                ))}
            </div>
            <div className="chat-control">
                <input onChange={e => setChatBox(e.target.value)} onKeyPress={onKeyPress} value={chatBox} type="text" autoCapitalize="off" className="text-input chat-input" ref={chatInputRef}></input>
                <button onClick={submitChat} disabled={chatBox.length < 1} className="big-button menu-button">send</button>
            </div>
        </div>

    );
}

function renderPeople(people: any[], clickPerson: (id: string) => void) {
    return (
        <div className="menu-body people-body">
            {people.map(({ id, name }) => <button className="menu-item" key={id} onClick={() => clickPerson(id)}>{name}</button>)}
        </div>
    );
}

function renderArt(art: any[], clickArt: (meshName: string) => void) {
    return (
        <div className="menu-body art-body">
            {art.map(artPiece => (
                <div className={`curation-space-row curation-space-row-hover`} style={{ cursor: 'pointer' }} key={`${artPiece.contractAddress}:${artPiece.tokenId}`} onClick={() => clickArt(artPiece.meshName)}>
                    <div className={`curation-space-row-inner`}>
                        <img className={`curation-space-image`}
                            onError={({ currentTarget}) => { currentTarget.onerror = null; currentTarget.src="/icon.png" }}
                            src={artPiece.imageThumbnailUrl}
                            alt={artPiece.name}
                        />
                        <div className={`curation-space-text`}>{(artPiece.name || `${artPiece.collectionName}: ${artPiece.tokenId}`)}</div>
                    </div>
                </div>
            ))}
        </div>
    );
}

function Menu(props: MenuProps) {
    const { resolvedAddress, visible, chat, sendChat, people, clickPerson, art, clickArt } = props;
    const [tab, setTab] = useState(0);
    const [chatBox, setChatBox] = useState('');
    const [hasChanges, setHasChanges] = useState(false);
    const { openErrorModal, closeErrorModal } = useContext(ErrorModalContext);
    const { web3Address } = useContext(Web3AddressContext);
    const { curate } = useContext(CurateContext);
    const ownsGallery = web3Address && resolvedAddress && (web3Address.toLowerCase() === resolvedAddress.toLowerCase());

    // Curate state
    const [curateFormHasChanges, setCurateFormHasChanges] = useState(false);
    const [spacesFormHasChanges, setSpacesFormHasChanges] = useState(false);
    const curateFormDefault: { [id: string]: any } = {};
    const [curateForm, setCurateForm] = useState(curateFormDefault);
    const [spacesForm, setSpacesForm] = useState([]);

    // Use the curate list if you own the gallery
    useEffect(() => {
        // TODO if statement is for collaborators
        // if (ownsGallery) {
        setCurateForm(cloneDeep(curate.nfts));
        // }
        setSpacesForm(cloneDeep(curate.spaces));
    }, [ownsGallery, curate]);


    const closeMenu = () => {
        props.onClose();
        setHasChanges(false);
        setCurateFormHasChanges(false);
        setSpacesFormHasChanges(false);
        setCurateForm(cloneDeep(curate.nfts));
        setSpacesForm(cloneDeep(curate.spaces));
    }
    const onClose = () => {
        if (!hasChanges) {
            closeMenu();
        } else {
            openErrorModal('unsaved changes', 'you have unsaved changes! do you want to save before closing?', 'close anyway', () => { closeErrorModal(); closeMenu(); }, 'cancel', closeErrorModal);
        }
    }

    const chatScrollRef = useRef(null);
    const chatInputRef = useRef(null);

    useEffect(() => {
        if (chatScrollRef.current) {
            chatScrollRef.current.scrollTop = chatScrollRef.current.scrollHeight;
        }
    }, [visible, tab, chat.length]);

    useEffect(() => {
        if (visible) {
            setTimeout(() => {
                chatInputRef.current && chatInputRef.current.focus();
            }, 200);
        }
    }, [visible]);

    let menuBody = null;
    switch (tab) {
        case 0: {
            menuBody = renderChat(chat, chatBox, setChatBox, sendChat, clickPerson, chatScrollRef, chatInputRef, onClose);
            break;
        }
        case 1: {
            menuBody = renderPeople(people, clickPerson);
            break;
        }
        case 2: {
            menuBody = renderArt(art, clickArt);
            break;
        }
        case 3: {
            menuBody = (
                <Curate
                    resolvedAddress={resolvedAddress}
                    onChange={(value: boolean) => setHasChanges(value)}
                    curateForm={curateForm}
                    setCurateForm={setCurateForm}
                    spacesForm={spacesForm}
                    setSpacesForm={setSpacesForm}
                    curateFormHasChanges={curateFormHasChanges}
                    setCurateFormHasChanges={setCurateFormHasChanges}
                    spacesFormHasChanges={spacesFormHasChanges}
                    setSpacesFormHasChanges={setSpacesFormHasChanges}
                />
            );
            break;
        }
        default: {
            break;
        }
    }

    return (
        <div className={`menu ${visible ? 'menu-visible' : ''}`} id="menu" onKeyDown={e => e.key === 'Escape' && onClose()}>
            <div className="menu-header">
                {!IS_MOBILE_APP && <button className={`menu-tab ${tab === 0 ? 'active-menu-tab' : ''}`} onClick={() => setTab(0)}>chat</button>}
                <button className={`menu-tab ${tab === 1 ? 'active-menu-tab' : ''}`} onClick={() => setTab(1)}>people {people.length > 1 ? `(${people.length})` : ''}</button>
                <button className={`menu-tab ${tab === 2 ? 'active-menu-tab' : ''}`} onClick={() => setTab(2)}>art</button>
                {!IS_MOBILE_APP && <button className={`menu-tab ${tab === 3 ? 'active-menu-tab' : ''}`} onClick={() => setTab(3)}>curate</button>}
                <button className="menu-tab" onClick={onClose}>close</button>
            </div>
            {visible && menuBody}
        </div>
    );
}

export default Menu;
