// import React, { useContext, useEffect, useState } from 'react';
// import { HYALIKO_DESCRIPTOR_ADDRESS, HYALIKO_DESCRIPTOR_ABI, meshBytes, bodyLayoutBytes, faceBytes, avatarRGBs } from './constants';
// import './SpaceFactoryFinalize.css';
// import { Web3AddressContext, Web3ModalContext } from './Web3Context';

// function uploadMeshes1(web3Address: string, contract: any) {
//     return contract.methods.addManyMeshes(meshBytes.slice(0, Math.floor(meshBytes.length / 2))).send({
//         maxPriorityFeePerGas: null,
//         maxFeePerGas: null,
//         from: web3Address
//     });
// }

// function uploadMeshes2(web3Address: string, contract: any) {
//     return contract.methods.addManyMeshes(meshBytes.slice(Math.floor(meshBytes.length / 2))).send({
//         maxPriorityFeePerGas: null,
//         maxFeePerGas: null,
//         from: web3Address
//     });
// }

// function uploadBodyLayouts(web3Address: string, contract: any) {
//     return contract.methods.addManyBodies(bodyLayoutBytes).send({
//         maxPriorityFeePerGas: null,
//         maxFeePerGas: null,
//         from: web3Address
//     });
// }

// function uploadFaces(web3Address: string, contract: any) {
//     return contract.methods.addManyFaces(faceBytes).send({
//         maxPriorityFeePerGas: null,
//         maxFeePerGas: null,
//         from: web3Address
//     });
// }

// function uploadColors(web3Address: string, contract: any) {
//     return contract.methods.addManyColors(avatarRGBs).send({
//         maxPriorityFeePerGas: null,
//         maxFeePerGas: null,
//         from: web3Address
//     });
// }

// function lockParts(web3Address: string, contract: any) {
//     return contract.methods.lockParts().send({
//         maxPriorityFeePerGas: null,
//         maxFeePerGas: null,
//         from: web3Address
//     });
// }

function HyalikoAvatarAdmin() {
    // Web3
    // const { connectWeb3, web3 } = useContext(Web3ModalContext);
    // const { web3Address } = useContext(Web3AddressContext);

    // const [contract, setContract] = useState(null);

    // useEffect(() => {
    //     if (web3) {
    //         setContract(new web3.eth.Contract(HYALIKO_DESCRIPTOR_ABI, HYALIKO_DESCRIPTOR_ADDRESS));
    //     }
    // }, [web3, web3Address]);

    return (
        <div className="container sf-finalize-container">

            {/* <div className="sf-finalize-buttons">
                {!web3Address || !web3 ? (
                    <button className="button" onClick={connectWeb3}>connect wallet</button>
                ) : (
                    <>
                        <button className="button big-button sf-mint-button" onClick={async () => {
                            await uploadMeshes1(web3Address, contract);
                        }}>upload meshes 1</button>
                        <button className="button big-button sf-mint-button" onClick={async () => {
                            await uploadMeshes2(web3Address, contract);
                        }}>upload meshes 2</button>
                        <button className="button big-button sf-mint-button" onClick={async () => {
                            await uploadBodyLayouts(web3Address, contract);
                        }}>upload body layouts</button>
                        <button className="button big-button sf-mint-button" onClick={async () => {
                            await uploadFaces(web3Address, contract);
                        }}>upload faces</button>
                        <button className="button big-button sf-mint-button" onClick={async () => {
                            await uploadColors(web3Address, contract);
                        }}>upload colors</button>
                        <button className="button big-button sf-mint-button" onClick={async () => {
                            await lockParts(web3Address, contract);
                        }}>lock parts</button>
                    </>
                )}
            </div> */}
        </div>
    );
}

export default HyalikoAvatarAdmin;