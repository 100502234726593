import { useContext } from 'react';
import { CurateContext } from './GalleryContext';
import './SwapModal.css';

type ModalProps = {
    onSelect: (value: any) => void,
    isOpen: boolean,
    onClose: () => void
};

function SwapModal(props: ModalProps) {
    const { isOpen, onClose, onSelect } = props;
    const { curate } = useContext(CurateContext);

    return isOpen ? (
        <div className="swap-modal-container">
            <div className="swap-modal-inner-container">
                <div className="swap-modal-body">
                    <div className="swap-modal-content">
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 24, paddingRight: 24, boxSizing: 'border-box' }}>
                            <span className="swap-modal-text"><b>select a piece to display on this canvas</b></span>
                            <button className="button" onClick={onClose}>cancel</button>
                        </div>
                        <div className="swap-modal-list-container">
                            {curate && Object.keys(curate.nfts).map(curateId => {
                                const curateItem = curate.nfts[curateId];
                                return (
                                    <div className={`curation-space-row curation-space-row-hover`} key={curateId}>
                                        <div className={`curation-space-row-inner`} onClick={() => { onSelect(curateItem) }}>
                                            <img className={`curation-space-image`} src={curateItem.imageUrl} alt={curateItem.id}></img>
                                            <div className={`curation-space-text`}>{curateItem.name}</div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : null;
}

export default SwapModal;
