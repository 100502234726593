import * as BABYLON from "babylonjs";

// Import our Shader Config
import { AnimatedGifShaderConfiguration } from "./animatedGifTextureShader";

// Gifs external library to parse Gif datas
import { parseGIF, decompressFrames } from "gifuct-js";

/**
 * Typings related to our Gif library as it does not includ a d ts file.
 */
declare type GifFrame = {
    /**
     * Current Frame dimensions.
     */
    dims: {
        width: number,
        height: number,
        top: number,
        left: number,
    },
    /**
     * Current Frame content as RGBA.
     */
    patch: Uint8ClampedArray,
    /**
     * Current Frame visible time.
     */
    delay: number,
    /**
     * Current Frame associated texture.
     */
    texture?: BABYLON.InternalTexture;
    /**
     * Current Transform Matrix to handle the patch scale and translation.
     */
    worldMatrix?: Float32Array;
};

/**
 * This represents an animated Gif textures.
 * Yes... It is truly animating ;-)
 */
export class ReducedAnimatedGifTexture extends BABYLON.BaseTexture {
    private _onLoad: BABYLON.Nullable<() => void>

    private _frames: BABYLON.Nullable<GifFrame[]> = null;
    private _currentFrame: BABYLON.Nullable<GifFrame>;
    private _nextFrameIndex = 0;
    private _previousDate: number;

    private _patchEffectWrapper: BABYLON.EffectWrapper;
    private _patchEffectRenderer: BABYLON.EffectRenderer;
    private _renderLoopCallback: () => void;
    private _renderTarget: BABYLON.RenderTargetWrapper;

    /**
     * Instantiates an AnimatedGifTexture from the following parameters.
     *
     * @param url The location of the Gif
     * @param engine engine the texture will be used in
     * @param onLoad defines a callback to trigger once all ready.
     */
    constructor(url: string, engine: BABYLON.ThinEngine, onLoad: BABYLON.Nullable<() => void> = null) {
        super(engine);

        this.name = url;
        this._onLoad = onLoad;

        this._createInternalTexture();
        this._createRenderer();
        this._createRenderLoopCallback();
        this._loadGifTexture();
    }

    /**
     * Creates the internal texture used by the engine.
     */
    private _createInternalTexture(): void {
        this._texture = this._engine.createRawTexture(null, 1, 1, BABYLON.Constants.TEXTUREFORMAT_RGBA, false, false, BABYLON.Constants.TEXTURE_BILINEAR_SAMPLINGMODE, null, BABYLON.Constants.TEXTURETYPE_UNSIGNED_INT);

        // Do not be ready before the data has been loaded
        this._texture.isReady = false;

        // Setups compatibility with gl1
        this.wrapU = BABYLON.Constants.TEXTURE_CLAMP_ADDRESSMODE;
        this.wrapV = BABYLON.Constants.TEXTURE_CLAMP_ADDRESSMODE;
        this.wrapR = BABYLON.Constants.TEXTURE_CLAMP_ADDRESSMODE;
        this.anisotropicFilteringLevel = 1;
    }

    /**
     * Create the renderer resources used to draw the Gif patches in the texture.
     */
    private _createRenderer(): void {
        // Creates a wrapper around our custom shader
        this._patchEffectWrapper = new BABYLON.EffectWrapper({
            ...AnimatedGifShaderConfiguration,
            engine: this._engine,
        });

        // Creates a dedicated fullscreen renderer for the frame blit
        this._patchEffectRenderer = new BABYLON.EffectRenderer(this._engine, {
            positions: [1, 1, 0, 1, 0, 0, 1, 0]
        });
    }

    /**
     * Creates the current render loop callback.
     */
    private _createRenderLoopCallback(): void {
        this._renderLoopCallback = () => {
            this._renderFrame();
        };
    }

    /**
     * Starts loading the Gif data.
     */
    private _loadGifTexture(): void {
        // Defines what happens after we read the data from the url
        const callback = (buffer: string | ArrayBuffer, responseUrl: string) => {
            this._parseGifData(buffer as ArrayBuffer);
            this._createGifResources();

            // Added by Collin McKinney. Limit to 10 frames!
            const numberOfFrames = 10;
            if (this._frames.length > numberOfFrames) {
                this._frames = this._frames.filter((frame, i) => {
                    return i % Math.floor((this._frames.length / numberOfFrames)) === 0;
                });
            }
            // Start Rendering the sequence of frames
            this._engine.runRenderLoop(this._renderLoopCallback);
        };

        // Load the array buffer from the Gif file
        this._engine._loadFile(this.name, callback, undefined, undefined, true);
    }

    /**
     * Parses the Gif data and creates the associated frames.
     * @param buffer Defines the buffer containing the data
     */
    private _parseGifData(buffer: ArrayBuffer): void {
        const gifData = parseGIF(buffer);
        this._frames = decompressFrames(gifData, true);
    }

    /**
     * Creates the GPU resources associated with the Gif file.
     * It will create the texture for each frame as well as our render target used
     * to hold the final Gif.
     */
    private _createGifResources(): void {
        let previousBuffer = null;
        let baseDimensions = null;
        let baseWorldMatrix = null;
        for (let frame of this._frames) {
            // Creates a dedicated texture for each frames
            // This only contains patched data for a portion of the image
            const framePatchBuffer = new Uint8Array(frame.patch.buffer);

            const { left, top, width } = frame.dims;
            if (previousBuffer) {
                for (let i = 0; i < framePatchBuffer.length; i += 4) {
                    const littlePixel = Math.floor(i / 4);
                    const littleY = Math.floor(littlePixel / width);
                    const littleX = littlePixel % width;
                    const bigPixelX = left + littleX;
                    const bigPixelY = top + littleY;
                    const bigI = ((bigPixelY * baseDimensions.width) + bigPixelX) * 4;
                    if (framePatchBuffer[i + 3] > 0) {
                        previousBuffer[bigI] = framePatchBuffer[i];
                        previousBuffer[bigI + 1] = framePatchBuffer[i + 1];
                        previousBuffer[bigI + 2] = framePatchBuffer[i + 2];
                        previousBuffer[bigI + 3] = framePatchBuffer[i + 3];
                    }
                }
                frame.texture = this._engine.createRawTexture(previousBuffer,
                    baseDimensions.width,
                    baseDimensions.height,
                    BABYLON.Constants.TEXTUREFORMAT_RGBA,
                    false,
                    true,
                    BABYLON.Constants.TEXTURE_NEAREST_SAMPLINGMODE,
                    null,
                    BABYLON.Constants.TEXTURETYPE_UNSIGNED_INT);
                frame.worldMatrix = baseWorldMatrix;
            } else {
                frame.texture = this._engine.createRawTexture(new Uint8Array(frame.patch.buffer),
                    frame.dims.width,
                    frame.dims.height,
                    BABYLON.Constants.TEXTUREFORMAT_RGBA,
                    false,
                    true,
                    BABYLON.Constants.TEXTURE_NEAREST_SAMPLINGMODE,
                    null,
                    BABYLON.Constants.TEXTURETYPE_UNSIGNED_INT);
                baseDimensions = frame.dims;
                baseWorldMatrix = frame.worldMatrix;
            }

            // Ensures webgl 1 compat
            this._engine.updateTextureWrappingMode(frame.texture, BABYLON.Constants.TEXTURE_CLAMP_ADDRESSMODE, BABYLON.Constants.TEXTURE_CLAMP_ADDRESSMODE);
            previousBuffer = (frame.texture._bufferView as Uint8Array).slice();
        }

        // Creates our main render target based on the Gif dimensions
        this._renderTarget = this._engine.createRenderTargetTexture(this._frames[0].dims, {
            format: BABYLON.Constants.TEXTUREFORMAT_RGBA,
            generateDepthBuffer: false,
            generateMipMaps: false,
            generateStencilBuffer: false,
            samplingMode: BABYLON.Constants.TEXTURE_BILINEAR_SAMPLINGMODE,
            type: BABYLON.Constants.TEXTURETYPE_UNSIGNED_BYTE
        });

        // Release the extra resources from the current internal texture
        this._engine._releaseTexture(this._texture);

        // Swap our internal texture by our new render target one
        this._renderTarget.texture._swapAndDie(this._texture);

        // And adapt its data
        this._engine.updateTextureWrappingMode(this._texture, BABYLON.Constants.TEXTURE_CLAMP_ADDRESSMODE, BABYLON.Constants.TEXTURE_CLAMP_ADDRESSMODE);
        this._texture.width = this._frames[0].dims.width;
        this._texture.height = this._frames[0].dims.height;
        this._texture.isReady = false;
    }

    /**
     * Render the current frame when all is ready.
     */
    private _renderFrame(): void {
        // Keep the current frame as long as specified in the Gif data
        // Edited by Collin McKinney. Slow framerate for performance.
        if (this._currentFrame && (BABYLON.PrecisionDate.Now - this._previousDate) < 200) {
            return;
        }

        // Replace the current frame
        this._currentFrame = this._frames[this._nextFrameIndex];

        // Patch the texture
        this._drawPatch();

        // Recall the current draw time for this frame.
        this._previousDate = BABYLON.PrecisionDate.Now;

        // Update the next frame index
        this._nextFrameIndex++;
        if (this._nextFrameIndex >= this._frames.length) {
            this._nextFrameIndex = 0;
        }
    }

    /**
     * Draw the patch texture on top of the previous one.
     */
    private _drawPatch(): void {
        // The texture is only ready when we are able to render
        if (!this._patchEffectWrapper.effect.isReady()) {
            return;
        }

        // Get the current frame
        const frame: GifFrame = this._currentFrame;

        // Record the old viewport
        const oldViewPort = this._engine.currentViewport;

        // We need to apply our special inputes to the effect when it renders
        this._patchEffectWrapper.onApplyObservable.addOnce(() => {
            this._patchEffectWrapper.effect._bindTexture("textureSampler", frame.texture);
        });

        // Render the current Gif frame on top of the previous one
        this._patchEffectRenderer.render(this._patchEffectWrapper, this._renderTarget);

        // Reset the old viewport
        this._engine.setViewport(oldViewPort);

        // We are now all ready to roll
        if (!this._texture.isReady) {
            this._texture.isReady = true;
            this._onLoad && this._onLoad();
        }
    }
    /**
     * Dispose the texture and release its associated resources.
     */
    public dispose(): void {
        // Stops the current Gif update loop
        this._engine.stopRenderLoop(this._renderLoopCallback);

        // Clear the render helpers
        this._patchEffectWrapper.dispose();
        this._patchEffectRenderer.dispose();

        // Clear the textures from the Gif
        for (let frame of this._frames) {
            frame.texture.dispose();
        }

        // Disposes the render target associated resources
        super.dispose();
    }
}

export class FullAnimatedGifTexture extends BABYLON.BaseTexture {
    private _onLoad: BABYLON.Nullable<() => void>

    private _frames: BABYLON.Nullable<GifFrame[]> = null;
    private _currentFrame: BABYLON.Nullable<GifFrame>;
    private _nextFrameIndex = 0;
    private _previousDate: number;

    private _patchEffectWrapper: BABYLON.EffectWrapper;
    private _patchEffectRenderer: BABYLON.EffectRenderer;
    private _renderLoopCallback: () => void;

    private _renderTarget: BABYLON.RenderTargetWrapper;

    private _isLoaded: boolean;

    /**
     * Instantiates an AnimatedGifTexture from the following parameters.
     *
     * @param url The location of the Gif
     * @param engine engine the texture will be used in
     * @param onLoad defines a callback to trigger once all ready.
     */
    constructor(url: string, engine: BABYLON.ThinEngine, onLoad: BABYLON.Nullable<() => void> = null) {
        super(engine);

        this.name = url;
        this._onLoad = onLoad;

        this._createInternalTexture();
        this._createRenderer();
        this._createRenderLoopCallback();
        this._loadGifTexture();
    }

    /**
     * Creates the internal texture used by the engine.
     */
    private _createInternalTexture(): void {
        this._texture = this._engine.createRawTexture(null, 1, 1, BABYLON.Constants.TEXTUREFORMAT_RGBA, false, false, BABYLON.Constants.TEXTURE_BILINEAR_SAMPLINGMODE, null, BABYLON.Constants.TEXTURETYPE_UNSIGNED_INT);

        // Do not be ready before the data has been loaded
        this._texture.isReady = false;

        // Setups compatibility with gl1
        this.wrapU = BABYLON.Constants.TEXTURE_CLAMP_ADDRESSMODE;
        this.wrapV = BABYLON.Constants.TEXTURE_CLAMP_ADDRESSMODE;
        this.wrapR = BABYLON.Constants.TEXTURE_CLAMP_ADDRESSMODE;
        this.anisotropicFilteringLevel = 1;
    }

    /**
     * Create the renderer resources used to draw the Gif patches in the texture.
     */
    private _createRenderer(): void {
        // Creates a wrapper around our custom shader
        this._patchEffectWrapper = new BABYLON.EffectWrapper({
            ...AnimatedGifShaderConfiguration,
            engine: this._engine,
        });

        // Creates a dedicated fullscreen renderer for the frame blit
        this._patchEffectRenderer = new BABYLON.EffectRenderer(this._engine, {
            positions: [1, 1, 0, 1, 0, 0, 1, 0]
        });
    }

    /**
     * Creates the current render loop callback.
     */
    private _createRenderLoopCallback(): void {
        this._renderLoopCallback = () => {
            this._renderFrame();
        };
    }

    /**
     * Starts loading the Gif data.
     */
    private _loadGifTexture(): void {
        // Defines what happens after we read the data from the url
        const callback = (buffer: string | ArrayBuffer, responseUrl: string) => {
            this._parseGifData(buffer as ArrayBuffer);
            try {
                this._createGifResources();

                // Start Rendering the sequence of frames
                this._engine.runRenderLoop(this._renderLoopCallback);
            } catch (e) {
                console.error(e);
            }
        };

        // Load the array buffer from the Gif file
        this._engine._loadFile(this.name, callback, undefined, undefined, true);
    }

    /**
     * Parses the Gif data and creates the associated frames.
     * @param buffer Defines the buffer containing the data
     */
    private _parseGifData(buffer: ArrayBuffer): void {
        const gifData = parseGIF(buffer);
        this._frames = decompressFrames(gifData, true);
    }

    /**
     * Creates the GPU resources associated with the Gif file.
     * It will create the texture for each frame as well as our render target used
     * to hold the final Gif.
     */
    private _createGifResources(): void {
        for (let frame of this._frames) {
            // Creates a dedicated texture for each frames
            // This only contains patched data for a portion of the image
            frame.texture = this._engine.createRawTexture(new Uint8Array(frame.patch.buffer),
                frame.dims.width, 
                frame.dims.height, 
                BABYLON.Constants.TEXTUREFORMAT_RGBA, 
                false,
                true,
                BABYLON.Constants.TEXTURE_NEAREST_SAMPLINGMODE, 
                null,
                BABYLON.Constants.TEXTURETYPE_UNSIGNED_INT);

            // As it only contains part of the image, we need to translate and scale
            // the rendering of the pacth to fit with the location data from the file
            const sx = frame.dims.width / this._frames[0].dims.width;
            const sy = frame.dims.height / this._frames[0].dims.height;
            const tx = frame.dims.left / this._frames[0].dims.width;
            // As we render from the bottom, the translation needs to be computed accordingly
            const ty = (this._frames[0].dims.height - (frame.dims.top + frame.dims.height)) / this._frames[0].dims.height;
            frame.worldMatrix = new Float32Array([
                sx, 0, tx,
                0, sy, ty,
                0,  0, 1,
            ]);

            // Ensures webgl 1 compat
            this._engine.updateTextureWrappingMode(frame.texture, BABYLON.Constants.TEXTURE_CLAMP_ADDRESSMODE, BABYLON.Constants.TEXTURE_CLAMP_ADDRESSMODE);
        }

        // Creates our main render target based on the Gif dimensions
        this._renderTarget = this._engine.createRenderTargetTexture(this._frames[0].dims, { 
            format: BABYLON.Constants.TEXTUREFORMAT_RGBA,
            generateDepthBuffer: false,
            generateMipMaps: false,
            generateStencilBuffer: false,
            samplingMode: BABYLON.Constants.TEXTURE_BILINEAR_SAMPLINGMODE,
            type: BABYLON.Constants.TEXTURETYPE_UNSIGNED_BYTE
        });

        // Release the extra resources from the current internal texture
        this._engine._releaseTexture(this._texture);

        // Swap our internal texture by our new render target one
        this._renderTarget.texture._swapAndDie(this._texture);

        // And adapt its data
        this._engine.updateTextureWrappingMode(this._texture, BABYLON.Constants.TEXTURE_CLAMP_ADDRESSMODE, BABYLON.Constants.TEXTURE_CLAMP_ADDRESSMODE);
        this._texture.width = this._frames[0].dims.width;
        this._texture.height = this._frames[0].dims.height;
        this._texture.isReady = false;
    }

    /**
     * Render the current frame when all is ready.
     */
    private _renderFrame(): void {
        // Keep the current frame as long as specified in the Gif data
        if (this._currentFrame && (BABYLON.PrecisionDate.Now - this._previousDate) < this._currentFrame.delay) {
            return;
        }

        // Replace the current frame
        this._currentFrame = this._frames[this._nextFrameIndex];

        // Patch the texture
        this._drawPatch();

        // Recall the current draw time for this frame.
        this._previousDate = BABYLON.PrecisionDate.Now;

        // Update the next frame index
        this._nextFrameIndex++;
        if (this._nextFrameIndex >= this._frames.length) {
            this._nextFrameIndex = 0;
        }
    }

    /**
     * Draw the patch texture on top of the previous one.
     */
    private _drawPatch(): void {
        // The texture is only ready when we are able to render
        if (!this._patchEffectWrapper.effect.isReady()) {
            return;
        }

        // Get the current frame
        const frame: GifFrame = this._currentFrame;

        // Record the old viewport
        const oldViewPort = this._engine.currentViewport;

        // We need to apply our special inputes to the effect when it renders
        this._patchEffectWrapper.onApplyObservable.addOnce(() => {
            this._patchEffectWrapper.effect.setMatrix3x3("world", frame.worldMatrix);
            this._patchEffectWrapper.effect._bindTexture("textureSampler", frame.texture);
        });

        // Render the current Gif frame on top of the previous one
        this._patchEffectRenderer.render(this._patchEffectWrapper, this._renderTarget);

        // Reset the old viewport
        this._engine.setViewport(oldViewPort);

        // We are now all ready to roll
        if (!this._texture.isReady) {
            this._texture.isReady = true;
            this._onLoad && this._onLoad();
            this._isLoaded = true;
        }
    }
    /**
     * Dispose the texture and release its associated resources.
     */
    public dispose(): void {
        // Stops the current Gif update loop
        this._engine.stopRenderLoop(this._renderLoopCallback);
        this._engine.clearInternalTexturesCache();

        // Clear the render helpers
        this._patchEffectWrapper.dispose();
        this._patchEffectRenderer.dispose();

        // Clear the textures from the Gif
        if (this._frames && typeof this._frames[Symbol.iterator] === 'function') {
            for (let frame of this._frames) {
                frame.texture.dispose();
            }
        }

        // Disposes the render target associated resources
        super.dispose();
    }

    public isLoaded(): boolean {
        return this._isLoaded;
    }
}
