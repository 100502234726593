import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { BABYLON_COLORS, colors, LIGHT_PINK } from './Colors';
import { avatarColors, avatarHexes, avatarHexToBackground, AVATAR_ANIMATIONS, AVATAR_OFFSETS, faces, getAnimationIndexForSizeAndColor, PARTICLE_SHAPE_FUNCTIONS } from './constants';
import './ViewSpace.css';

const INTRO_CAMERA_EASING = new BABYLON.QuinticEase();
INTRO_CAMERA_EASING.setEasingMode(BABYLON.QuinticEase.EASINGMODE_EASEINOUT);

const AVATAR_FLY_IN_EASE = new BABYLON.SineEase();
AVATAR_FLY_IN_EASE.setEasingMode(BABYLON.SineEase.EASINGMODE_EASEINOUT);

const HOVER_EASING = new BABYLON.QuinticEase();
HOVER_EASING.setEasingMode(BABYLON.QuinticEase.EASINGMODE_EASEINOUT);

const PBRMaterialToStandardMaterial = (pbrMaterial: BABYLON.PBRMaterial, scene: BABYLON.Scene) => {
    const newMaterial = new BABYLON.StandardMaterial(pbrMaterial.name, scene);
    newMaterial.diffuseColor = pbrMaterial.albedoColor;
    newMaterial.diffuseTexture = pbrMaterial.albedoTexture;
    newMaterial.emissiveColor = pbrMaterial.emissiveColor;
    newMaterial.useAlphaFromDiffuseTexture = true;
    newMaterial.alpha = pbrMaterial.alpha;
    newMaterial.alphaMode = pbrMaterial.alphaMode;
    pbrMaterial.dispose();
    return newMaterial;
}

type ViewAvatarParams = {
    body: string,
    face: string,
    color: string,
    size: string,
    opacity: string,
    animation: string
}

function ViewAvatar() {
    // Refs
    const sceneRef: React.MutableRefObject<BABYLON.Scene> = useRef(null);
    const currentAvatarRef: React.MutableRefObject<BABYLON.AbstractMesh> = useRef(null);

    const { body: rawBody, face: rawFace, color: rawColor, size: rawSize, opacity: rawOpacity, animation: rawAnimation } = useParams() as ViewAvatarParams;
    const body = rawBody ? parseInt(rawBody) : null;
    const face = rawFace ? parseInt(rawFace) : null;
    const color = rawColor ? parseInt(rawColor) : null;
    const size = rawSize ? parseInt(rawSize) : null;
    const opacity = rawOpacity ? parseInt(rawOpacity) : null;
    const animation = rawAnimation ? parseInt(rawAnimation) : null;

    // Mount Babylon
    useEffect(() => {
        // Get the canvas DOM element
        const canvas: HTMLCanvasElement = document.getElementById('spaceViewCanvas') as HTMLCanvasElement;
        // Load the 3D engine
        const engine = new BABYLON.Engine(canvas, false, {
            preserveDrawingBuffer: true, stencil: true, xrCompatible: false
        }, false);

        // Create a basic BJS Scene object
        const scene = new BABYLON.Scene(engine);
        // scene.debugLayer.show();
        sceneRef.current = scene;

        scene.fogEnabled = true;
        scene.fogMode = BABYLON.Scene.FOGMODE_EXP;
        scene.fogColor = BABYLON.Color3.FromHexString(colors.LIGHT_PINK);
        scene.fogDensity = 0.0025;

        var camera = new BABYLON.ArcRotateCamera("Camera", Math.PI / 2, Math.PI / 2, 4, BABYLON.Vector3.Zero(), scene);
        camera.attachControl(canvas, true);
        // new AsciiArtPostProcess("AsciiArt", camera, {
        //     font: "32px Monospace",
        //     characterSet: "かけくこなねにのはひへほられりろいえおうふをわさしせそたちてと"
        // });
        new BABYLON.PassPostProcess("resolution", 0.4, camera);

        const light = new BABYLON.DirectionalLight('light1', new BABYLON.Vector3(-1, -1, 0), scene);
        light.intensity = 0.5;
        scene.ambientColor = BABYLON_COLORS.PINK;
        scene.clearColor = LIGHT_PINK;
        scene.fogEnabled = true;
        scene.fogMode = BABYLON.Scene.FOGMODE_EXP;
        scene.fogColor = BABYLON.Color3.White();
        scene.fogDensity = 0.001;

        // Ambient particle system
        const particleLimit = 2000;
        const emitRateMultiplier = 80;
        const particles = new BABYLON.ParticleSystem('particles', particleLimit, scene);
        particles.emitRate *= emitRateMultiplier;
        particles.maxScaleX = 1;
        particles.maxScaleY = 1;
        particles.minScaleX = 1;
        particles.minScaleY = 1;
        particles.minEmitPower = 5;
        particles.maxEmitPower = 6;
        particles.particleTexture = BABYLON.Texture.LoadFromDataString('particleTexture', PARTICLE_SHAPE_FUNCTIONS[0]('#ffffff'), scene, true, true, true, BABYLON.Texture.NEAREST_SAMPLINGMODE);
        particles.blendMode = BABYLON.ParticleSystem.BLENDMODE_MULTIPLYADD;
        particles.color1 = new BABYLON.Color4(1, 1, 1, 1);
        particles.color2 = new BABYLON.Color4(1, 1, 1, 1);
        particles.colorDead = new BABYLON.Color4(1, 1, 1, 0);
        particles.emitter = camera.position;
        particles.createBoxEmitter(BABYLON.Vector3.UpReadOnly, BABYLON.Vector3.UpReadOnly, new BABYLON.Vector3(-100, -100, -100), new BABYLON.Vector3(100, 100, 100));
        particles.start();

        const file = `/models/generated_models/body${body}-color0-face0.gltf`;
        const meshPromise = BABYLON.SceneLoader.ImportMeshAsync(
            "",
            file,
            "",
            scene,
            null,
            '.gltf'
        ).then(result => {
            const mesh = result.meshes[0];
            mesh.rotationQuaternion = null;
            mesh.name = `body${body}-color0-face0`;

            result.meshes[1].material = PBRMaterialToStandardMaterial((result.meshes[1].material as BABYLON.PBRMaterial), scene);
            result.meshes[2].material = PBRMaterialToStandardMaterial((result.meshes[2].material as BABYLON.PBRMaterial), scene);

            const characterParent = new BABYLON.Mesh('characterParent', scene);
            characterParent.position = BABYLON.Vector3.Zero();
            mesh.parent = characterParent;
            characterParent.scaling.x *= -1;

            return mesh;
        });

        // Import animations
        const animationFile = AVATAR_ANIMATIONS[getAnimationIndexForSizeAndColor(color, size > 7 ? 2 : size > 2 ? 1 : 0)];
        const animationsPromise = BABYLON.SceneLoader.ImportMeshAsync(
            "",
            `/animations/${animationFile}.glb`,
            "",
            scene,
            null,
            '.glb'
        ).then(result => {
            result.meshes[0].rotationQuaternion = null;
            return scene.animationGroups.slice();
        });

        // Load faces
        const texture = new BABYLON.Texture(`/textures/faces/${faces[face]}.png`, scene, false, false, BABYLON.Texture.NEAREST_SAMPLINGMODE);
        texture.hasAlpha = true;

        meshPromise.then(async character => {
            const animations = await animationsPromise;
            // Apply parameters
            currentAvatarRef.current = character;

            const characterParent = character.parent as BABYLON.Mesh;

            const scaling = size > 7 ? 1.2 : size > 2 ? 1 : 0.8;
            characterParent.position.y = AVATAR_OFFSETS[body] * scaling || 0;
            character.scaling = BABYLON.Vector3.One().scale(scaling);

            const characters = scene.getMeshesByTags('character');
            scene.animationGroups.forEach(animationGroup => { animationGroup.stop() });
            characters.forEach(mesh => {
                mesh.getChildMeshes().forEach(childMesh => {
                    childMesh.isVisible = false;
                });
            });

            // Find the new character
            character.isVisible = true;
            const childMeshes = character.getChildMeshes();
            childMeshes.forEach(childMesh => childMesh.isVisible = true);

            // Set the face
            const face = childMeshes[0];
            (face.material as BABYLON.StandardMaterial).diffuseTexture = texture;

            // Set the color
            const bodyMaterial = (childMeshes[1].material as BABYLON.StandardMaterial);
            bodyMaterial.diffuseColor = avatarColors[color];
            bodyMaterial.emissiveColor = avatarColors[color];
            bodyMaterial.alpha = opacity > 6 ? 1 : opacity > 2 ? 0.9 : 0.5;

            scene.clearColor = avatarHexToBackground[avatarHexes[color]];


            // Start animation loaded from GLTF// Import animations
            // const camera = sceneRef.current.activeCamera as BABYLON.ArcRotateCamera;
            const currentAnimation = animations[0];
            const newAnimation = BABYLON.AnimationGroup.MakeAnimationAdditive(currentAnimation.clone(currentAnimation.name, () => character));

            setTimeout(() => {
                const el = document.createElement('div');
                el.id = "spaceInitialized";
                document.getElementById('root').append(el);
                // trigger the end when the animation ends
                newAnimation.onAnimationEndObservable.add(() => {
                    const el = document.getElementById('spaceViewCanvas');
                    if (el) {
                        el.remove();
                    }
                });
                newAnimation.start(false, 0.3);
            }, 1000);
        });

        // run the render loop
        engine.runRenderLoop(function () {
            scene.render();
        });

        // the canvas/window resize event handler
        window.addEventListener('resize', function () {
            engine.resize();
        });
    }, [body, color, face, opacity, size, animation]);

    return (
        <canvas id="spaceViewCanvas" className="space-view-canvas"></canvas>
    );
}

export default ViewAvatar;