import React, { useEffect, useRef, useState } from 'react';
import { avatarColors, avatarHexes, avatarHexToBackground, AVATAR_OFFSETS, bodyLayoutBytes, faces, PARTICLE_SHAPE_FUNCTIONS, AVATAR_ANIMATIONS, getAnimationIndexForSizeAndColor } from './constants';
import { BABYLON_COLORS, colors, LIGHT_PINK } from './Colors';
import * as BABYLON from 'babylonjs';
import './HyalikoAvatarView.css';
import { useParams } from 'react-router-dom';

const INTRO_CAMERA_EASING = new BABYLON.QuinticEase();
INTRO_CAMERA_EASING.setEasingMode(BABYLON.QuinticEase.EASINGMODE_EASEINOUT);

const AVATAR_FLY_IN_EASE = new BABYLON.SineEase();
AVATAR_FLY_IN_EASE.setEasingMode(BABYLON.SineEase.EASINGMODE_EASEINOUT);

const PBRMaterialToStandardMaterial = (pbrMaterial: BABYLON.PBRMaterial, scene: BABYLON.Scene) => {
    const newMaterial = new BABYLON.StandardMaterial(pbrMaterial.name, scene);
    newMaterial.diffuseColor = pbrMaterial.albedoColor;
    newMaterial.diffuseTexture = pbrMaterial.albedoTexture;
    newMaterial.emissiveColor = pbrMaterial.emissiveColor;
    newMaterial.useAlphaFromDiffuseTexture = true;
    newMaterial.alpha = pbrMaterial.alpha;
    newMaterial.alphaMode = pbrMaterial.alphaMode;
    pbrMaterial.dispose();
    return newMaterial;
}

// const avatarColors = [
//     new BABYLON.Color3(1, 0.403, 0.812),
//     new BABYLON.Color3(0.02, 1, 0.631),
//     new BABYLON.Color3(0.7215686274509804, 0.403921568627451, 1),
//     new BABYLON.Color3(0.8, 0.8, 0.8),
//     new BABYLON.Color3(0.486, 0.486, 0.486),
//     new BABYLON.Color3(1, 1, 1),
//     new BABYLON.Color3(0, 0, 0),
//     new BABYLON.Color3(0, 0.816, 0.506),
//     new BABYLON.Color3(0.675, 0.549, 1),
//     new BABYLON.Color3(0.588, 0.122, 1),
//     new BABYLON.Color3(0.949, 0.596, 0),
//     new BABYLON.Color3(0.996, 0.0118, 0.008),
//     new BABYLON.Color3(0.596, 0.004, 0),
//     new BABYLON.Color3(0.043, 0.804, 0.996),
//     new BABYLON.Color3(0.286, 0, 1)
// ];

const BODIES = bodyLayoutBytes.length;
const FACES = faces.length;
const COLORS = avatarColors.length;

const HOVER_EASING = new BABYLON.QuinticEase();
HOVER_EASING.setEasingMode(BABYLON.QuinticEase.EASINGMODE_EASEINOUT);

type ViewAvatarParams = {
    body: string,
    face: string,
    color: string,
    size: string,
    opacity: string,
    animation: string
}

function ViewSpace() {
    // Refs
    const sceneRef: React.MutableRefObject<BABYLON.Scene> = useRef(null);
    const texturesRef: React.MutableRefObject<BABYLON.Texture[]> = useRef([]);
    const animationsRef: React.MutableRefObject<BABYLON.AnimationGroup[]> = useRef(AVATAR_ANIMATIONS.map(() => null));
    const currentAvatarRef: React.MutableRefObject<BABYLON.AbstractMesh> = useRef(null);

    const { body: rawBody, face: rawFace, color: rawColor, size: rawSize, opacity: rawOpacity, animation: rawAnimation } = useParams() as ViewAvatarParams;
    const body = rawBody ? parseInt(rawBody) : null;
    const face = rawFace ? parseInt(rawFace) : null;
    const color = rawColor ? parseInt(rawColor) : null;
    const size = rawSize ? parseInt(rawSize) : null;
    const opacity = rawOpacity ? parseInt(rawOpacity) : null;
    const animation = rawAnimation ? parseInt(rawAnimation) : null;

    const [currentParameters, setCurrentParameters] = useState({
        body: null,
        face: null,
        color: null,
        size: null,
        opacity: null,
        animation: null,
        background: null
    });

    useEffect(() => {
        const scene = sceneRef.current;
        if (!scene) {
            return;
        }

        const newCharacterName = `body${currentParameters.body}-color0-face0`;
        const character = scene.getMeshByName(newCharacterName);
        currentAvatarRef.current = character;
        const childMeshes = character.getChildMeshes();

        const characterParent = character.parent as BABYLON.Mesh;

        const size = currentParameters.size;
        const scaling = size > 7 ? 1.2 : size > 2 ? 1 : 0.8;
        characterParent.position.y = AVATAR_OFFSETS[currentParameters.body] * scaling || 0;
        character.scaling = BABYLON.Vector3.One().scale(scaling);

        const characters = scene.getMeshesByTags('character');
        scene.animationGroups.forEach(animationGroup => { animationGroup.stop() });
        characters.forEach(mesh => {
            mesh.getChildMeshes().forEach(childMesh => {
                childMesh.isVisible = false;
            });
        });

        // Find the new character
        character.isVisible = true;
        childMeshes.forEach(childMesh => childMesh.isVisible = true);

        // Set the face
        const face = childMeshes[0];
        (face.material as BABYLON.StandardMaterial).diffuseTexture = texturesRef.current[currentParameters.face];

        // Set the color
        const bodyMaterial = (childMeshes[1].material as BABYLON.StandardMaterial);
        bodyMaterial.diffuseColor = avatarColors[currentParameters.color];
        bodyMaterial.emissiveColor = avatarColors[currentParameters.color];
        const opacity = currentParameters.opacity;
        bodyMaterial.alpha = opacity > 6 ? 1 : opacity > 2 ? 0.9 : 0.5;

        scene.clearColor = avatarHexToBackground[avatarHexes[currentParameters.color]];

        // Start animation loaded from GLTF// Import animations
        const animations = animationsRef.current;
        // const camera = sceneRef.current.activeCamera as BABYLON.ArcRotateCamera;
        const currentAnimation = animations[getAnimationIndexForSizeAndColor(currentParameters.color, size > 7 ? 2 : size > 2 ? 1 : 0)];
        console.log(animations);
        console.log(currentAnimation);
        let newAnimation = currentAnimation.clone(currentAnimation.name, () => character);
        newAnimation = BABYLON.AnimationGroup.MakeAnimationAdditive(newAnimation);
        // const FRAME_RATE = 60;
        // const cameraAnimation = new BABYLON.Animation('cameraRotation', 'alpha', 60, BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE);
        // const cameraLoopKeys = [];
        // cameraLoopKeys.push({
        //     frame: 0,
        //     value: camera.alpha
        // });
        // cameraLoopKeys.push({
        //     frame: FRAME_RATE * 2,
        //     value: camera.alpha + 4 * Math.PI
        // });
        // cameraLoopKeys.push({
        //     frame: (FRAME_RATE * 2) + 300,
        //     value: camera.alpha + 4 * Math.PI
        // });
        // cameraAnimation.setKeys(cameraLoopKeys);
        // cameraAnimation.setEasingFunction(AVATAR_FLY_IN_EASE);
        // scene.beginDirectAnimation(camera, [cameraAnimation], 0, (FRAME_RATE * 2) + 300, true);
        newAnimation.start(true);
    }, [currentParameters]);

    // Mount Babylon
    useEffect(() => {
        // Get the canvas DOM element
        const canvas: HTMLCanvasElement = document.getElementById('spaceViewCanvas') as HTMLCanvasElement;
        // Load the 3D engine
        const engine = new BABYLON.Engine(canvas, false, {
            preserveDrawingBuffer: true, stencil: true, xrCompatible: false
        }, false);

        // Create a basic BJS Scene object
        const scene = new BABYLON.Scene(engine);
        // scene.debugLayer.show();
        sceneRef.current = scene;

        scene.fogEnabled = true;
        scene.fogMode = BABYLON.Scene.FOGMODE_EXP;
        scene.fogColor = BABYLON.Color3.FromHexString(colors.LIGHT_PINK);
        scene.fogDensity = 0.0025;

        // var skybox = BABYLON.MeshBuilder.CreateSphere("skyBox", { diameter: 500.0 }, scene);
        // var skyboxMaterial = new BABYLON.StandardMaterial("skyBox", scene);
        // skyboxMaterial.backFaceCulling = false;
        // skyboxMaterial.diffuseTexture = new BABYLON.Texture("/textures/skybox/skybox_ny.jpg", scene);
        // skyboxMaterial.diffuseColor = new BABYLON.Color3(0, 0, 0);
        // skyboxMaterial.specularColor = new BABYLON.Color3(0, 0, 0);
        // skyboxMaterial.emissiveColor = new BABYLON.Color3(1, 1, 1);
        // skyboxMaterial.disableLighting = true;
        // skybox.material = skyboxMaterial;

        var camera = new BABYLON.ArcRotateCamera("Camera", Math.PI / 2, Math.PI / 2, 4, BABYLON.Vector3.Zero(), scene);
        camera.attachControl(canvas, true);
        // new AsciiArtPostProcess("AsciiArt", camera, {
        //     font: "32px Monospace",
        //     characterSet: "かけくこなねにのはひへほられりろいえおうふをわさしせそたちてと"
        // });
        new BABYLON.PassPostProcess("resolution", 0.4, camera);

        const light = new BABYLON.DirectionalLight('light1', new BABYLON.Vector3(-1, -1, 0), scene);
        light.intensity = 0.5;
        scene.ambientColor = BABYLON_COLORS.PINK;
        scene.clearColor = LIGHT_PINK;
        scene.fogEnabled = true;
        scene.fogMode = BABYLON.Scene.FOGMODE_EXP;
        scene.fogColor = BABYLON.Color3.White();
        scene.fogDensity = 0.001;

        // Ambient particle system
        const particleLimit = 2000;
        const emitRateMultiplier = 80;
        // const particleLimit = 20000;
        // const emitRateMultiplier = 12000;
        const particles = new BABYLON.ParticleSystem('particles', particleLimit, scene);
        particles.emitRate *= emitRateMultiplier;
        particles.maxScaleX = 1;
        particles.maxScaleY = 1;
        particles.minScaleX = 1;
        particles.minScaleY = 1;
        particles.minEmitPower = 5;
        particles.maxEmitPower = 6;
        particles.particleTexture = BABYLON.Texture.LoadFromDataString('particleTexture', PARTICLE_SHAPE_FUNCTIONS[0]('#ffffff'), scene, true, true, true, BABYLON.Texture.NEAREST_SAMPLINGMODE);
        particles.blendMode = BABYLON.ParticleSystem.BLENDMODE_MULTIPLYADD;
        particles.color1 = new BABYLON.Color4(1, 1, 1, 1);
        particles.color2 = new BABYLON.Color4(1, 1, 1, 1);
        particles.colorDead = new BABYLON.Color4(1, 1, 1, 0);
        particles.emitter = camera.position;
        particles.createBoxEmitter(BABYLON.Vector3.UpReadOnly, BABYLON.Vector3.UpReadOnly, new BABYLON.Vector3(-100, -100, -100), new BABYLON.Vector3(100, 100, 100));
        particles.start();

        // avatar particles
        // const playerParticles = new BABYLON.ParticleSystem('playerParticles', 200, scene);
        // playerParticles.maxScaleX = 0.25;
        // playerParticles.maxScaleY = 0.25;
        // playerParticles.minScaleX = 0.25;
        // playerParticles.minScaleY = 0.25;
        // playerParticles.maxEmitPower /= 2;
        // playerParticles.particleTexture = new BABYLON.Texture('/textures/Flare.png', scene);
        // playerParticles.color1 = BABYLON_COLORS.WHITE.toColor4();
        // playerParticles.color2 = BABYLON_COLORS.WHITE.toColor4();
        // playerParticles.emitRate *= 10;
        // playerParticles.maxLifeTime /= 100;
        // playerParticles.createSphereEmitter(0.1, 1);
        // playerParticles.start();
        // playerParticlesRef.current = playerParticles;
        // const SPS = new BABYLON.SolidParticleSystem("SPS", scene, {
        //     useModelMaterial: true
        // });
        // const particleMaterial = new BABYLON.StandardMaterial('particleMaterial');
        // particleMaterial.diffuseColor = BABYLON_COLORS.WHITE;
        // particleMaterial.ambientColor = BABYLON_COLORS.WHITE;
        // particleMaterial.emissiveColor = BABYLON_COLORS.WHITE;
        // particleMaterial.alphaMode = BABYLON.Material.MATERIAL_ALPHABLEND;
        // particleMaterial.alpha = 1;
        // const sphere = BABYLON.MeshBuilder.CreateSphere("s", { diameter: 0.15 });
        // sphere.material = particleMaterial;
        // SPS.addShape(sphere, 30); // 20 spheres
        // sphere.dispose(); //free memory

        // SPS.buildMesh();

        // const recycleParticle = (particle: BABYLON.SolidParticle) => {
        //     const currentAvatar = currentAvatarRef.current;
        //     if (currentAvatar) {
        //         const currentAvatarPosition = currentAvatar.position;
        //         particle.position.x = currentAvatarPosition.x + (-0.5 + Math.random()) * (0.1 * currentAvatar.scaling.x);
        //         particle.position.y = currentAvatarPosition.y + (-0.5 + Math.random()) * (0.1 * currentAvatar.scaling.x);
        //         particle.position.z = currentAvatarPosition.z + (-0.5 + Math.random()) * (0.1 * currentAvatar.scaling.x);
        //         particle.scaling = currentAvatar.scaling;
        //         particle.props.lifespan = 30 + Math.random() * 30;
        //         particle.props.lifetime = 0;
        //         particle.props.direction = new BABYLON.Vector3((-0.5 + Math.random()) * 0.005, (-0.5 + Math.random()) * 0.005, (-0.5 + Math.random()) * 0.005);
        //     } else {
        //         particle.props.lifetime = 0;
        //         particle.props.lifespan = 0;
        //     }
        //     return particle;
        // }

        // SPS.initParticles = () => {
        //     for (let p = 0; p < SPS.nbParticles; p++) {
        //         const particle = SPS.particles[p];
        //         particle.props = {};
        //         recycleParticle(particle);
        //     }
        // };

        // //Update SPS mesh
        // SPS.initParticles();
        // SPS.setParticles();

        // //Update function
        // SPS.updateParticle = (particle) => {
        //     particle.props.lifetime++;
        //     if (particle.props.lifetime >= particle.props.lifespan) {
        //         // set random starting position and direction
        //         return recycleParticle(particle);
        //     } else {
        //         // update movement
        //         particle.position.x += particle.props.direction.x;
        //         particle.position.y += particle.props.direction.y;
        //         particle.position.z += particle.props.direction.z;
        //         return particle;
        //     }
        // }


        // // Randomized
        // const bodies = 21;
        // const colors = 1;
        // const faces = 22;
        // let totalCount = 0;
        // let meshLoadPromises = [];
        // for (let bodyIndex = 0; bodyIndex < bodies; bodyIndex++) {
        //     for (let colorIndex = 0; colorIndex < colors; colorIndex++) {
        //         for (let faceIndex = 0; faceIndex < faces; faceIndex++) {
        //             const count = totalCount;
        //             const randomBody = Math.floor(Math.random() * bodies);
        //             const randomFace = Math.floor(Math.random() * faces);
        //             const file = `/generated_models/body${randomBody}-color${colorIndex}-face${randomFace}.gltf`;
        //             meshLoadPromises.push(BABYLON.SceneLoader.ImportMeshAsync(
        //                 "",
        //                 file,
        //                 "",
        //                 scene,
        //                 null,
        //                 '.gltf'
        //             ).then(result => {
        //                 const mesh = result.meshes[0];
        //                 mesh.name = count.toString();
        //                 BABYLON.Tags.AddTagsTo(mesh, 'character');

        //                 const body = result.meshes[2];
        //                 const randomColor = avatarColors[Math.floor(Math.random() * avatarColors.length)];
        //                 (body.material as BABYLON.PBRMaterial).albedoColor = randomColor;
        //                 (body.material as BABYLON.PBRMaterial).emissiveColor = randomColor;

        //                 mesh.isVisible = false;
        //             }));
        //             totalCount++;
        //         }
        //     }
        // }

        let meshLoadPromises = [];
        for (let bodyIndex = 0; bodyIndex < BODIES; bodyIndex++) {
            for (let colorIndex = 0; colorIndex < 1; colorIndex++) {
                for (let faceIndex = 0; faceIndex < 1; faceIndex++) {
                    const file = `/models/generated_models/body${bodyIndex}-color0-face0.gltf`;
                    meshLoadPromises.push(BABYLON.SceneLoader.ImportMeshAsync(
                        "",
                        file,
                        "",
                        scene,
                        null,
                        '.gltf'
                    ).then(result => {
                        const mesh = result.meshes[0];
                        mesh.rotationQuaternion = null;
                        mesh.name = `body${bodyIndex}-color0-face0`;
                        BABYLON.Tags.AddTagsTo(mesh, 'character');

                        result.meshes[1].material = PBRMaterialToStandardMaterial((result.meshes[1].material as BABYLON.PBRMaterial), scene);
                        result.meshes[1].material.name += bodyIndex;
                        result.meshes[2].material = PBRMaterialToStandardMaterial((result.meshes[2].material as BABYLON.PBRMaterial), scene);
                        result.meshes[2].material.name += bodyIndex;
                        result.meshes.forEach((childMesh, i) => {
                            childMesh.isVisible = false
                        });

                        const characterParent = new BABYLON.Mesh('characterParent', scene);
                        characterParent.position = BABYLON.Vector3.Zero();
                        characterParent.rotation = mesh.rotation;
                        characterParent.rotationQuaternion = mesh.rotationQuaternion;
                        characterParent.addChild(mesh);
                        characterParent.scaling.x *= -1;
                    }));
                }
            }
        }

        // Import animations
        AVATAR_ANIMATIONS.forEach((avatarAnimation, i) => {
            meshLoadPromises.push(BABYLON.SceneLoader.ImportMeshAsync(
                "",
                `/animations/${avatarAnimation}.glb`,
                "",
                scene,
                null,
                '.glb'
            ).then(result => {
                result.meshes[0].rotationQuaternion = null;
                animationsRef.current[i] = result.animationGroups[0];
                BABYLON.AnimationGroup.MakeAnimationAdditive(result.animationGroups[0]);
            }));
        });

        // TODO read from URL
        Promise.all(meshLoadPromises).then(() => {
            setCurrentParameters({
                body: body || 0,
                face: face || 0,
                color: color || 0,
                animation: animation || 0,
                background: null,
                opacity: opacity || 5,
                size: size || 5
            })
        });

        // Load faces
        for (let faceIndex = 0; faceIndex < FACES; faceIndex++) {
            const texture = new BABYLON.Texture(`/textures/faces/${faces[faceIndex]}.png`, scene, false, false, BABYLON.Texture.NEAREST_SAMPLINGMODE);
            texture.hasAlpha = true;
            texturesRef.current.push(texture);
        }

        // run the render loop
        engine.runRenderLoop(function () {
            scene.render();
        });

        scene.registerAfterRender(() => {
            // SPS.setParticles();
        })

        // the canvas/window resize event handler
        window.addEventListener('resize', function () {
            engine.resize();
        });
    }, [body, color, face, opacity, size, animation]);

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <canvas id="spaceViewCanvas" className="hyaliko-avatar-canvas" onClick={() => {
                // spin camera and then play the current hyaliko's animation on click
                // const camera = sceneRef.current.activeCamera as BABYLON.ArcRotateCamera;
                // BABYLON.Animation.CreateAndStartAnimation('cameraSpin', camera, 'alpha', 60, 90, camera.alpha, camera.alpha + 2 * Math.PI, BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE, AVATAR_FLY_IN_EASE, () => {
                //     const animations = sceneRef.current.animationGroups;
                //     animations[animations.length - 1].start(false);
                // });
            }}></canvas>
            <div style={{ position: 'absolute', width: 1024, left: 'calc(50% - 512px)', bottom: 0, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <button className="new-home-button avatar-view-button" onClick={() => {
                        let newBody = (currentParameters.body === 0) ? BODIES - 1 : currentParameters.body - 1;
                        setCurrentParameters({
                            ...currentParameters,
                            body: newBody
                        });
                    }}>prev body</button>
                    <div>{currentParameters.body}</div>
                    <button className="new-home-button avatar-view-button" onClick={() => {
                        let newBody = (currentParameters.body === BODIES - 1) ? 0 : currentParameters.body + 1;
                        setCurrentParameters({
                            ...currentParameters,
                            body: newBody
                        });
                    }}>next body</button>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <button className="new-home-button avatar-view-button" onClick={() => {
                        setCurrentParameters({
                            ...currentParameters,
                            face: (currentParameters.face === 0) ? FACES - 1 : currentParameters.face - 1
                        });
                    }}>prev face</button>
                    <div>{currentParameters.face}</div>
                    <button className="new-home-button avatar-view-button" onClick={() => {
                        setCurrentParameters({
                            ...currentParameters,
                            face: (currentParameters.face === FACES - 1) ? 0 : currentParameters.face + 1
                        });
                    }}>next face</button>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <button className="new-home-button avatar-view-button" onClick={() => {
                        setCurrentParameters({
                            ...currentParameters,
                            color: (currentParameters.color === 0) ? COLORS - 1 : currentParameters.color - 1
                        });
                    }}>prev color</button>
                    <div>{currentParameters.color}</div>
                    <button className="new-home-button avatar-view-button" onClick={() => {
                        setCurrentParameters({
                            ...currentParameters,
                            color: (currentParameters.color === COLORS - 1) ? 0 : currentParameters.color + 1
                        });
                    }}>next color</button>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <button className="new-home-button avatar-view-button" onClick={() => {
                        setCurrentParameters({
                            ...currentParameters,
                            size: (currentParameters.size === 0) ? 9 : currentParameters.size - 1
                        });
                    }}>prev size</button>
                    <div>{currentParameters.size}</div>
                    <button className="new-home-button avatar-view-button" onClick={() => {
                        setCurrentParameters({
                            ...currentParameters,
                            size: (currentParameters.size === 9) ? 0 : currentParameters.size + 1
                        });
                    }}>next size</button>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <button className="new-home-button avatar-view-button" onClick={() => {
                        setCurrentParameters({
                            ...currentParameters,
                            opacity: (currentParameters.opacity === 0) ? 9 : currentParameters.opacity - 1
                        });
                    }}>prev opacity</button>
                    <div>{currentParameters.opacity}</div>
                    <button className="new-home-button avatar-view-button" onClick={() => {
                        setCurrentParameters({
                            ...currentParameters,
                            opacity: (currentParameters.opacity === 9) ? 0 : currentParameters.opacity + 1
                        });
                    }}>next opacity</button>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <button className="new-home-button avatar-view-button" onClick={() => {
                        setCurrentParameters({
                            ...currentParameters,
                            animation: (currentParameters.animation === 0) ? 12 : currentParameters.animation - 1
                        });
                    }}>prev animation</button>
                    <div>{currentParameters.animation}</div>
                    <button className="new-home-button avatar-view-button" onClick={() => {
                        setCurrentParameters({
                            ...currentParameters,
                            animation: (currentParameters.animation === 12) ? 0 : currentParameters.animation + 1
                        });
                    }}>next animation</button>
                </div>
            </div>
        </div>
    );
}

export default ViewSpace;