import './Modal.css';

type ModalProps = {
    title: string,
    text: string,
    leftButtonTitle: string,
    leftButtonOnClick: () => void,
    rightButtonTitle: string,
    rightButtonOnClick: () => void,
    isOpen: boolean
};

function Modal(props: ModalProps) {
    const { title, text, leftButtonTitle, leftButtonOnClick, rightButtonTitle, rightButtonOnClick, isOpen } = props;

    return isOpen ? (
        <div className="modal-container">
            <div className="modal-body">
                <div className="modal-content">
                    <span className="modal-title"><b>{title}</b></span>
                    <span className="modal-text">{text}</span>
                </div>
                <div className="modal-button-row">
                    {leftButtonOnClick && <button className="modal-button" onClick={leftButtonOnClick}>{leftButtonTitle}</button>}
                    {rightButtonOnClick && <button className="modal-button" onClick={rightButtonOnClick}>{rightButtonTitle}</button>}
                </div>
            </div>
        </div>
    ) : null;
}

export default Modal;
