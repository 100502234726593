import React, { useEffect, useRef } from 'react';
// import { PARTICLE_SHAPE_FUNCTIONS } from './constants';
// import { colors as constColors } from './Colors';
import * as BABYLON from 'babylonjs';
// TODO remove this 
// import { AsciiArtPostProcess } from 'babylonjs-post-process';
import './HyalikoAvatars.css';
import { faces } from './constants';

// const avatarColors = [
//     new BABYLON.Color3(1, 0.403, 0.812),
//     new BABYLON.Color3(0.02, 1, 0.631),
//     new BABYLON.Color3(0.7215686274509804, 0.403921568627451, 1),
//     new BABYLON.Color3(0.8, 0.8, 0.8),
//     new BABYLON.Color3(0.486, 0.486, 0.486),
//     new BABYLON.Color3(1, 1, 1),
//     new BABYLON.Color3(0, 0, 0),
//     new BABYLON.Color3(0, 0.816, 0.506),
//     new BABYLON.Color3(0.675, 0.549, 1),
//     new BABYLON.Color3(0.588, 0.122, 1),
//     new BABYLON.Color3(0.949, 0.596, 0),
//     new BABYLON.Color3(0.996, 0.0118, 0.008),
//     new BABYLON.Color3(0.596, 0.004, 0),
//     new BABYLON.Color3(0.043, 0.804, 0.996),
//     new BABYLON.Color3(0.286, 0, 1)
// ];

function ViewSpace() {
    // Refs
    const sceneRef: React.MutableRefObject<BABYLON.Scene> = useRef(null);
    const currentCharacterRef: React.MutableRefObject<BABYLON.AbstractMesh> = useRef(null);
    // Mount Babylon
    useEffect(() => {
        // Get the canvas DOM element
        const canvas: HTMLCanvasElement = document.getElementById('spaceViewCanvas') as HTMLCanvasElement;
        // Load the 3D engine
        const engine = new BABYLON.Engine(canvas, false, {
            preserveDrawingBuffer: true, stencil: true, xrCompatible: false
        }, false);

        // Create a basic BJS Scene object
        const scene = new BABYLON.Scene(engine);
        scene.debugLayer.show();
        sceneRef.current = scene;

        // var skybox = BABYLON.MeshBuilder.CreateSphere("skyBox", { diameter: 500.0 }, scene);
        // var skyboxMaterial = new BABYLON.StandardMaterial("skyBox", scene);
        // skyboxMaterial.backFaceCulling = false;
        // skyboxMaterial.diffuseTexture = new BABYLON.Texture("/textures/skybox/skybox_ny.jpg", scene);
        // skyboxMaterial.diffuseColor = new BABYLON.Color3(0, 0, 0);
        // skyboxMaterial.specularColor = new BABYLON.Color3(0, 0, 0);
        // skyboxMaterial.emissiveColor = new BABYLON.Color3(1, 1, 1);
        // skyboxMaterial.disableLighting = true;
        // skybox.material = skyboxMaterial;

        const camera = new BABYLON.FreeCamera('camera', new BABYLON.Vector3(0, 0, 5), scene);
        camera.target = new BABYLON.Vector3(0, 0, 0);
        camera.attachControl(canvas, true);
        // new AsciiArtPostProcess("AsciiArt", camera, {
        //     font: "32px Monospace",
        //     characterSet: "かけくこなねにのはひへほられりろいえおうふをわさしせそたちてと"
        // });
        new BABYLON.PassPostProcess("resolution", 0.4, camera);

        const light = new BABYLON.DirectionalLight('light1', new BABYLON.Vector3(1, -1, 1), scene);
        light.intensity = 0.5;
        scene.clearColor = BABYLON.Color3.FromHexString('#000000').toColor4(1);

        // Ambient particle system
        // const particleLimit = 2000;
        // const emitRateMultiplier = 80;
        // const particleLimit = 20000;
        // const emitRateMultiplier = 12000;
        // const particles = new BABYLON.ParticleSystem('particles', particleLimit, scene);
        // particles.emitRate *= emitRateMultiplier;
        // particles.maxScaleX = 1;
        // particles.maxScaleY = 1;
        // particles.minScaleX = 1;
        // particles.minScaleY = 1;
        // particles.minEmitPower = 5;
        // particles.maxEmitPower = 6;
        // particles.particleTexture = BABYLON.Texture.LoadFromDataString('particleTexture', PARTICLE_SHAPE_FUNCTIONS[0]('#ffffff'), scene, true, true, true, BABYLON.Texture.NEAREST_SAMPLINGMODE);
        // particles.blendMode = BABYLON.ParticleSystem.BLENDMODE_MULTIPLYADD;
        // particles.color1 = new BABYLON.Color4(1, 1, 1, 1);
        // particles.color2 = new BABYLON.Color4(1, 1, 1, 1);
        // particles.colorDead = new BABYLON.Color4(1, 1, 1, 0);
        // particles.emitter = camera.position;
        // particles.createBoxEmitter(BABYLON.Vector3.UpReadOnly, BABYLON.Vector3.UpReadOnly, new BABYLON.Vector3(-100, -100, -100), new BABYLON.Vector3(100, 100, 100));
        // particles.start();


        // // Randomized
        // const bodies = 21;
        // const colors = 1;
        // const faces = 22;
        // let totalCount = 0;
        // let meshLoadPromises = [];
        // for (let bodyIndex = 0; bodyIndex < bodies; bodyIndex++) {
        //     for (let colorIndex = 0; colorIndex < colors; colorIndex++) {
        //         for (let faceIndex = 0; faceIndex < faces; faceIndex++) {
        //             const count = totalCount;
        //             const randomBody = Math.floor(Math.random() * bodies);
        //             const randomFace = Math.floor(Math.random() * faces);
        //             const file = `/generated_models/body${randomBody}-color${colorIndex}-face${randomFace}.gltf`;
        //             meshLoadPromises.push(BABYLON.SceneLoader.ImportMeshAsync(
        //                 "",
        //                 file,
        //                 "",
        //                 scene,
        //                 null,
        //                 '.gltf'
        //             ).then(result => {
        //                 const mesh = result.meshes[0];
        //                 mesh.name = count.toString();
        //                 BABYLON.Tags.AddTagsTo(mesh, 'character');

        //                 const body = result.meshes[2];
        //                 const randomColor = avatarColors[Math.floor(Math.random() * avatarColors.length)];
        //                 (body.material as BABYLON.PBRMaterial).albedoColor = randomColor;
        //                 (body.material as BABYLON.PBRMaterial).emissiveColor = randomColor;

        //                 mesh.isVisible = false;
        //             }));
        //             totalCount++;
        //         }
        //     }
        // }

        // 46
        const bodies = 1;
        const colors = 1;
        for (let bodyIndex = 0; bodyIndex < bodies; bodyIndex++) {
            for (let colorIndex = 0; colorIndex < colors; colorIndex++) {
                for (let faceIndex = 0; faceIndex < faces.length   ; faceIndex++) {
                    const file = `/models/generated_models/body0-color0-face${faceIndex}.gltf`;
                    BABYLON.SceneLoader.ImportMeshAsync(
                        "",
                        file,
                        "",
                        scene,
                        null,
                        '.gltf'
                    ).then(result => {
                        const mesh = result.meshes[0];
                        // mesh.position.x -= colorIndex * 5;
                        mesh.position.x -= faceIndex * 5;
                        // mesh.position.z += colorIndex * 5;
                        // const bodyMaterial = (result.meshes[2].material as BABYLON.PBRMaterial);
                        // bodyMaterial.albedoColor = avatarColors[colorIndex];
                        // bodyMaterial.emissiveColor = avatarColors[colorIndex];
                    });
                }
            }
        }

        // run the render loop
        engine.runRenderLoop(function () {
            scene.render();
        });

        // the canvas/window resize event handler
        window.addEventListener('resize', function () {
            engine.resize();
        });

        scene.registerBeforeRender(() => {
            const characters = scene.getMeshesByTags('character');
            const characterToUse = currentCharacterRef.current;
            if (!characterToUse) {
                return;
            }
            characterToUse.rotate(BABYLON.Vector3.UpReadOnly, 0.025 * 1.5);
            // skybox.rotate(BABYLON.Vector3.UpReadOnly, 0.0001);
            if (characterToUse.rotationQuaternion.toEulerAngles().y >= (Math.PI - 0.025)) {
                const characterToUse = Math.floor(Math.random() * characters.length);
                characters.forEach(mesh => {
                    mesh.getChildMeshes().forEach(childMesh => {
                        childMesh.isVisible = false;
                    });
                });
                const selectedChildMeshes = characters[characterToUse].getChildMeshes();
                selectedChildMeshes.forEach(childMesh => { childMesh.isVisible = true });
                currentCharacterRef.current = characters[characterToUse];
            }
        });
    }, []);

    return (
        <canvas id="spaceViewCanvas" className="hyaliko-avatar-canvas"></canvas>
    );
}

export default ViewSpace;