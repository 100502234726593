import React, { useEffect, useState } from 'react';
import './Marketplace.css';

const HYALIKO_TOKENS_URL = 'https://api.hyaliko.com/getHyalikoTokens';

// async function connectToMetaMask(history: any) {
//     const ethereum = window.ethereum;
//     if (!ethereum) {
//         window.open('https://metamask.io', '_blank');
//     } else {
//         const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
//         if (accounts && accounts.length > 0) {
//             history.push(`/${accounts.join(',')}`);
//         }
//         ethereum.on('accountsChanged', (newAccounts: any) => {
//             history.push(`/${newAccounts.join(',')}`);
//         });
//     }
// }

function Marketplace() {
    const [tokens, setTokens] = useState([]);

    useEffect(() => {
        fetch(`${HYALIKO_TOKENS_URL}?addresses=0x8D94ae183D36c2e0931555fAbAc20589ccF0A631`, {
            mode: 'cors',
            credentials: 'same-origin'
        }).catch(() => null)
            .then(res => res ? res.json() : { tokens: [] })
            .then(({ tokens }) => setTokens(tokens.filter((token: any) => token.token_id !== '114')))
    }, []);

    return (
        <>
            <div className="container marketplace-container">
                <div className="home-header">
                    <div>
                        <a className="home-header-link" href="/">home</a>
                    </div>
                    <span>
                        <a className="home-header-link" href="https://opensea.io/collection/hyaliko-space-garden" target="_blank" rel="noreferrer">opensea</a> <span className="home-header-link">•</span> <a className="home-header-link" href="https://twitter.com/hyaliko" target="_blank" rel="noreferrer">twitter</a> <span className="home-header-link">•</span> <a className="home-header-link" href="https://discord.gg/2xvCVkAuWu" target="_blank" rel="noreferrer">discord</a>
                    </span>
                </div>
                <div className="marketplace-inner-container">
                    <h1 className="home-title marketplace-title">hyaliko space marketplace</h1>
                    <h2 className="marketplace-subtitle">{tokens.length} unclaimed spaces remain.</h2>
                    <h2 className="marketplace-subtitle">available for purchase on <a href="https://opensea.io/collection/hyaliko-space-garden" target="_blank" rel="noreferrer" >opensea</a>.</h2>
                    <div className="marketplace-spaces">
                        {tokens.map((token, i) => {
                            return (
                                <div className="marketplace-item" key={token.token_id}>
                                    <a href={token.permalink} target="_blank" rel="noreferrer"><img src={token.image_url} className="marketplace-item-image" alt="token" /></a>
                                </div>
                            );
                        })}
                    </div>
                </div>

            </div>
        </>
    );
}

export default Marketplace;
