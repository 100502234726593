import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import InputModal from './InputModal';
import trash from './img/trash.svg';
import './Onboarding.css';

const numLines = 6;

export function Onboarding() {
    const history = useHistory();
    const [slideCount, setSlideCount] = useState(0);
    useEffect(() => {
        if (window.localStorage.getItem('onboardingComplete') === 'true') {
            history.replace('/mobile');
        }
        for (let i = 1; i < numLines; i++) {
            setTimeout(() => {
                setSlideCount(i);
            }, 500 * (i - 1));
        }
    }, [history]);
    return (
        <div className="container onboarding-container">
            <div className="inner-container onboarding-inner-container">
                <h2 className="onboarding-subtitle opacity-transition" style={{ opacity: slideCount >= 1 ? 1 : 0, transform: slideCount >= 1 ? 'translate(0, 0)' : 'translate(0, -10px)' }}>welcome to</h2>
                <h1 className="onboarding-title opacity-transition" style={{ opacity: slideCount >= 2 ? 1 : 0, transform: slideCount >= 2 ? 'translate(0, 0)' : 'translate(0, -10px)' }}>hyaliko</h1>
            </div>
            <button className="button big-button onboarding-big-button opacity-transition" style={{ opacity: slideCount >= 3 ? 1 : 0, transform: slideCount >= 3 ? 'translate(0, 0)' : 'translate(0, -10px)' }} onClick={() => {
                history.replace('/onboarding/galleries');
            }}>begin</button>
        </div>
    );
}

export function OnboardingGalleries() {
    const history = useHistory();
    const [slideCount, setSlideCount] = useState(0);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [addModalValue, setAddModalValue] = useState('');
    const inputRef = useRef(null);
    useEffect(() => {
        for (let i = 1; i < numLines; i++) {
            setTimeout(() => {
                setSlideCount(i);
            }, 500 * (i - 1));
        }
    }, []);

    const [galleries, setGalleries] = useState([]);
    return (
        <>
            <div className="container onboarding-container">
                <div className="inner-container onboarding-galleries-container">
                    <h2 className="onboarding-subtitle opacity-transition" style={{ opacity: slideCount >= 1 ? 1 : 0, transform: slideCount >= 1 ? 'translate(0, 0)' : 'translate(0, -10px)' }}>add your galleries</h2>
                    <div className="onboarding-galleries-list opacity-transition" style={{ opacity: slideCount >= 2 ? 1 : 0, transform: slideCount >= 2 ? 'translate(0, 0)' : 'translate(0, -10px)' }}>
                        {galleries.map((gallery, i) => (
                            <div className="onboarding-gallery-container" key={gallery}>
                                <div key={gallery} className="onboarding-gallery">{gallery}</div>
                                <button className="onboarding-remove-button" onClick={() => { setGalleries(galleries.filter((e, ei) => ei !== i)); }}><img className="onboarding-remove-button" src={trash} alt="" /></button>
                            </div>
                        ))}
                    </div>
                    <button className="onboarding-gallery onboarding-gallery-add opacity-transition" style={{ opacity: slideCount >= 3 ? 1 : 0, transform: slideCount >= 3 ? 'translate(0, 0)' : 'translate(0, -10px)' }} onClick={() => { setAddModalOpen(true); setTimeout(() => inputRef.current.focus(), 100); }}>add a gallery</button>
                </div>
                <button className="button big-button onboarding-big-button opacity-transition" style={{ opacity: slideCount >= 4 ? 1 : 0, transform: slideCount >= 4 ? 'translate(0, 0)' : 'translate(0, -10px)' }} onClick={() => {
                    window.localStorage.setItem('galleries', JSON.stringify(galleries));
                    history.replace('/onboarding/notifications');
                }}>continue</button>
            </div>
            <InputModal
                isOpen={addModalOpen}
                title="add a gallery"
                rightButtonOnClick={() => {
                    if (!(addModalValue.includes('.') || addModalValue.startsWith('0x'))) {
                        alert('must be an eth address or ens name');
                        return;
                    }
                    if (!galleries.includes(addModalValue)) {
                        setGalleries([...galleries, addModalValue]);
                    }
                    setAddModalOpen(false);
                    setAddModalValue('');
                }}
                rightButtonTitle="add"
                leftButtonOnClick={() => { setAddModalOpen(false); setAddModalValue(''); }}
                leftButtonTitle="cancel"
                value={addModalValue}
                onChange={e => setAddModalValue(e.target.value)}
                placeholder="eth addr / ens"
                inputRef={inputRef}
            />
        </>
    );
}

export function OnboardingNotifications() {
    const history = useHistory();
    const [slideCount, setSlideCount] = useState(0);
    const [pushToken, setPushToken] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        for (let i = 1; i < numLines; i++) {
            setTimeout(() => {
                setSlideCount(i);
            }, 500 * (i - 1));
        }

        // Ask for push token
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                type: 'requestPushToken'
            }));
        }
    }, []);

    useEffect(() => {
        const handleNotificationsRequest = (event: MessageEvent<any>) => {
            if (event.data && event.data.source === 'hyaliko') {
                const message = event.data;
                if (message.type === 'notificationsPermissionGranted') {
                    // Set notifications on by default
                    fetch('https://api.hyaliko.com/setNotifications', {
                        method: 'POST',
                        mode: 'cors',
                        credentials: 'include',
                        cache: 'no-cache',
                        body: JSON.stringify({
                            pushToken: message.content.token,
                            addresses: JSON.parse(window.localStorage.getItem('galleries'))
                        })
                    }).then(() => {
                        window.localStorage.setItem('onboardingComplete', 'true');
                        history.replace('/mobile');
                    });
                } else if (message.type === 'notificationsPermissionDenied') {
                    if (pushToken) {
                        // Clear notifications
                        fetch('https://api.hyaliko.com/setNotifications', {
                            method: 'POST',
                            mode: 'cors',
                            credentials: 'include',
                            cache: 'no-cache',
                            body: JSON.stringify({
                                pushToken: pushToken,
                                addresses: []
                            })
                        }).then(() => {
                            window.localStorage.setItem('onboardingComplete', 'true');
                            history.replace('/mobile');
                        });
                    } else {
                        window.localStorage.setItem('onboardingComplete', 'true');
                        history.replace('/mobile');
                    }
                } else if (message.type === 'pushToken') {
                    setPushToken(message.content.token);
                }
            }
        };

        window.addEventListener('message', handleNotificationsRequest);

        return () => {
            window.removeEventListener('message', handleNotificationsRequest);
        };
    }, [history, pushToken]);

    return (
        <div className="container onboarding-container">
            <div className="inner-container onboarding-inner-container">
                <h2 className="onboarding-subtitle opacity-transition" style={{ opacity: slideCount >= 1 ? 1 : 0, transform: slideCount >= 1 ? 'translate(0, 0)' : 'translate(0, -10px)' }}>notifications</h2>
                <br></br>
                <h2 className="onboarding-subtitle opacity-transition" style={{ opacity: slideCount >= 2 ? 1 : 0, transform: slideCount >= 2 ? 'translate(0, 0)' : 'translate(0, -10px)' }}>allow hyaliko to send you notifications</h2>
                <br></br>
                <h2 className="onboarding-subtitle opacity-transition" style={{ opacity: slideCount >= 3 ? 1 : 0, transform: slideCount >= 3 ? 'translate(0, 0)' : 'translate(0, -10px)' }}>we'll let you know when you have viewers in your galleries</h2>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <button style={{ opacity: slideCount >= 4 ? 1 : 0, transform: slideCount >= 4 ? 'translate(0, 0)' : 'translate(0, -10px)' }} className="onboarding-notifications-skip opacity-transition" onClick={() => {
                    window.localStorage.setItem('onboardingComplete', 'true');
                    history.replace('/mobile');
                    if (pushToken) {
                        setLoading(true);
                        fetch('https://api.hyaliko.com/setNotifications', {
                            method: 'POST',
                            mode: 'cors',
                            credentials: 'include',
                            cache: 'no-cache',
                            body: JSON.stringify({
                                pushToken: pushToken,
                                addresses: []
                            })
                        }).then(() => {
                            window.localStorage.setItem('onboardingComplete', 'true');
                            history.replace('/mobile');
                            setLoading(false);
                        });
                    } else {
                        window.localStorage.setItem('onboardingComplete', 'true');
                        history.replace('/mobile');
                    }
                }}>skip this step for now</button>
                <button className="button big-button onboarding-big-button opacity-transition" style={{ opacity: slideCount >= 4 ? 1 : 0, transform: slideCount >= 4 ? 'translate(0, 0)' : 'translate(0, -10px)' }} onClick={async () => {
                    setLoading(true);
                    if (window.ReactNativeWebView) {
                        window.ReactNativeWebView.postMessage(JSON.stringify({
                            type: 'requestNotificationPermission'
                        }));
                    } else {
                        window.localStorage.setItem('onboardingComplete', 'true');
                        setLoading(false);
                        history.replace('/mobile');
                    }
                }}>{loading ? 'waiting...' : 'allow'}</button>
            </div>

        </div>
    );
}