import React, { useState } from 'react';
import './Redemption.css';

function Redemption() {
    const [id, setId] = useState('');
    const [status, setStatus] = useState(null);

    const getStatus = () => {
        fetch(`https://api.hyaliko.com/space-factory/redemption?tokenId=${parseInt(id)}`)
        .then(res => res.json())
        .then(json => setStatus(json.status));
    };

    return (
        <div className="container redemption-container">
            <div className="redemption-inner-container">
                <h2 style={{ marginBottom: 16 }}>genesis spaces from <a href="https://opensea.io/collection/hyaliko-space-garden" target="_blank" rel="noreferrer">the hyaliko space garden</a> can be redeemed<br></br>for a free mint from <a href="https://opensea.io/collection/hyaliko-space-factory" target="_blank" rel="noreferrer">the hyaliko space factory</a> </h2>
                <h2 style={{ marginBottom: 16 }}>to check whether or not a genesis space<br></br>has been redeemed yet, enter its token ID below</h2>
                <input style={{ marginBottom: 16 }} onChange={e => { setId(e.target.value); setStatus(null); }} value={id} type="text" autoComplete="off" autoCapitalize="off" className="text-input big-text-input"></input>
                <button style={{ marginBottom: 32 }} className="big-button button" disabled={!id || isNaN(parseInt(id))} onClick={getStatus}>check status</button>
                {status && <h2 className={`redemption-status ${status === 'redeemed' ? 'redemption-status-redeemed' : 'redemption-status-unredeemed'}`}>{status === 'redeemed' ? `space ${id} has already been redeemed :(` : `space ${id} has not been redeemed yet!`}</h2>}
                {status === 'unredeemed' && <h2 style={{ marginTop: 8 }}><a href={`https://opensea.io/assets/0xd6c1693653b1145f01b4052c8a3fb5b1a13718dd/${id}`} target="_blank" rel="noreferrer">view it on opensea</a></h2>}
            </div>
        </div>
    );
}

export default Redemption;
