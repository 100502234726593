const COOKIE_NAME = 'hyaliko-auth-sig';

function getCookie(cname: string) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export function getAuthCookie() {
  return getCookie(COOKIE_NAME);
}

const GET_AUTH_NONCE_URL = 'https://api.hyaliko.com/getAuthNonce';
const SUBMIT_SIGNED_AUTH_NONCE_URL = 'https://api.hyaliko.com/submitSignedAuthNonce';

// This async function is for verifying / authenticating before performing a restricted operation.
export const authenticate = async (web3: any, rawAddress: string) => {
  const address = rawAddress.toLowerCase();
  return new Promise(async (resolve) => {
    // Check if the user is authenticated.
    const authCookie = getAuthCookie();
    if (authCookie && authCookie === address) {
      resolve(true);
    } else {
      // Run through auth flow
      try {
        const nonce = await fetch(`${GET_AUTH_NONCE_URL}?address=${address}`, {
          mode: 'cors',
          credentials: 'same-origin'
        }).catch(() => null)
          .then(res => res ? res.json() : { nonce: null })
          .then(json => json.nonce)
          .catch(() => null);

        const nonceString = `welcome to hyaliko!\nclick "sign" to authenticate.\nno password required.\n\naddress: ${address}\n\nnonce: ${nonce}`;
        const signedAuthNonce = await web3.eth.personal.sign(
          nonceString,
          address,
          'password',
          (err: any, signature: string) => {
            if (err) {
              return;
            } else if (signature) {
              return signature;
            }
          }
        )
        const signInResponse = await fetch(SUBMIT_SIGNED_AUTH_NONCE_URL, {
          method: 'POST',
          mode: 'cors',
          credentials: 'include',
          cache: 'no-cache',
          body: JSON.stringify({ address, signedAuthNonce })
        });
        if (signInResponse.status === 200) {
          resolve(true);
        }
      } catch (e) {
        resolve(false);
      }
    }
  });
};

export const checkAuthentication = (rawAddress: string, web3: any, web3Address: string) => {
  const address = rawAddress.toLowerCase();
  if (!web3Address || !web3) {
    return false;
  }
  // Check if the user is authenticated.
  const authCookie = getAuthCookie();
  if (authCookie && authCookie === address) {
    return true;
  } else {
    return false;
  }
};