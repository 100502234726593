import { useEffect, useRef, useState } from 'react';
import { DIALOGUE_PITCH_DISTRIBUTION, SOUND_DURATION, SOUND_SMOOTHING } from './constants';
import './DialogueBox.css';

type DialogueBoxProps = {
    text: string,
    frequencyMultiplier: number,
    onContinue: () => void,
    audio: any,
    renderContent?: () => any,
    error: string
};

function DialogueBox(props: DialogueBoxProps) {
    const { text, frequencyMultiplier, onContinue, audio, renderContent, error } = props;

    const dialogueRunningRef = useRef(false);
    const displayedTextRef = useRef('');
    const currentTextRef = useRef(null);

    const [displayedText, setDisplayedText] = useState('');

    useEffect(() => {
        if (text) {
            // If we got new text
            if (text !== currentTextRef.current) {
                currentTextRef.current = text;
                displayedTextRef.current = '';
                setDisplayedText('');
                dialogueRunningRef.current = false;
            }
            if (!dialogueRunningRef.current) {
                dialogueRunningRef.current = true;
                const loop = () => {
                    var rand = Math.round(Math.random() * 50) + 25;
                    setTimeout(function () {
                        // Stop if we're done with dialogue or if there is different text to render
                        if (!text || !dialogueRunningRef.current || currentTextRef.current !== text) {
                            return;
                        }
                        const newCharacter = text.substring(displayedTextRef.current.length, displayedTextRef.current.length + 1);
                        const newDisplayedText = displayedTextRef.current + newCharacter;
                        setDisplayedText(newDisplayedText);
                        displayedTextRef.current = newDisplayedText;

                        if (audio) {
                            audio.oscillator.frequency.value = frequencyMultiplier * 192.5 * DIALOGUE_PITCH_DISTRIBUTION[Math.floor(Math.random() * DIALOGUE_PITCH_DISTRIBUTION.length)];
                            const now = audio.audioContext.currentTime;
                            const gain = audio.gain;
                            gain.gain.setTargetAtTime(0.125, now, SOUND_SMOOTHING);
                            gain.gain.setTargetAtTime(0, now + SOUND_DURATION + (Math.random() * 0.02), SOUND_SMOOTHING);
                        }

                        // If text matches than we're done
                        if (displayedTextRef.current === text) {
                            dialogueRunningRef.current = false;
                        } else {
                            loop();
                        }
                    }, rand);
                };
                loop();
            }
        } else {
            displayedTextRef.current = '';
            dialogueRunningRef.current = false;
            currentTextRef.current = false;
        }
    }, [text, frequencyMultiplier, audio]);

    return text ? (
        <div className="dialogue-box-outer-container">
            <div className="dialogue-box-container">
                <div className="dialogue-box-inner-container dialogue-box-text">
                    {displayedText}
                </div>
                {error && <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 16}}>
                    <div className="dialogue-box-text dialogue-box-error">{error}</div>
                </div>}
                {renderContent ? renderContent() : <button onClick={() => onContinue()} className="dialogue-box-continue-button"></button>}
            </div>
        </div>
    ) : null;
}

export default DialogueBox;
