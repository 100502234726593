import React, { useEffect, useState } from 'react';
// import { isMobile } from 'react-device-detect';
import './Gallery.css';
import MintHyalikoAvatar from './MintHyalikoAvatar';

function MintHyalikoAvatarIntro() {
    const [gameState, setGameState] = useState(0);

    const startGame = () => {
        setGameState(gameState + 1);
        const mintingNode = document.getElementById('minting1') as HTMLAudioElement;
        if (mintingNode) {
            mintingNode.loop = true;
            mintingNode.play();
        }
    }

    // Header state
    const [ready, setReady] = useState(false);

    const [slideCount, setSlideCount] = useState(0);

    useEffect(() => {
        for (let i = 1; i < 6; i++) {
            setTimeout(() => {
                setSlideCount(i);
            }, 250 * (i - 1));
        }

        // Prevent any sort of selection
        document.body.classList.add('no-select');
        const divs = document.getElementsByTagName('div');
        for (let i = 0; i < divs.length; i++) {
            divs[i].classList.add('no-select');
        }

        return () => {
            // Remove select limits
            document.body.classList.remove('no-select');
            const divs = document.getElementsByTagName('div');
            for (let i = 0; i < divs.length; i++) {
                divs[i].classList.remove('no-select');
            }
        };
    }, []);

    let contents = null;
    switch (gameState) {
        case 0:
            contents = (
                <div className="intro" style={{ height: '100vh' }}>
                    <button className="button big-button intro-button opacity-transition" disabled={!ready} onClick={() => startGame()} style={{ opacity: slideCount >= 1 ? undefined : 0, transform: slideCount >= 1 ? 'translate(0, 0)' : 'translate(0, -10px)' }}>begin</button>
                    <a href="/mint-simple" className="opacity-transition" style={{ opacity: (slideCount >= 2 && ready) ? undefined : 0, textAlign: 'center', transform: slideCount >= 2 ? 'translate(0, 0)' : 'translate(0, -10px)',  marginTop: 16 , textDecoration: 'underline' }}>or <b>skip 3d experience</b> (not recommended)</a>
                </div>
            );
            break;
        case 1:
            // TODO this will be dialogue container
            if (false) {
                // contents = (
                //     <div>
                //     </div>
                // );
            }
            break;
        default:
            break;
    }

    return (
        <>
            <div>
                {contents}
            </div>
            <audio src="/sounds/minting-1.mp3" id="minting1" loop />
            <MintHyalikoAvatar gameState={gameState} setReady={setReady} />
        </>
    );
}

export default MintHyalikoAvatarIntro;
