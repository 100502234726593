import React, { useContext, useEffect, useRef, useState } from 'react';
import './BabylonContainer.css';
import * as BABYLON from 'babylonjs';
import 'babylonjs-loaders';
import 'pepjs';
import { isMobile } from 'react-device-detect';
import './index.css';
import { colors, BABYLON_COLORS, LIGHT_PINK } from './Colors';
import nipplejs from 'nipplejs';
import Web3 from 'web3';
import { avatarHexToBackground, HYALIKO_ABI, HYALIKO_ADDRESS, HYALIKO_COLLECTION_INFORMATION_URL, HYALIKO_MERKLE_PROOFS_URL, PARTICLE_SHAPE_FUNCTIONS } from './constants';
import DialogueBox from './DialogueBox';
import { Web3ModalContext, Web3AddressContext } from './Web3Context';

const ERRORS = [
    'This can only be done after the project has been published for airdrop.',
    'This can only be done after the project has been published for whitelist.',
    'This can only be done after the project has been published.',
    'Ineligible to mint.',
    'Hyaliko cost 0.02 ETH each.',
    'Mint quantity exceeds maximum allowed.',
    'Sold out.',
    'Already claimed.',
    'Wrong merkle proof. Unlisted address or wrong quantity.'
];

const PBRMaterialToStandardMaterial = (pbrMaterial: BABYLON.PBRMaterial, scene: BABYLON.Scene) => {
    const newMaterial = new BABYLON.StandardMaterial(pbrMaterial.name, scene);
    newMaterial.diffuseColor = pbrMaterial.albedoColor;
    newMaterial.diffuseTexture = pbrMaterial.albedoTexture;
    newMaterial.ambientColor = BABYLON.Color3.Black();
    newMaterial.emissiveColor = pbrMaterial.emissiveColor;
    newMaterial.useAlphaFromDiffuseTexture = true;
    newMaterial.alpha = pbrMaterial.alpha;
    newMaterial.alphaMode = pbrMaterial.alphaMode;
    return newMaterial;
}

const INTRO_CAMERA_INITIAL_POSITION = new BABYLON.Vector3(0, 1000, -2000);
const INTRO_CAMERA_FINAL_POSITION = new BABYLON.Vector3(0, 500, -500)
const INTRO_CAMERA_INITIAL_TARGET = new BABYLON.Vector3(0, 500, 1500);
const INTRO_CAMERA_FINAL_TARGET = new BABYLON.Vector3(0, 0, 1500);

const INTRO_CAMERA_EASING = new BABYLON.QuinticEase();
INTRO_CAMERA_EASING.setEasingMode(BABYLON.QuinticEase.EASINGMODE_EASEINOUT);

const AVATAR_FLY_IN_EASE = new BABYLON.SineEase();
AVATAR_FLY_IN_EASE.setEasingMode(BABYLON.SineEase.EASINGMODE_EASEINOUT);

// const INTRO_ROTATE_EASING = new BABYLON.CubicEase();
// INTRO_ROTATE_EASING.setEasingMode(BABYLON.CubicEase.EASINGMODE_EASEINOUT);

const HYALIKO_MASTER_STARTING_POSITION = new BABYLON.Vector3(30, 210, 1350);
const PEDASTAL_LOCATION = new BABYLON.Vector3(0, 220, 1390);

const STATES = {
    // Intro camera
    INTRO: 0,
    // Free to move about space and engage in dialogue
    FREE: 1,
    // Minting hyaliko avatars
    MINTING: 2
}

const MULTIPLAYER_ENABLED = true;

const EASE = new BABYLON.QuadraticEase();
EASE.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);

// const IS_MOBILE_APP = window.navigator.userAgent.includes('HyalikoApp');
// const IS_MOBILE_APP = false;

let DEBUG: boolean;
DEBUG = true;
DEBUG = false;

const HIGH_SPEED = false;

let hasAmmoLoaded = false;

const playerMaterials: { [key: string]: BABYLON.StandardMaterial } = {};

const IS_MOBILE = window.innerWidth < 720;

const CAMERA_OFFSET = new BABYLON.Vector3(0, IS_MOBILE ? 10 : 12, IS_MOBILE ? -20 : -25);
const HEIGHT_OFFSET = new BABYLON.Vector3(0, IS_MOBILE ? 9 : 3.75, 0);
const CAMERA_INITIAL_ALPHA = -Math.PI / 2;
const CAMERA_INITIAL_BETA = (IS_MOBILE ? 1.8 : 1.6) * Math.PI / 4;

const MAX_ZOOM = 50;
// const DEFAULT_ZOOM = 25;
const MIN_ZOOM = 1;

// Cinematic
// const CAMERA_OFFSET = new BABYLON.Vector3(0, 2, -10);
// const HEIGHT_OFFSET = new BABYLON.Vector3(0, 0, 0);

const MAX_PLAYER_SPEED = HIGH_SPEED ? 108 : 35;
const MAX_PLAYER_VERTICAL_SPEED = HIGH_SPEED ? 1000 : 18;
const PLAYER_ACCELERATION = 1.5;
const PLAYER_STARTING_POSITION = new BABYLON.Vector3(0, 8, 15);
// const PLAYER_STARTING_POSITION = HYALIKO_MASTER_STARTING_POSITION.add(new BABYLON.Vector3(0, 0, -100));

const DASH_ANIMATION_LENGTH = 100;
const DASH_POWER = 100;

// const PAGE_SIZE = 5;

const WEBSOCKET_URL = 'wss://www.server.oatsinteractive.net:443';

// These should definitely be refs
let colorToMaterial: { [key: string]: BABYLON.Material } = {};
let indexToParticleTexture: { [key: string]: BABYLON.Texture } = {};

function getMaterialForColor(scene: BABYLON.Scene, color: string) {
    if (!colorToMaterial[color]) {
        const material = new BABYLON.StandardMaterial(color, scene);
        material.ambientColor = BABYLON.Color3.FromHexString(color);
        material.alpha = 1;
        material.alphaMode = BABYLON.Material.MATERIAL_ALPHABLEND;
        colorToMaterial[color] = material;
    }
    return colorToMaterial[color];
}

function getParticleTexture(scene: BABYLON.Scene, shape: number, color: string) {
    const key = shape + color;

    if (!indexToParticleTexture[shape]) {
        const particleTexture = BABYLON.Texture.LoadFromDataString('particleTexture' + key, PARTICLE_SHAPE_FUNCTIONS[shape](color), scene, true, true, true, BABYLON.Texture.NEAREST_SAMPLINGMODE);
        particleTexture.hasAlpha = true;
        indexToParticleTexture[key] = particleTexture;
    }
    return indexToParticleTexture[key];
}

// const createCanvas = (scene: BABYLON.Scene, isBig: boolean) => {

// };

const addLevelSegment = async function (scene: BABYLON.Scene, babylonMemory: BabylonMemory) {
    const initMesh = (mesh: BABYLON.AbstractMesh) => {
        mesh.parent = null;
        // groundMaterial.alpha = 0.5;
        mesh.isPickable = false;
        if (!mesh.name.includes('NoPhysics')) {
            const levelPhysics = new BABYLON.PhysicsImpostor(mesh, BABYLON.PhysicsImpostor.MeshImpostor, { mass: 0, restitution: 0, friction: 0 }, scene);
            mesh.physicsImpostor = levelPhysics;
            mesh.isPickable = true;
        }
        mesh.cullingStrategy = BABYLON.AbstractMesh.CULLINGSTRATEGY_BOUNDINGSPHERE_ONLY;
    };
    const assetContainer = await BABYLON.SceneLoader.LoadAssetContainerAsync('/models/', 'temple.4.5.obj', scene);
    const meshes = assetContainer.meshes;
    meshes.forEach(mesh => {
        BABYLON.Tags.AddTagsTo(mesh, "level");
        initMesh(mesh);
        const wallMaterial = new BABYLON.StandardMaterial('wallMaterial', scene);
        wallMaterial.diffuseColor = BABYLON_COLORS.BLACK;
        wallMaterial.ambientColor = BABYLON_COLORS.BLACK;
        wallMaterial.emissiveColor = BABYLON_COLORS.BLACK;
        const pedastalMaterial = new BABYLON.StandardMaterial('pedastalMaterial', scene);
        pedastalMaterial.diffuseColor = BABYLON_COLORS.BLACK;
        pedastalMaterial.ambientColor = BABYLON_COLORS.BLACK;
        pedastalMaterial.emissiveColor = BABYLON_COLORS.BLACK;
        if (mesh.name.startsWith('floor') || mesh.name.startsWith('walls') || mesh.name.startsWith('pedastal_base') || mesh.name.startsWith('pedastal_center') || mesh.name.startsWith('ring')) {
            mesh.material = wallMaterial
        } else if (false) {
            mesh.material = pedastalMaterial;
        } else {
            mesh.material = getMaterialForColor(scene, colors.PINK);
        }
    });
    assetContainer.addAllToScene();

    const orbitContainer = await BABYLON.SceneLoader.LoadAssetContainerAsync('/models/', 'orbit.glb', scene);
    const orbitMaterial = new BABYLON.StandardMaterial('orbitMaterial', scene);
    orbitMaterial.diffuseColor = BABYLON_COLORS.PINK;
    orbitMaterial.emissiveColor = BABYLON_COLORS.PINK;
    orbitMaterial.ambientColor = BABYLON_COLORS.PINK;
    orbitMaterial.alphaMode = BABYLON.Material.MATERIAL_ALPHABLEND;
    orbitMaterial.alpha = 0.5;
    const interiorOrbitMaterial = orbitMaterial.clone('interiorOrbitMaterial');
    interiorOrbitMaterial.diffuseColor = BABYLON_COLORS.WHITE;
    interiorOrbitMaterial.emissiveColor = BABYLON_COLORS.WHITE;
    interiorOrbitMaterial.ambientColor = BABYLON_COLORS.WHITE;
    orbitContainer.meshes.forEach(mesh => {
        if (mesh.name.startsWith('interior')) {
            mesh.material = interiorOrbitMaterial;
        } else {
            mesh.material = orbitMaterial;
        }
    });
    orbitContainer.addAllToScene();
    orbitContainer.animationGroups.forEach(animationGroup => animationGroup.play(true));
}

const createPlayer = function (scene: BABYLON.Scene, isStatic: boolean, id?: string) {
    return BABYLON.SceneLoader.LoadAssetContainerAsync("/models/", 'default-hyaliko.gltf', scene).then(async (result) => {
        result.meshes.forEach(mesh => {
            if (mesh.material) {
                // Convert default GLTF PBR materials to standard materials for performance
                mesh.material = PBRMaterialToStandardMaterial(mesh.material as BABYLON.PBRMaterial, scene);
            }
        });

        // TODO maybe make sure gltf has these parented properly
        let playerMesh = result.meshes[2];

        result.meshes.forEach(mesh => BABYLON.Tags.AddTagsTo(mesh, 'player'));

        playerMesh.parent = null;
        playerMesh.name = `player${id || ''}`;

        // Make sure face is a child of body mesh
        result.meshes[2].addChild(result.meshes[1]);

        playerMesh.lookAt(playerMesh.forward.scale(-1));

        if (!isStatic) {
            const playerTrigger = BABYLON.MeshBuilder.CreateBox('playerTrigger', { width: 7.5, height: 7.5, depth: 7.5 });
            playerTrigger.isVisible = false;
            playerTrigger.isPickable = false;
            playerMesh.addChild(playerTrigger);
        }

        // Particle system
        const particles = new BABYLON.ParticleSystem('particles', 200, scene);
        particles.maxScaleX = 0.5;
        particles.maxScaleY = 0.5;
        particles.minScaleX = 0.5;
        particles.minScaleY = 0.5;
        particles.particleTexture = new BABYLON.Texture('/textures/Flare.png', scene);
        particles.color1 = BABYLON_COLORS.WHITE.toColor4();
        particles.color2 = BABYLON_COLORS.WHITE.toColor4();
        particles.emitRate *= 10;
        particles.maxLifeTime /= 100;
        particles.emitter = playerMesh;
        particles.createSphereEmitter(0.1, 1);
        particles.start();

        const emoteParticles = new BABYLON.ParticleSystem(`emoteParticles${id || ''}`, 200, scene);
        emoteParticles.maxScaleX = 1.5;
        emoteParticles.maxScaleY = 1.5;
        emoteParticles.minScaleX = 1.5;
        emoteParticles.minScaleY = 1.5;
        emoteParticles.minEmitPower = 12;
        emoteParticles.maxEmitPower = 10;
        emoteParticles.particleTexture = new BABYLON.Texture('/textures/Heart.png', scene);
        emoteParticles.color1 = BABYLON.Color3.White().toColor4(1);
        emoteParticles.color2 = emoteParticles.color1;
        emoteParticles.emitRate *= 10;
        emoteParticles.targetStopDuration = 0.1;
        emoteParticles.emitter = playerMesh;
        emoteParticles.createSphereEmitter(0.1, 1);
        emoteParticles.start();

        const spotLight = new BABYLON.SpotLight("spot", new BABYLON.Vector3(0, -1, 0), BABYLON.Vector3.UpReadOnly.scale(-1), Math.PI, 1000, scene);
        spotLight.exponent = 16;
        spotLight.range = 100;
        spotLight.parent = playerMesh;

        const playerScale = 1.25;
        playerMesh.scaling.x *= playerScale;
        playerMesh.scaling.y *= playerScale;
        playerMesh.scaling.z *= playerScale;

        playerMesh.position = PLAYER_STARTING_POSITION.clone();

        if (!isStatic) {
            const tempScaling = playerMesh.scaling.z;
            playerMesh.scaling.z = 1;
            let playerPhysics;
            playerPhysics = new BABYLON.PhysicsImpostor(playerMesh, BABYLON.PhysicsImpostor.CylinderImpostor, { mass: 1, restitution: 0, friction: 0 }, scene);
            playerMesh.physicsImpostor = playerPhysics;
            playerMesh.scaling.z = tempScaling;
        }

        result.addAllToScene();

        playerMesh.isPickable = true;
        // TODO make pickable for emotes
        // if (playerFace) {
        //     playerFace.isPickable = true;
        // }

        return { playerMesh };
    });
};

// CreateScene function that creates and return the scene
const createScene = function (engine: BABYLON.Engine, babylonMemory: BabylonMemory) {
    // Create a basic BJS Scene object
    const scene = new BABYLON.Scene(engine);

    const gravityVector = new BABYLON.Vector3(0, -9.81 * 2, 0);

    const camera = new BABYLON.ArcRotateCamera('playerCamera', CAMERA_INITIAL_ALPHA, CAMERA_INITIAL_BETA, IS_MOBILE ? 20 : 25, PLAYER_STARTING_POSITION.add(CAMERA_OFFSET), scene);
    camera.inputs.addMouseWheel();
    camera.attachControl();
    camera.inputs.remove(camera.inputs.attached.pointers);
    camera.inputs.remove(camera.inputs.attached.keyboard);
    camera.lowerRadiusLimit = MIN_ZOOM;
    camera.upperRadiusLimit = MAX_ZOOM;
    new BABYLON.PassPostProcess("resolution", 0.4, camera);
    camera.fov = IS_MOBILE ? 1.6 : 1.2;
    camera.maxZ = 4000;

    // Intro camera
    const introCamera = new BABYLON.ArcRotateCamera('introCamera', CAMERA_INITIAL_ALPHA, CAMERA_INITIAL_BETA, IS_MOBILE ? 20 : 25, INTRO_CAMERA_INITIAL_TARGET, scene);
    introCamera.position = INTRO_CAMERA_INITIAL_POSITION;
    introCamera.inputs.remove(introCamera.inputs.attached.pointers);
    introCamera.inputs.remove(introCamera.inputs.attached.keyboard);
    new BABYLON.PassPostProcess("resolution", 0.4, introCamera);
    introCamera.fov = IS_MOBILE ? 1.6 : 1.2;
    introCamera.maxZ = 4000;
    introCamera.alpha = 3 * Math.PI / 2
    scene.activeCamera = introCamera;

    // Ambient particle system
    const particles = new BABYLON.ParticleSystem('ambientParticles', 2000, scene);
    // TODO maybe bad for performance
    particles.emitRate *= 160;
    particles.maxScaleX = 1;
    particles.maxScaleY = 1;
    particles.minScaleX = 1;
    particles.minScaleY = 1;
    particles.minEmitPower = 10;
    particles.particleTexture = getParticleTexture(scene, 0, '#ffffff');
    particles.blendMode = BABYLON.ParticleSystem.BLENDMODE_MULTIPLYADD;
    particles.color1 = BABYLON.Color3.White().toColor4(1);
    particles.color2 = BABYLON.Color3.White().toColor4(1);
    particles.colorDead = new BABYLON.Color4(1, 1, 1, 0);
    particles.emitter = scene.getCameraByName('playerCamera').position;
    const emitterMin = new BABYLON.Vector3(-1, -1, -1).scale(200);
    const emitterMax = new BABYLON.Vector3(1, 1, 1).scale(200);
    // Offset scales by player scale
    // emitterMin.x /= playerMesh.scaling.x;
    // emitterMin.y /= playerMesh.scaling.y;
    // emitterMin.z /= playerMesh.scaling.z;
    // emitterMax.x /= playerMesh.scaling.x;
    // emitterMax.y /= playerMesh.scaling.y;
    // emitterMax.z /= playerMesh.scaling.z;
    particles.createBoxEmitter(BABYLON.Vector3.UpReadOnly, BABYLON.Vector3.UpReadOnly, emitterMin, emitterMax);
    particles.start();
    babylonMemory.ambientParticles = particles;

    const inspectCamera = new BABYLON.FreeCamera('inspectCamera', camera.position.clone());
    new BABYLON.PassPostProcess("resolution", 0.4, inspectCamera);
    inspectCamera.fov = camera.fov;
    inspectCamera.maxZ = 500;

    // @ts-ignore
    return (hasAmmoLoaded ? Promise.resolve() : window.Ammo()).then(() => {
        hasAmmoLoaded = true;
        const physicsPlugin = new BABYLON.AmmoJSPlugin(false);
        scene.enablePhysics(gravityVector, physicsPlugin);

        // const options = new BABYLON.SceneOptimizerOptions();
        // options.addOptimization(new BABYLON.HardwareScalingOptimization(0, 1));

        // // Optimizer
        // const optimizer = new BABYLON.SceneOptimizer(scene, options);


        const light = new BABYLON.DirectionalLight('light1', new BABYLON.Vector3(-1, -1, 0), scene);
        light.intensity = 0.5;
        scene.ambientColor = BABYLON_COLORS.PINK;
        scene.clearColor = LIGHT_PINK;
        scene.fogEnabled = true;
        scene.fogMode = BABYLON.Scene.FOGMODE_EXP;
        scene.fogColor = BABYLON.Color3.White();
        scene.fogDensity = 0.001;

        return addLevelSegment(scene, babylonMemory).then(() => scene);
    });
}

// async function loadPage(page: number, scene: BABYLON.Scene, onZoomAreaEnter: (mesh: BABYLON.AbstractMesh) => void, onZoomAreaExit: () => void, address: string, canvasIndex: number) {
//     return fetch(`${NFT_SERVER_URL}?addresses=${address}&page=${page}&pageSize=${PAGE_SIZE}`)
//         .then(res => res.json())
//         .then(json => addNFTsToScene(json.nfts || [], scene, canvasIndex, onZoomAreaEnter, onZoomAreaExit))
// }

type BabylonContainerProps = {
    setReady: (value: boolean) => void,
    gameState: number
};

type BabylonMemory = {
    cameraTarget: BABYLON.Vector3,
    cameraPosition: BABYLON.Vector3,
    dialogueAudioLoaded: boolean,
    state: number,
    ambientParticles: BABYLON.ParticleSystem,
    mintingParticles: BABYLON.ParticleSystem,
    faceTextures: BABYLON.Texture[],
    cameraAnimationTimer: number
}
// Use this for general global references
const babylonMemoryDefault: BabylonMemory = {
    cameraTarget: null,
    cameraPosition: null,
    dialogueAudioLoaded: false,
    state: STATES.INTRO,
    ambientParticles: null,
    mintingParticles: null,
    faceTextures: [],
    cameraAnimationTimer: 0
};

// Preload textures and bodies
async function preloadEnvironment(scene: BABYLON.Scene, babylonMemory: BabylonMemory) {
    // Minting particles
    // Ambient particle system
    const particles = new BABYLON.ParticleSystem('mintingParticles', 2000, scene);
    // TODO maybe bad for performance
    particles.emitRate = 0;
    particles.maxScaleX = 1;
    particles.maxScaleY = 1;
    particles.minScaleX = 1;
    particles.minScaleY = 1;
    particles.minEmitPower = 1;
    particles.maxEmitPower = 1;
    particles.particleTexture = getParticleTexture(scene, 0, '#ffffff');
    particles.blendMode = BABYLON.ParticleSystem.BLENDMODE_MULTIPLYADD;
    particles.color1 = BABYLON.Color3.White().toColor4(1);
    particles.color2 = BABYLON.Color3.White().toColor4(1);
    particles.colorDead = new BABYLON.Color4(1, 1, 1, 0);
    particles.minLifeTime = 0.75;
    particles.maxLifeTime = 1;
    const emitterMin = new BABYLON.Vector3(-1, -1, -1).scale(10);
    const emitterMax = new BABYLON.Vector3(1, 1, 1).scale(10);
    // Offset scales by player scale
    // emitterMin.x /= playerMesh.scaling.x;
    // emitterMin.y /= playerMesh.scaling.y;
    // emitterMin.z /= playerMesh.scaling.z;
    // emitterMax.x /= playerMesh.scaling.x;
    // emitterMax.y /= playerMesh.scaling.y;
    // emitterMax.z /= playerMesh.scaling.z;
    particles.createBoxEmitter(BABYLON.Vector3.UpReadOnly, BABYLON.Vector3.UpReadOnly, emitterMin, emitterMax);
    particles.start();
    babylonMemory.mintingParticles = particles;

    // Import animations
    const animationsResult = await BABYLON.SceneLoader.ImportMeshAsync(
        "",
        '/animations/hover-small.glb',
        "",
        scene,
        null,
        '.glb'
    );
    animationsResult.meshes[0].rotationQuaternion = null;
    animationsResult.animationGroups[0].name = 'Hover';
}

async function createEnvironment(scene: BABYLON.Scene, babylonMemory: BabylonMemory, player: BABYLON.AbstractMesh, setDialogueSequence: (sequence: string) => void, setDialogueSequenceIndex: (index: number) => void, setOnTalk: (onTalk: () => void) => void) {
    const startDialogueCameraAnimation = () => {
        babylonMemory.cameraAnimationTimer = 0;
    };

    const hoverAnimation = scene.getAnimationGroupByName('Hover');

    // Add master hyaliko
    const assetContainer = await BABYLON.SceneLoader.LoadAssetContainerAsync('/models/', 'master-hyaliko.glb', scene);
    assetContainer.meshes.forEach(mesh => {
        if (mesh.material) {
            // Convert default GLTF PBR materials to standard materials for performance
            mesh.material = PBRMaterialToStandardMaterial(mesh.material as BABYLON.PBRMaterial, scene);
        }
    });
    const hyalikoMasterCollider = BABYLON.MeshBuilder.CreateBox('masterPhysics', { height: 14, width: 14, depth: 4 }, scene);
    hyalikoMasterCollider.rotation.y = Math.PI;
    const physics = new BABYLON.PhysicsImpostor(hyalikoMasterCollider, BABYLON.PhysicsImpostor.MeshImpostor, { mass: 0, restitution: 0, friction: 0 }, scene);
    hyalikoMasterCollider.physicsImpostor = physics;
    const gearAnimation = assetContainer.animationGroups[0];
    gearAnimation.pause();
    const hyalikoMasterBody = assetContainer.meshes[0];
    hyalikoMasterBody.name = 'hyalikoMasterBody';
    hyalikoMasterBody.rotationQuaternion.y = 0;
    hyalikoMasterBody.position = hyalikoMasterCollider.position;
    hyalikoMasterCollider.addChild(assetContainer.meshes[0]);
    hyalikoMasterCollider.position = HYALIKO_MASTER_STARTING_POSITION;
    assetContainer.addAllToScene();
    hyalikoMasterCollider.isVisible = false;

    // Particle system
    const particles = new BABYLON.ParticleSystem(`hyalikoMasterParticles`, 200, scene);
    particles.maxScaleX = 0.5;
    particles.maxScaleY = 0.5;
    particles.minScaleX = 0.5;
    particles.minScaleY = 0.5;
    particles.particleTexture = new BABYLON.Texture('/textures/Flare.png', scene);
    particles.color1 = BABYLON_COLORS.WHITE.toColor4();
    particles.color2 = BABYLON_COLORS.WHITE.toColor4();
    particles.emitRate *= 10;
    particles.maxLifeTime /= 100;
    particles.emitter = hyalikoMasterBody;
    particles.createBoxEmitter(BABYLON.Vector3.UpReadOnly, BABYLON.Vector3.UpReadOnly, BABYLON.Vector3.One().scaleInPlace(12), BABYLON.Vector3.One().scaleInPlace(-12));

    // hyaliko master hover animation
    const hyalikoMasterHover = hoverAnimation.clone('masterHyaliko_' + hoverAnimation.name, () => hyalikoMasterBody);
    hyalikoMasterHover.play(true);

    const hyalikoMasterEyes = assetContainer.meshes[assetContainer.meshes.length - 1];

    const onMasterHyalikoTalk = () => {
        const tempOnTalk = onMasterHyalikoTalk;
        setOnTalk(null);
        startDialogueCameraAnimation();
        // Fade volume
        const audio = (document.getElementById('minting1') as HTMLAudioElement);
        const fadeInterval = setInterval(() => {
            if (audio.volume > 0.25) {
                audio.volume -= 0.025
            } else {
                clearInterval(fadeInterval);
            }
        }, 50);
        // Adjust camera
        babylonMemory.cameraTarget = hyalikoMasterCollider.position.clone().add(new BABYLON.Vector3(0, -6, 0));
        babylonMemory.cameraPosition = hyalikoMasterCollider.position.clone().add(new BABYLON.Vector3(0, -5, -25));
        setTimeout(() => {
            BABYLON.Animation.CreateAndStartAnimation('eyes', hyalikoMasterEyes.material, 'alpha', 30, 60, 0, 1, BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE, new BABYLON.QuinticEase(), () => {
                setTimeout(() => {
                    scene.getMeshByName('hyalikoMasterBody').getConnectedParticleSystems()[0].start();
                    gearAnimation.play(true);
                    setTimeout(() => {
                        // Open dialogue
                        setDialogueSequence('MASTER_HYALIKO');
                        setDialogueSequenceIndex(0);
                        setOnTalk(() => tempOnTalk);
                    }, 750);
                }, 1000)
            });
        }, 1000);
    };

    // Add hyaliko dialogue trigger
    const trigger = BABYLON.MeshBuilder.CreateBox('masterHyalikoTrigger', { width: 75, height: 50, depth: 50 }, scene);
    trigger.parent = hyalikoMasterCollider;
    trigger.position = BABYLON.Vector3.Zero();
    trigger.position.x = 0;
    trigger.isVisible = false;
    trigger.isPickable = false;
    trigger.actionManager = new BABYLON.ActionManager();
    trigger.actionManager.registerAction(
        new BABYLON.ExecuteCodeAction(
            {
                trigger: BABYLON.ActionManager.OnIntersectionEnterTrigger,
                parameter: {
                    mesh: player.getChildMeshes()[1],
                    usePreciseIntersection: true
                }
            },
            () => {
                setOnTalk(() => onMasterHyalikoTalk);
            }
        )
    );
    trigger.actionManager.registerAction(
        new BABYLON.ExecuteCodeAction(
            {
                trigger: BABYLON.ActionManager.OnIntersectionExitTrigger,
                parameter: {
                    mesh: player.getChildMeshes()[1],
                    usePreciseIntersection: true
                }
            },
            () => {
                setOnTalk(null);
            }
        )
    );

    // NPCs
    const NPC_LOCATIONS = [
        new BABYLON.Vector3(-50, 97, 421),
        new BABYLON.Vector3(28, 116, 545),
        new BABYLON.Vector3(-20, 169, 892)
    ];
    for (let i = 0; i < 1; i++) {
        BABYLON.SceneLoader.LoadAssetContainerAsync("/models/", 'default-hyaliko.gltf', scene).then(async (result) => {
            // TODO maybe make sure gltf has these parented properly
            const playerMeshParent = result.meshes[0];
            let playerMesh = result.meshes[2];

            playerMesh.parent = playerMeshParent;
            playerMesh.name = `npc${i}`;

            // Make sure face is a child of body mesh
            result.meshes[2].addChild(result.meshes[1]);

            playerMesh.lookAt(playerMesh.forward.scale(-1));


            // Particle system
            const particles = new BABYLON.ParticleSystem(`npcParticles${i}`, 200, scene);
            particles.maxScaleX = 0.5;
            particles.maxScaleY = 0.5;
            particles.minScaleX = 0.5;
            particles.minScaleY = 0.5;
            particles.particleTexture = new BABYLON.Texture('/textures/Flare.png', scene);
            particles.color1 = BABYLON_COLORS.WHITE.toColor4();
            particles.color2 = BABYLON_COLORS.WHITE.toColor4();
            particles.emitRate *= 10;
            particles.maxLifeTime /= 100;
            particles.emitter = playerMesh;
            particles.createSphereEmitter(0.1, 1);
            particles.start();

            const playerScale = 1.75;
            playerMeshParent.scaling.x *= playerScale;
            playerMeshParent.scaling.y *= playerScale;
            playerMeshParent.scaling.z *= playerScale;

            // TODO get npc positions
            playerMeshParent.position = NPC_LOCATIONS[i];

            result.meshes.forEach(mesh => {
                if (mesh.material) {
                    // Convert default GLTF PBR materials to standard materials for performance
                    mesh.material = PBRMaterialToStandardMaterial(mesh.material as BABYLON.PBRMaterial, scene);
                }
            });

            // Add hyaliko dialogue trigger
            const onNPCTalk = () => {
                startDialogueCameraAnimation();
                // Fade volume
                const audio = (document.getElementById('minting1') as HTMLAudioElement);
                const fadeInterval = setInterval(() => {
                    if (audio.volume > 0.25) {
                        audio.volume -= 0.025
                    } else {
                        clearInterval(fadeInterval);
                    }
                }, 50);
                // Adjust camera
                babylonMemory.cameraTarget = playerMeshParent.position.clone();
                babylonMemory.cameraPosition = playerMeshParent.position.clone().add(new BABYLON.Vector3(0, 0, -10));
                setTimeout(() => {
                    // Open dialogue
                    setDialogueSequence(`NPC_${i}`);
                    setDialogueSequenceIndex(0);
                }, 500);
            };
            const trigger = BABYLON.MeshBuilder.CreateBox(`npc${i}Trigger`, { width: 20, height: 20, depth: 20 }, scene);
            trigger.parent = playerMeshParent;
            trigger.position = BABYLON.Vector3.Zero();
            trigger.isVisible = false;
            trigger.isPickable = false;
            trigger.actionManager = new BABYLON.ActionManager();
            trigger.actionManager.registerAction(
                new BABYLON.ExecuteCodeAction(
                    {
                        trigger: BABYLON.ActionManager.OnIntersectionEnterTrigger,
                        parameter: {
                            mesh: player.getChildMeshes()[1],
                            usePreciseIntersection: true
                        }
                    },
                    () => {
                        setOnTalk(() => onNPCTalk);
                    }
                )
            );

            trigger.actionManager.registerAction(
                new BABYLON.ExecuteCodeAction(
                    {
                        trigger: BABYLON.ActionManager.OnIntersectionExitTrigger,
                        parameter: {
                            mesh: player.getChildMeshes()[1],
                            usePreciseIntersection: true
                        }
                    },
                    () => {
                        setOnTalk(null);
                    }
                )
            );

            const npcHover = hoverAnimation.clone(`NPC_${i}` + hoverAnimation.name, () => playerMesh);
            npcHover.play(true);

            result.addAllToScene();
        });
    }
}

function MintHyalikoAvatar(props: BabylonContainerProps) {
    const { setReady, gameState } = props;
    const [dialogueAudio, setDialogueAudio] = useState(null);
    // const [muted, setMuted] = useState(false);
    const [dialogueSequence, setDialogueSequence] = useState(null);
    const [dialogueSequenceIndex, setDialogueSequenceIndex] = useState(null);
    const [publicationStatus, setPublicationStatus] = useState(null);

    const [merkleProofs, setMerkleProofs] = useState(null);
    // const { web3Address, web3ENS } = useContext(Web3AddressContext);
    // const { authenticateWithModal } = useContext(AuthenticationModalContext);
    // const { openErrorModal, closeErrorModal } = useContext(ErrorModalContext);

    // useEffect(() => {
    //     if (addressesString === 'test') {
    //         DEBUG = true;
    //     }
    // }, [addressesString]);

    const sceneReference: React.MutableRefObject<BABYLON.Scene> = useRef(null);
    const webSocketReference = useRef(null);

    const { connectWeb3, web3 } = useContext(Web3ModalContext);
    const { web3Address, web3ENS } = useContext(Web3AddressContext);
    const displayWeb3Address = web3ENS || web3Address;

    // CONTRACT STUFF
    const [contract, setContract] = useState(null);
    const [airdropError, setAirdropError] = useState(null);
    const [whitelistError, setWhitelistError] = useState(null);
    const [publicSaleError, setPublicSaleError] = useState(null);
    const [rawNumberToMint, setNumberToMint] = useState('');
    const numberToMint = rawNumberToMint ? parseInt(rawNumberToMint) : 0;
    const [loading, setLoading] = useState(false);
    const [awaitingApproval, setAwaitingApproval] = useState(false);
    const [awaitingTransaction, setAwaitingTransaction] = useState(false);
    const [awaitingModels, setAwaitingModels] = useState(false);
    const awaitingTransactionRef = useRef(false);
    const [onTalk, setOnTalk] = useState(null);

    const formattedAirdropError = airdropError !== null ? (isNaN(airdropError) ? 'Insufficient funds or other unknown error.' : ERRORS[airdropError]) : null;
    const formattedWhitelistError = whitelistError !== null ? (isNaN(whitelistError) ? 'Insufficient funds or other unknown error.' : ERRORS[whitelistError]) : null;
    const formattedPublicSaleError = publicSaleError !== null ? (isNaN(publicSaleError) ? 'Insufficient funds or other unknown error.' : ERRORS[publicSaleError]) : null;

    useEffect(() => {
        if (web3 && web3Address) {
            if (web3 && web3Address && dialogueSequence === 'MASTER_HYALIKO' && dialogueSequenceIndex !== 9) {
                // TO CONNECT SCREEN
                fetch(`${HYALIKO_MERKLE_PROOFS_URL}?address=${web3Address}`).then(res => res.json()).then(({ merkleProofs}) => {
                    if (merkleProofs) {
                        setMerkleProofs(merkleProofs);
                    }
                }); 
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [web3, web3Address])

    // check minting publication status, initialize contract
    useEffect(() => {
        fetch(HYALIKO_COLLECTION_INFORMATION_URL).then(res => res.json()).then(json => {
            setPublicationStatus(json);
        });
    }, []);

    // if anything changes, check if there are errors
    useEffect(() => {
        if (web3 && web3Address && contract) {
            // TODO do the same for public sale and whitelist
            if (merkleProofs?.airdrop?.merkleProof) {
                contract.methods.mintFromAirdrop(merkleProofs?.airdrop?.quantity, merkleProofs?.airdrop?.merkleProof).estimateGas({
                    maxPriorityFeePerGas: null,
                    maxFeePerGas: null,
                    from: web3Address
                })
                    .then(() => setAirdropError(null))
                    .catch((e: any) => {
                        console.error(e);
                        console.log(parseInt(e.message.substring('execution reverted: b:0'.length, 'execution reverted: b:0'.length + 1)) - 1);
                        setAirdropError(parseInt(e.message.substring('execution reverted: b:0'.length, 'execution reverted: b:0'.length + 1)) - 1);
                    });
            }

            if (merkleProofs?.whitelist?.merkleProof) {
                contract.methods.mintFromWhitelist(merkleProofs?.whitelist?.merkleProof).estimateGas({
                    maxPriorityFeePerGas: null,
                    maxFeePerGas: null,
                    from: web3Address
                })
                    .then(() => setWhitelistError(null))
                    .catch((e: any) => {
                        console.error(e);
                        console.log(parseInt(e.message.substring('execution reverted: b:0'.length, 'execution reverted: b:0'.length + 1)) - 1);
                        setWhitelistError(parseInt(e.message.substring('execution reverted: b:0'.length, 'execution reverted: b:0'.length + 1)) - 1);
                    });
            }

            if (numberToMint) {
                const weiValue = Web3.utils.toWei(`${0.02 * numberToMint}`);
                contract.methods.mintFromSale(numberToMint).estimateGas({
                    maxPriorityFeePerGas: null,
                    maxFeePerGas: null,
                    from: web3Address,
                    value: weiValue
                })
                    .then(() => setPublicSaleError(null))
                    .catch((e: any) => {
                        console.error(e);
                        console.log(parseInt(e.message.substring('execution reverted: b:0'.length, 'execution reverted: b:0'.length + 1)) - 1);
                        setPublicSaleError(parseInt(e.message.substring('execution reverted: b:0'.length, 'execution reverted: b:0'.length + 1)) - 1);
                    });
            }

        }
    }, [web3, web3Address, merkleProofs, contract, numberToMint])

    useEffect(() => {
        if (web3) {
            setContract(new web3.eth.Contract(HYALIKO_ABI, HYALIKO_ADDRESS));
        }
    }, [web3, web3Address]);

    const exitDialogue = () => {
        // exit dialogue
        setDialogueSequence(null);
        setDialogueSequenceIndex(null);
        const audio = (document.getElementById('minting1') as HTMLAudioElement);
        const fadeInterval = setInterval(() => {
            if (audio.volume < 1) {
                audio.volume += 0.025
            } else {
                clearInterval(fadeInterval);
            }
        }, 50);
        babylonMemoryRef.current.cameraTarget = null;
        babylonMemoryRef.current.cameraPosition = null;
    }
    const defaultOnContinue = () => {
        const nextIndex = dialogueSequenceIndex + 1;
        if (nextIndex === DIALOGUE_SEQUENCES[dialogueSequence].pages.length) {
            exitDialogue();
        } else {
            setDialogueSequenceIndex(nextIndex);
        }
    };

    type DialoguePage = {
        text: string,
        onContinue?: () => void,
        renderContent?: () => any,
        error?: string
    }

    type DialogueSequence = {
        pages: DialoguePage[],
        frequencyMultiplier: number
    };

    const startMintingAnimation = (tokenIds: number[]) => {
        const mintingSpeed = tokenIds.length < 6 ? 1 : tokenIds.length < 20 ? 2 : tokenIds.length < 100 ? 3 : 3;
        exitDialogue();
        const tempOnTalk = onTalk;
        setOnTalk(null);
        const scene = sceneReference.current;
        // Initiate animation sequence
        const pedastalLocation = PEDASTAL_LOCATION;
        babylonMemoryRef.current.cameraAnimationTimer = 0;
        babylonMemoryRef.current.cameraTarget = pedastalLocation;
        // TODO animate this
        babylonMemoryRef.current.cameraPosition = pedastalLocation.add(new BABYLON.Vector3(0, 0, -15));

        const ambientColorInterval = setInterval(() => {
            BABYLON.Color3.LerpToRef(scene.ambientColor, BABYLON.Color3.Black(), 0.01, scene.ambientColor);
        }, 17);
        setTimeout(() => {
            clearInterval(ambientColorInterval);
        }, 6000);

        setTimeout(() => {
            const onEnd = () => {
                const ambientColorInterval = setInterval(() => {
                    BABYLON.Color3.LerpToRef(scene.ambientColor, BABYLON_COLORS.PINK, 0.001, scene.ambientColor);
                }, 17);
                setTimeout(() => {
                    clearInterval(ambientColorInterval);
                }, 6000);
                const colorInterval = setInterval(() => {
                    BABYLON.Color3.LerpToRef(backgroundMaterial.diffuseColor, BABYLON.Color3.Black(), 0.005, backgroundMaterial.diffuseColor);
                    BABYLON.Color3.LerpToRef(backgroundMaterial.emissiveColor, BABYLON.Color3.Black(), 0.005, backgroundMaterial.emissiveColor);
                    BABYLON.Color3.LerpToRef(backgroundMaterial.specularColor, BABYLON.Color3.Black(), 0.005, backgroundMaterial.specularColor);
                }, 17);
                setTimeout(() => {
                    clearInterval(colorInterval);
                }, 6000);
                babylonMemoryRef.current.cameraTarget = null;
                babylonMemoryRef.current.cameraPosition = null;

                setDialogueSequence('MASTER_HYALIKO');
                setDialogueSequenceIndex(DIALOGUE_SEQUENCES['MASTER_HYALIKO'].pages.length - 1);
                setOnTalk(() => tempOnTalk);
            }

            const backgroundMaterial = scene.getMaterialByName('wallMaterial') as BABYLON.StandardMaterial;

            const nextBody = (i: number) => {
                if (i >= tokenIds.length) {
                    onEnd();
                    return;
                }
                // TODO import all meshes?
                const file = `https://hyaliko-avatar-models.s3.amazonaws.com/${tokenIds[i]}`;
                BABYLON.SceneLoader.ImportMeshAsync(
                    "",
                    file,
                    "",
                    scene,
                    null,
                    '.gltf'
                ).then(result => {
                    result.meshes[0].name = `body${tokenIds[i]}`;
                    result.meshes[0].position.x = -10000;
                    const faceMaterial = PBRMaterialToStandardMaterial((result.meshes[1].material as BABYLON.PBRMaterial), scene)
                    result.meshes[1].material = faceMaterial;
                    const bodyMaterial = PBRMaterialToStandardMaterial((result.meshes[2].material as BABYLON.PBRMaterial), scene);
                    result.meshes[2].material = bodyMaterial;

                    const body = result.meshes[0];

                    const originalScaling = body.scaling.clone();
                    const finalScaling = body.scaling.scale(4);
                    body.scaling = BABYLON.Vector3.Zero();
                    body.position = pedastalLocation.clone();
                    body.position.y -= 10;
                    babylonMemoryRef.current.mintingParticles.emitter = body;
                    const flyEase = new BABYLON.BackEase(0.1);
                    flyEase.setEasingMode(BABYLON.BackEase.EASINGMODE_EASEINOUT);
                    BABYLON.Animation.CreateAndStartAnimation('avatarIntroFly', body, 'position.y', 60, 90 / mintingSpeed, body.position.y, pedastalLocation.y, BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE, AVATAR_FLY_IN_EASE);
                    setTimeout(() => {
                        BABYLON.Animation.CreateAndStartAnimation('avatarMintingParticles', babylonMemoryRef.current.mintingParticles, 'minScaleX', 60, 90 / mintingSpeed, 0, 4, BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE, AVATAR_FLY_IN_EASE);
                        BABYLON.Animation.CreateAndStartAnimation('avatarMintingParticles', babylonMemoryRef.current.mintingParticles, 'minScaleY', 60, 90 / mintingSpeed, 0, 4, BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE, AVATAR_FLY_IN_EASE);
                        BABYLON.Animation.CreateAndStartAnimation('avatarMintingParticles', babylonMemoryRef.current.mintingParticles, 'maxScaleX', 60, 90 / mintingSpeed, 0, 4, BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE, AVATAR_FLY_IN_EASE);
                        BABYLON.Animation.CreateAndStartAnimation('avatarMintingParticles', babylonMemoryRef.current.mintingParticles, 'maxScaleY', 60, 90 / mintingSpeed, 0, 4, BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE, AVATAR_FLY_IN_EASE);
                        BABYLON.Animation.CreateAndStartAnimation('avatarMintingParticles', babylonMemoryRef.current.mintingParticles, 'emitRate', 60, 90 / mintingSpeed, 0, 500, BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE, AVATAR_FLY_IN_EASE, () => {
                            setTimeout(() => {
                                BABYLON.Animation.CreateAndStartAnimation('avatarMintingParticles', babylonMemoryRef.current.mintingParticles, 'minScaleX', 60, 180 / mintingSpeed, 4, 1, BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE, AVATAR_FLY_IN_EASE);
                                BABYLON.Animation.CreateAndStartAnimation('avatarMintingParticles', babylonMemoryRef.current.mintingParticles, 'minScaleY', 60, 180 / mintingSpeed, 4, 1, BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE, AVATAR_FLY_IN_EASE);
                                BABYLON.Animation.CreateAndStartAnimation('avatarMintingParticles', babylonMemoryRef.current.mintingParticles, 'maxScaleX', 60, 180 / mintingSpeed, 4, 1, BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE, AVATAR_FLY_IN_EASE);
                                BABYLON.Animation.CreateAndStartAnimation('avatarMintingParticles', babylonMemoryRef.current.mintingParticles, 'maxScaleY', 60, 180 / mintingSpeed, 4, 1, BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE, AVATAR_FLY_IN_EASE);
                            }, 1000 / mintingSpeed);

                            setTimeout(() => {
                                // Animate background color
                                const backgroundColor = BABYLON.Color3.FromHexString(avatarHexToBackground[bodyMaterial.diffuseColor.toHexString().toLowerCase()].toHexString().substring(0, 7));

                                const colorInterval = setInterval(() => {
                                    BABYLON.Color3.LerpToRef(backgroundMaterial.diffuseColor, backgroundColor, 0.005, backgroundMaterial.diffuseColor);
                                    BABYLON.Color3.LerpToRef(backgroundMaterial.emissiveColor, backgroundColor, 0.005, backgroundMaterial.emissiveColor);
                                    BABYLON.Color3.LerpToRef(backgroundMaterial.specularColor, backgroundColor, 0.005, backgroundMaterial.specularColor);
                                }, 17 / mintingSpeed);
                                setTimeout(() => {
                                    clearInterval(colorInterval);
                                }, 6000);
                            }, 3000 / mintingSpeed);
                            // BABYLON.Animation.CreateAndStartAnimation('backgroundColor', backgroundMaterial, 'diffuseColor', 60, 180, backgroundMaterial.diffuseColor, backgroundColor, BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE, AVATAR_FLY_IN_EASE);
                            // BABYLON.Animation.CreateAndStartAnimation('backgroundColor', backgroundMaterial, 'emissiveColor', 60, 180, backgroundMaterial.emissiveColor, backgroundColor, BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE, AVATAR_FLY_IN_EASE);
                            // BABYLON.Animation.CreateAndStartAnimation('backgroundColor', backgroundMaterial, 'ambientColor', 60, 180, backgroundMaterial.ambientColor, backgroundColor, BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE, AVATAR_FLY_IN_EASE);

                            BABYLON.Animation.CreateAndStartAnimation('avatarScaling', body, 'scaling', 60, 540 / mintingSpeed, body.scaling.clone(), finalScaling, BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE, INTRO_CAMERA_EASING);
                            body.rotationQuaternion = null;
                            BABYLON.Animation.CreateAndStartAnimation('avatarSpinning', body, 'rotation.y', 60, 540 / mintingSpeed, body.rotation.y, 8 * 2 * Math.PI, BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE, INTRO_CAMERA_EASING, () => {
                                babylonMemoryRef.current.mintingParticles.emitRate = 0;
                                setTimeout(() => {
                                    BABYLON.Animation.CreateAndStartAnimation('avatarFlyRotate', body, 'rotation.y', 60, 60 / mintingSpeed, body.rotation.y, body.rotation.y + 4 * 2 * Math.PI, BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE, INTRO_CAMERA_EASING, () => {
                                    });
                                    BABYLON.Animation.CreateAndStartAnimation('avatarFly', body, 'position.y', 60, 120 / mintingSpeed, body.position.y, body.position.y + 100, BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE, flyEase, () => {
                                        body.scaling = originalScaling
                                        nextBody(i + 1);
                                    });
                                }, 2000 / mintingSpeed);
                            });
                        });
                    }, 480 / mintingSpeed)
                });
            };
            nextBody(0);
        }, 1000);
    };
    const masterHyalikoOnEnd = () => {
        exitDialogue();
    };

    const DIALOGUE_SEQUENCES: { [key: string]: DialogueSequence } = {
        MASTER_HYALIKO: {
            pages: [
                { text: "you..." },
                { text: "so you're just like them..." },
                { text: "looking for something..." },
                { text: "something other than what you are..." },
                { text: "flat..." },
                { text: "colorless..." },
                { text: "expressionless..." },
                {
                    text: "you're looking for yourself", onContinue: () => {
                        if (!web3Address || !web3) {
                            // go to connect wallet step
                            setDialogueSequenceIndex(dialogueSequenceIndex + 1);
                        } else {
                            // skip connect step
                            setDialogueSequenceIndex(dialogueSequenceIndex + 2);
                        }
                    }
                },
                { text: `you're not the only one. ${publicationStatus && (publicationStatus.totalSupply)} identities have been claimed.` },
                {
                    text: "first... we need you to connect", renderContent: () => (
                        <div className="dialogue-box-button-row">
                            <button className="dialogue-box-button" onClick={async () => {
                                setLoading(true);
                                // connect to web3
                                let web3State;
                                if (!web3 || !web3Address) {
                                    try {
                                        web3State = await connectWeb3();
                                    } catch {
                                        setLoading(false);
                                        return;
                                    }
                                } else {
                                    web3State = { web3, web3Address };
                                }

                                let airdropMerkleProof = null;
                                let whitelistMerkleProof = null;
                                if (web3State && web3State.web3Address) {
                                    const merkleProofs = (await fetch(`${HYALIKO_MERKLE_PROOFS_URL}?address=${web3State.web3Address}`).then(res => res.json())).merkleProofs;
                                    if (merkleProofs) {
                                        if (merkleProofs.airdrop) {
                                            airdropMerkleProof = merkleProofs.airdrop.merkleProof;
                                        }
                                        if (merkleProofs.whitelist) {
                                            whitelistMerkleProof = merkleProofs.whitelist.merkleProof;
                                        }
                                        setMerkleProofs(merkleProofs);
                                        if (airdropMerkleProof && publicationStatus.publishedForAirdrop && !merkleProofs?.airdrop?.claimed) {
                                            setDialogueSequenceIndex(dialogueSequenceIndex + 2);
                                        } else if (whitelistMerkleProof && publicationStatus.publishedForWhitelist && !merkleProofs?.whitelist?.claimed) {
                                            setDialogueSequenceIndex(dialogueSequenceIndex + 7);
                                        } else if (publicationStatus.published) {
                                            setDialogueSequenceIndex(dialogueSequenceIndex + 13);
                                        } else {
                                            setDialogueSequenceIndex(dialogueSequenceIndex + 1);
                                        }
                                    }
                                } else {
                                    // error state if connecting to web3 fails
                                    setDialogueSequenceIndex(dialogueSequenceIndex + 1);
                                }
                                setLoading(false);
                            }}>{loading ? 'connecting...' : 'connect wallet'}</button>
                        </div>
                    )
                },
                { text: "i'm sorry. it seems that there's nothing i can do for you right now... farewell.", onContinue: masterHyalikoOnEnd },
                { text: `ah yes, i see that you are one of our curators` },
                { text: `it appears that we have ${merkleProofs?.airdrop?.quantity} identities waiting for you` },
                {
                    text: `would you like to claim your ${merkleProofs?.airdrop?.quantity} identities right now?`, renderContent: () => (
                        <div className="dialogue-box-button-row">
                            <button className="dialogue-box-button" onClick={() => {
                                setDialogueSequenceIndex(dialogueSequenceIndex + 1);
                            }}>yes</button>
                            <button className="dialogue-box-button" onClick={() => {
                                const whitelistMerkleProof = merkleProofs.whitelist.merkleProof;
                                if (whitelistMerkleProof && publicationStatus.publishedForWhitelist && !merkleProofs?.whitelist?.claimed) {
                                    setDialogueSequenceIndex(dialogueSequenceIndex + 3);
                                } else if (publicationStatus.published) {
                                    setDialogueSequenceIndex(dialogueSequenceIndex + 9);
                                } else {
                                    setDialogueSequenceIndex(dialogueSequenceIndex - 3);
                                }

                            }}>not right now</button>
                        </div>
                    )
                },
                {
                    text: 'very well. let\'s begin'
                },
                {
                    text: "use your wallet to claim your identities", renderContent: () => awaitingApproval ? (
                        <div className="dialogue-box-text" style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>waiting for the transaction to be approved...</div>
                    ) : awaitingTransaction ? (
                        <div className="dialogue-box-text" style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>waiting for the transaction to succeed...</div>
                    ) : awaitingModels ? (
                        <div className="dialogue-box-text" style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>waiting for your identities...</div>
                    ) : (
                        <div className="dialogue-box-button-row">
                            <button className="dialogue-box-button" disabled={airdropError !== null} onClick={async () => {
                                const startingTokenIndex = await contract.methods.totalSupply().call();
                                const event = contract.methods.mintFromAirdrop(merkleProofs?.airdrop?.quantity, merkleProofs?.airdrop?.merkleProof).send({
                                    maxPriorityFeePerGas: null,
                                    maxFeePerGas: null,
                                    from: web3Address
                                });
                                setAwaitingApproval(true);

                                // go to the hyaliko minting animation
                                event.on('transactionHash', () => {
                                    // Transaction submitted. We're now awaiting approval.
                                    setAwaitingApproval(false)
                                    setAwaitingTransaction(true);
                                    awaitingTransactionRef.current = true;
                                });
                                event.then(async () => {
                                    // Transaction has been confirmed.
                                    setAwaitingTransaction(false);
                                    setAwaitingApproval(false);
                                    awaitingTransactionRef.current = false;
                                    setAwaitingModels(true);

                                    // get the token IDs
                                    const tokenIds = (await contract.methods.tokensOfOwner(web3Address).call()).map((id: string) => parseInt(id)).filter((id: number) => id >= startingTokenIndex);

                                    const modelPromises = tokenIds.map((tokenId: number) => {
                                        return fetch(`https://api.hyaliko.com/hyaliko/generate-model/${tokenId}`).catch(() => { });
                                    });

                                    await Promise.all(modelPromises);
                                    setAwaitingModels(false);

                                    // Kick-off image generation
                                    tokenIds.forEach((tokenId: number) => {
                                        fetch(`https://api.hyaliko.com/hyaliko/generate-image/${tokenId}`).catch(() => { })
                                    });


                                    // do the animation
                                    startMintingAnimation(tokenIds);
                                }).catch((e: any) => {
                                    // Something went wrong. User rejected or something worse.
                                    console.error(e);
                                    setAwaitingApproval(false);
                                    setAwaitingTransaction(false);
                                    awaitingTransactionRef.current = false;
                                });
                            }}>claim</button>
                        </div>
                    ), error: formattedAirdropError
                },
                { text: "it appears you are on the list of the chosen" },
                { text: 'you may claim an identity at no cost' },
                {
                    text: `would you like to redeem your place on the list of the chosen for your identity right now?`, renderContent: () => (
                        <div className="dialogue-box-button-row">
                            <button className="dialogue-box-button" onClick={() => {
                                setDialogueSequenceIndex(dialogueSequenceIndex + 2);
                            }}>yes</button>
                            <button className="dialogue-box-button" onClick={() => {
                                if (publicationStatus.published) {
                                    setDialogueSequenceIndex(dialogueSequenceIndex + 4);
                                } else {
                                    setDialogueSequenceIndex(dialogueSequenceIndex + 1);
                                }
                            }}>not right now</button>
                        </div>
                    )
                },
                { text: "i'm sorry. it seems that there's nothing i can do for you right now... farewell.", onContinue: masterHyalikoOnEnd },
                { text: 'very well. let\'s begin' },
                {
                    text: "use your wallet to claim your identities", renderContent: () => awaitingApproval ? (
                        <div className="dialogue-box-text" style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>waiting for the transaction to be approved...</div>
                    ) : awaitingTransaction ? (
                        <div className="dialogue-box-text" style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>waiting for the transaction to succeed...</div>
                    ) : awaitingModels ? (
                        <div className="dialogue-box-text" style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>waiting for your identities...</div>
                    ) : (
                        <div className="dialogue-box-button-row">
                            <button className="dialogue-box-button" disabled={whitelistError !== null} onClick={async () => {
                                const startingTokenIndex = await contract.methods.totalSupply().call();
                                const event = contract.methods.mintFromWhitelist(merkleProofs?.whitelist?.merkleProof).send({
                                    maxPriorityFeePerGas: null,
                                    maxFeePerGas: null,
                                    from: web3Address
                                });
                                setAwaitingApproval(true);

                                // go to the hyaliko minting animation
                                event.on('transactionHash', () => {
                                    // Transaction submitted. We're now awaiting approval.
                                    setAwaitingApproval(false)
                                    setAwaitingTransaction(true);
                                    awaitingTransactionRef.current = true;
                                });
                                event.then(async () => {
                                    // Transaction has been confirmed.
                                    setAwaitingTransaction(false);
                                    setAwaitingApproval(false);
                                    awaitingTransactionRef.current = false;
                                    setAwaitingModels(true);

                                    // get the token IDs
                                    const tokenIds = (await contract.methods.tokensOfOwner(web3Address).call()).map((id: string) => parseInt(id)).filter((id: number) => id >= startingTokenIndex);


                                    const modelPromises = tokenIds.map((tokenId: number) => {
                                        return fetch(`https://api.hyaliko.com/hyaliko/generate-model/${tokenId}`).catch(() => { });
                                    });

                                    await Promise.all(modelPromises);
                                    setAwaitingModels(false);

                                    // Kick-off image generation
                                    tokenIds.forEach((tokenId: number) => {
                                        fetch(`https://api.hyaliko.com/hyaliko/generate-image/${tokenId}`).catch(() => { })
                                    });


                                    // do the animation
                                    startMintingAnimation(tokenIds);
                                }).catch((e: any) => {
                                    // Something went wrong. User rejected or something worse.
                                    console.error(e);
                                    setAwaitingApproval(false);
                                    setAwaitingTransaction(false);
                                    awaitingTransactionRef.current = false;
                                });
                            }}>claim</button>
                        </div>
                    ), error: formattedWhitelistError
                },
                { text: 'we are in the final stages of identity distribution. you may acquire up to 100 identies in exchange for 0.02 ether per identity' },
                {
                    text: "please input the number of identities you'd like (max 100) and then use your wallet to mint", renderContent: () => awaitingApproval ? (
                        <div className="dialogue-box-text" style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>waiting for the transaction to be approved...</div>
                    ) : awaitingTransaction ? (
                        <div className="dialogue-box-text" style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>waiting for the transaction to succeed...</div>
                    ) : awaitingModels ? (
                        <div className="dialogue-box-text" style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>waiting for your identities...</div>
                    ) : (
                        <>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                <input style={{ marginBottom: 32 }} autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" className="form-text-input big-text-input dialogue-box-input" value={rawNumberToMint} onChange={e => setNumberToMint(e.target.value)} type="number" placeholder="# of identities"></input>
                            </div>
                            <div className="dialogue-box-button-row">
                                <button className="dialogue-box-button" disabled={numberToMint > 100 || numberToMint <= 0 || (publicSaleError !== null)} onClick={async () => {
                                    const startingTokenIndex = await contract.methods.totalSupply().call();
                                    const weiValue = Web3.utils.toWei(`${0.02 * numberToMint}`);
                                    const event = contract.methods.mintFromSale(numberToMint).send({
                                        maxPriorityFeePerGas: null,
                                        maxFeePerGas: null,
                                        from: web3Address,
                                        value: weiValue
                                    });
                                    setAwaitingApproval(true);

                                    // go to the hyaliko minting animation
                                    event.on('transactionHash', () => {
                                        // Transaction submitted. We're now awaiting approval.
                                        setAwaitingApproval(false)
                                        setAwaitingTransaction(true);
                                        awaitingTransactionRef.current = true;
                                    });
                                    event.then(async () => {
                                        // Transaction has been confirmed.
                                        setAwaitingTransaction(false);
                                        setAwaitingApproval(false);
                                        awaitingTransactionRef.current = false;
                                        setAwaitingModels(true);

                                        // get the token IDs
                                        const tokenIds = (await contract.methods.tokensOfOwner(web3Address).call()).map((id: string) => parseInt(id)).filter((id: number) => id >= startingTokenIndex);

                                        const modelPromises = tokenIds.map((tokenId: number) => {
                                            return fetch(`https://api.hyaliko.com/hyaliko/generate-model/${tokenId}`).catch(() => { });
                                        });

                                        await Promise.all(modelPromises);
                                        setAwaitingModels(false);

                                        // Kick-off image generation
                                        tokenIds.forEach((tokenId: number) => {
                                            fetch(`https://api.hyaliko.com/hyaliko/generate-image/${tokenId}`).catch(() => { })
                                        });


                                        // do the animation
                                        startMintingAnimation(tokenIds);
                                    }).catch((e: any) => {
                                        // Something went wrong. User rejected or something worse.
                                        console.error(e);
                                        setAwaitingApproval(false);
                                        setAwaitingTransaction(false);
                                        awaitingTransactionRef.current = false;
                                    });
                                }}>mint</button>
                                <button className="dialogue-box-button" onClick={() => {
                                    setDialogueSequenceIndex(dialogueSequenceIndex + 1);
                                }}>not right now</button>
                            </div>
                        </>
                    ), error: formattedPublicSaleError
                },
                { text: 'i wish you luck. may we meet again somewhere and someday' }
            ],
            frequencyMultiplier: 1
        },
        NPC_0: {
            pages: [
                { text: "so you're one of the lucky ones huh" },
                { text: "you found this place" },
                { text: "well don't waste any more time talking to me" },
                { text: "go on in there" }
            ],
            frequencyMultiplier: 1.5
        },
        NPC_1: {
            pages: [
                { text: "me? go in there?" },
                { text: "i did" },
                { text: "it told me i wasn't enough" },
                { text: "it's all i can do now to help the rest of you" },
                { text: "be warned though" },
                { text: "even if you're not deemed worthy, it will change you" }
            ],
            frequencyMultiplier: 1.6
        },
        NPC_2: {
            pages: [
                { text: "i can't go in there" },
                { text: "i tried" },
                { text: "i'm so tired" },
                { text: "it's so empty" }
            ],
            frequencyMultiplier: 1.7
        }
    };

    // const initialSpaceInputRef = useRef(null);

    // Global game variables
    const isDraggingRef = useRef(false);
    const hasEmotedRef = useRef(false);
    const hasDashedRef = useRef(false);
    const lastDashedRef = useRef(0);
    const dashAnimationTimerRef = useRef(DASH_ANIMATION_LENGTH);
    const keysRef: React.MutableRefObject<{ [key: string]: boolean }> = useRef({
        ArrowUp: false,
        ArrowDown: false,
        ArrowLeft: false,
        ArrowRight: false,
        w: false,
        a: false,
        s: false,
        d: false,
        ' ': false
    });
    const joystickMoveRef = useRef({ x: 0, y: 0 });
    const joystickLookRef = useRef({ x: 0, y: 0 });
    const cameraAlphaRef = useRef(0);
    const cameraBetaRef = useRef(0);
    // Kind of hacky way to track rotation about y axis. This is for wizards and sprites moving canvases
    const playerRotationRef = useRef(0);
    // const cameraZoomRef = useRef(0);

    // GLOBAL VARIABLES
    // const particleSystemsRef: React.MutableRefObject<{ [name: string]: BABYLON.ParticleSystem } | null> = useRef({});

    const babylonMemoryRef = useRef(babylonMemoryDefault);

    const activateDialogueAudio = () => {
        if (!dialogueAudio && !babylonMemoryRef.current.dialogueAudioLoaded) {
            babylonMemoryRef.current.dialogueAudioLoaded = true;
            const audioContext = new AudioContext()

            // gain
            const gain = audioContext.createGain()
            gain.connect(audioContext.destination);
            gain.gain.setValueAtTime(0, audioContext.currentTime);

            // oscillator
            const oscillator = audioContext.createOscillator()
            oscillator.connect(gain);
            oscillator.type = "triangle"
            oscillator.start();
            setDialogueAudio({
                audioContext,
                oscillator,
                gain
            });
        }
    }

    const clientIdRef: React.MutableRefObject<string> = useRef(null);
    const playersRef: React.MutableRefObject<{ [key: string]: BABYLON.AbstractMesh }> = useRef({});
    // Hold onto these to iterate over and keep rotated towards the camera.

    // Apply customizations after submitting the form
    useEffect(() => {
        if (gameState === 1) {
            if (DEBUG) {
                sceneReference.current.debugLayer.show();
            }
            // sceneReference.current.debugLayer.show();
            const canvas: HTMLCanvasElement = document.getElementById('renderCanvas') as HTMLCanvasElement;
            canvas.focus();
            const scene: BABYLON.Scene = sceneReference.current;
            const engine = scene.getEngine();
            if (scene) {
                // Create player
                createPlayer(scene, false).then(({ playerMesh }) => {
                    // Begin character animation
                    const introCamera = scene.getCameraByName('introCamera') as BABYLON.ArcRotateCamera;
                    BABYLON.Animation.CreateAndStartAnimation('introAnimation1', introCamera, 'target', 30, 180, INTRO_CAMERA_INITIAL_TARGET, INTRO_CAMERA_FINAL_TARGET, BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE, INTRO_CAMERA_EASING, () => {

                    });
                    BABYLON.Animation.CreateAndStartAnimation('introAnimation2', introCamera, 'position', 30, 180, INTRO_CAMERA_INITIAL_POSITION.clone(), INTRO_CAMERA_FINAL_POSITION, BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE, INTRO_CAMERA_EASING, () => {

                    });
                    BABYLON.Animation.CreateAndStartAnimation('introAnimation3', introCamera, 'alpha', 60, 360, 3 * Math.PI / 2, -Math.PI / 2, BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE, INTRO_CAMERA_EASING, () => {
                        introCamera.alpha = -Math.PI / 2;
                        BABYLON.Animation.CreateAndStartAnimation('introAnimation5', introCamera, 'target', 30, 45, introCamera.target.clone(), (playerMesh.getChildren(node => node.name === 'cameraTarget')[0] as BABYLON.TransformNode).getAbsolutePosition(), BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE, INTRO_CAMERA_EASING, () => {
                        });
                        BABYLON.Animation.CreateAndStartAnimation('introAnimation6', introCamera, 'alpha', 30, 45, introCamera.alpha, (scene.getCameraByName('playerCamera') as BABYLON.ArcRotateCamera).alpha, BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE, INTRO_CAMERA_EASING, () => {
                        });
                        BABYLON.Animation.CreateAndStartAnimation('introAnimation7', introCamera, 'beta', 30, 45, introCamera.beta, (scene.getCameraByName('playerCamera') as BABYLON.ArcRotateCamera).beta, BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE, INTRO_CAMERA_EASING, () => {
                            babylonMemoryRef.current.state = STATES.FREE;
                            babylonMemoryRef.current.ambientParticles.emitRate /= 2;
                        });
                        BABYLON.Animation.CreateAndStartAnimation('introAnimation8', introCamera, 'radius', 30, 45, introCamera.radius, (scene.getCameraByName('playerCamera') as BABYLON.ArcRotateCamera).radius, BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE, INTRO_CAMERA_EASING, () => {
                            // babylonMemoryRef.current.state = STATES.FREE;
                        });
                    });

                    // BABYLON.Animation.CreateAndStartAnimation('introAnimation3', introCamera, 'alpha', 30, 0, -Math.PI / 2, Math.PI / 2, BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE, INTRO_CAMERA_EASING, () => {
                    // Animate camera to player camera and then start
                    // BABYLON.Animation.CreateAndStartAnimation('introAnimation4', introCamera, 'alpha', 30, 360, -Math.PI / 2, (3 / 2 * Math.PI), BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE, INTRO_ROTATE_EASING, () => {

                    // })
                    // })
                    createEnvironment(scene, babylonMemoryRef.current, playerMesh, setDialogueSequence, setDialogueSequenceIndex, setOnTalk);


                    // Create camera target
                    const cameraTarget = new BABYLON.AbstractMesh('cameraTarget');
                    cameraTarget.position = playerMesh.position.add(HEIGHT_OFFSET);
                    playerMesh.addChild(cameraTarget);
                    // Create follow camera
                    const playerCamera = scene.getCameraByName('playerCamera') as BABYLON.ArcRotateCamera;
                    // Target the camera to scene origin
                    playerCamera.lockedTarget = cameraTarget;
                    const inspectCamera = scene.getCameraByName('inspectCamera') as BABYLON.FreeCamera;
                    inspectCamera.lockedTarget = cameraTarget.absolutePosition.clone();

                    scene.onPointerObservable.add((pointerInfo) => {
                        switch (pointerInfo.type) {
                            case BABYLON.PointerEventTypes.POINTERTAP: {
                                let mesh = pointerInfo.pickInfo.pickedMesh;
                                if (mesh && (BABYLON.Tags.MatchesQuery(mesh, 'player'))) {
                                    hasEmotedRef.current = true;
                                }
                                break;
                            }
                            case BABYLON.PointerEventTypes.POINTERDOWN: {
                                if (!babylonMemoryRef.current.dialogueAudioLoaded) {
                                    activateDialogueAudio();
                                }
                                isDraggingRef.current = true;
                                break;
                            }
                            case BABYLON.PointerEventTypes.POINTERUP: {
                                isDraggingRef.current = false;
                                break;
                            }
                        }
                    });

                    scene.onAfterPhysicsObservable.add(() => {
                        playerMesh.physicsImpostor.setAngularVelocity(BABYLON.Vector3.ZeroReadOnly);
                        const playerRotationEuler = playerMesh.rotationQuaternion.toEulerAngles();
                        playerMesh.rotationQuaternion = BABYLON.Quaternion.FromEulerAngles(0, playerRotationEuler.y, 0);
                    });

                    // Bind keys
                    scene.onKeyboardObservable.add((kbInfo) => {
                        if (!babylonMemoryRef.current.dialogueAudioLoaded) {
                            activateDialogueAudio();
                        }
                        if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'w', 'a', 's', 'd', 'c', ' '].includes(kbInfo.event.key)) {
                            switch (kbInfo.type) {

                                case BABYLON.KeyboardEventTypes.KEYDOWN:
                                    keysRef.current[kbInfo.event.key] = true;
                                    break;
                                case BABYLON.KeyboardEventTypes.KEYUP:
                                    keysRef.current[kbInfo.event.key] = false;
                                    break;
                            }
                        }
                    });

                    let fallingTimer = 0;
                    const playerGroundVector = BABYLON.Vector3.UpReadOnly.scale(-1);
                    scene.registerBeforeRender(() => {

                        // can't move while targeting
                        const keys = keysRef.current;

                        if (keys[' ']) {
                            hasEmotedRef.current = true;
                        }
                        // Emote
                        if (hasEmotedRef.current) {
                            playerMesh.getConnectedParticleSystems().forEach(particles => {
                                if (particles.name.includes('emote')) {
                                    particles.start();
                                }
                            });
                        }

                        // Camera controls
                        if (keys.w || keys.a || keys.s || keys.d) {
                            if (!babylonMemoryRef.current.dialogueAudioLoaded) {
                                activateDialogueAudio();
                            }
                            const alphaRotation = keys.a ? 0.02 : keys.d ? -0.02 : 0;
                            cameraAlphaRef.current += alphaRotation;
                            const betaRotation = keys.w ? 0.02 : keys.s ? -0.02 : 0;
                            cameraBetaRef.current += betaRotation;
                        }
                        if (joystickLookRef.current.x !== 0 || joystickLookRef.current.y !== 0) {
                            const alphaRotation = -joystickLookRef.current.x / 50;
                            cameraAlphaRef.current += alphaRotation;
                            const betaRotation = joystickLookRef.current.y / 50;
                            cameraBetaRef.current += betaRotation;
                        }
                        cameraBetaRef.current = Math.min(cameraBetaRef.current, Math.PI / 2);
                        cameraBetaRef.current = Math.max(cameraBetaRef.current, -Math.PI / 2);

                        // Move
                        let velocityVector = null;

                        if (babylonMemoryRef.current.state === STATES.FREE && !babylonMemoryRef.current.cameraTarget) {
                            // Joystick controls
                            if (joystickMoveRef.current.x !== 0 || joystickMoveRef.current.y !== 0) {
                                velocityVector = new BABYLON.Vector3(joystickMoveRef.current.x, 0, joystickMoveRef.current.y).normalize().scaleInPlace(PLAYER_ACCELERATION);
                            }

                            if (isDraggingRef.current && scene.pointerX && scene.pointerY && !isMobile) {
                                const cameraViewport = playerCamera.viewport.toGlobal(
                                    engine.getRenderWidth(),
                                    engine.getRenderHeight()
                                );
                                const playerScreenPosition = BABYLON.Vector3.Project(
                                    playerMesh.getAbsolutePosition(),
                                    BABYLON.Matrix.IdentityReadOnly,
                                    scene.getTransformMatrix(),
                                    cameraViewport
                                );
                                const pointerPlayerDelta = new BABYLON.Vector2(-(playerScreenPosition.x - scene.pointerX), playerScreenPosition.y - scene.pointerY);
                                if (pointerPlayerDelta.length() > 50) {
                                    velocityVector = new BABYLON.Vector3(pointerPlayerDelta.x, 0, pointerPlayerDelta.y).normalize().scaleInPlace(PLAYER_ACCELERATION);
                                }
                            } else if (keys.ArrowUp || keys.ArrowDown || keys.ArrowLeft || keys.ArrowRight) {
                                const xVelocity = keys.ArrowLeft ? -1 : keys.ArrowRight ? 1 : 0;
                                const yVelocity = keys.ArrowDown ? -1 : keys.ArrowUp ? 1 : 0;
                                velocityVector = new BABYLON.Vector3(xVelocity, 0, yVelocity).normalize().scaleInPlace(PLAYER_ACCELERATION);
                            }
                        }

                        // If there's a velocity vector, rotate it around camera
                        if (velocityVector) {
                            velocityVector.rotateByQuaternionAroundPointToRef(BABYLON.Quaternion.FromEulerAngles(0, -cameraAlphaRef.current, 0), BABYLON.Vector3.ZeroReadOnly, velocityVector);
                        }

                        let currentVelocity = playerMesh.physicsImpostor.getLinearVelocity();
                        // Add movement speed
                        if (velocityVector) {
                            playerMesh.lookAt(playerMesh.position.add(velocityVector));
                            currentVelocity.addInPlace(velocityVector);
                            playerRotationRef.current = BABYLON.Vector3.GetAngleBetweenVectors(new BABYLON.Vector3(velocityVector.x, 0, velocityVector.z), new BABYLON.Vector3(0, 0, 1), new BABYLON.Vector3(0, -1, 0));
                        }
                        // Clamp speed
                        const currentVelocityNoY = currentVelocity.clone();
                        currentVelocityNoY.y = 0;
                        const clampedVelocityMagnitude = Math.min(currentVelocityNoY.length(), MAX_PLAYER_SPEED);
                        const finalClampedVelocity = currentVelocityNoY.normalize().scaleInPlace(clampedVelocityMagnitude);
                        finalClampedVelocity.y = currentVelocity.y;
                        if (currentVelocity.y > MAX_PLAYER_VERTICAL_SPEED) {
                            finalClampedVelocity.y = MAX_PLAYER_VERTICAL_SPEED;
                        }
                        playerMesh.physicsImpostor.setLinearVelocity(finalClampedVelocity);

                        currentVelocity = playerMesh.physicsImpostor.getLinearVelocity();
                        const fieldRay = new BABYLON.Ray(playerMesh.position.add(playerMesh.forward), playerGroundVector, 10);
                        const hit = scene.pickWithRay(fieldRay, mesh => mesh.isPickable && !BABYLON.Tags.MatchesQuery(mesh, 'player') && !mesh.name.startsWith('Canvas'));
                        if (hit && hit.pickedPoint) {
                            fallingTimer = null;
                            const height = playerMesh.position.y - hit.pickedPoint.y - 0.25;
                            currentVelocity.y += Math.min(1, (2.2 / (height * height)));
                        } else {
                            // if no picked point for 5 seconds, check if player is falling and reset
                            if (fallingTimer === null) {
                                fallingTimer = Date.now();
                            } else {
                                if (Date.now() - fallingTimer > 3000) {
                                    // Reset player if falling
                                    const deathHit = scene.pickWithRay(new BABYLON.Ray(playerMesh.position.add(playerMesh.forward), BABYLON.Vector3.UpReadOnly.scale(-100)), mesh => mesh.isPickable && !BABYLON.Tags.MatchesQuery(mesh, 'player') && !mesh.name.startsWith('Canvas') && !mesh.name.endsWith('Trigger'));
                                    if ((!deathHit || !deathHit.pickedPoint) && !HIGH_SPEED) {
                                        playerMesh.position = PLAYER_STARTING_POSITION;
                                        playerMesh.physicsImpostor.setLinearVelocity(BABYLON.Vector3.Zero());
                                    } else {
                                        fallingTimer = null;
                                    }
                                }
                            }
                        }
                        currentVelocity.y *= 0.99;
                        if (HIGH_SPEED && isDraggingRef.current) {
                            currentVelocity.y = 20;
                        }
                        playerMesh.physicsImpostor.setLinearVelocity(currentVelocity);

                        // Apply friction
                        if (!velocityVector) {
                            playerMesh.physicsImpostor.setLinearVelocity(new BABYLON.Vector3(currentVelocity.x * 0.9, currentVelocity.y, currentVelocity.z * 0.9));
                        }

                        // Dash
                        if (hasDashedRef.current) {
                            if (!lastDashedRef.current || (Date.now() - lastDashedRef.current > 3000)) {
                                playerMesh.physicsImpostor.setLinearVelocity(playerMesh.physicsImpostor.getLinearVelocity().add(playerMesh.forward.scale(DASH_POWER)));
                                hasEmotedRef.current = true;
                                hasDashedRef.current = false;
                                lastDashedRef.current = Date.now();
                                dashAnimationTimerRef.current = 0;
                                // TODO: UI stuff. Disabled button etc.
                            } else {
                                hasDashedRef.current = false;
                            }
                        }


                        // Update camera
                        if (babylonMemoryRef.current.state === STATES.INTRO) {
                            scene.activeCamera = introCamera;
                        } else {
                            if (babylonMemoryRef.current.cameraTarget === null) {
                                scene.activeCamera = playerCamera;
                            } else {
                                scene.activeCamera = inspectCamera;
                            }
                        }

                        // Always keep the player camera in sync
                        playerCamera.lockedTarget = playerMesh.getChildren(node => node.name === 'cameraTarget')[0];
                        playerCamera.alpha = BABYLON.Scalar.Lerp(playerCamera.alpha, CAMERA_INITIAL_ALPHA + cameraAlphaRef.current, 0.1);
                        playerCamera.beta = BABYLON.Scalar.Lerp(playerCamera.beta, CAMERA_INITIAL_BETA + cameraBetaRef.current, 0.1);

                        // If there's a target, animate the camera
                        if (babylonMemoryRef.current.cameraTarget === null || babylonMemoryRef.current.cameraPosition === null) {
                            inspectCamera.lockedTarget = playerCamera.lockedTarget.absolutePosition.clone();
                            inspectCamera.position = playerCamera.position.clone();
                        } else {
                            const target = babylonMemoryRef.current.cameraTarget;
                            const newCameraTarget = target.clone();
                            const newCameraPosition = babylonMemoryRef.current.cameraPosition.clone();
                            // BABYLON.Animation.CreateAndStartAnimation('cameraTarget', inspectCamera, 'lockedTarget', 60, 60, inspectCamera.lockedTarget, newCameraTarget);
                            // BABYLON.Animation.CreateAndStartAnimation('cameraPosition', inspectCamera, 'position', 60, 60, inspectCamera.position, playerMesh.position.clone());
                            babylonMemoryRef.current.cameraAnimationTimer += engine.getDeltaTime() / 2000;
                            BABYLON.Vector3.LerpToRef(inspectCamera.lockedTarget, newCameraTarget, Math.min(Math.max(babylonMemoryRef.current.cameraAnimationTimer, 0), 1), inspectCamera.lockedTarget);
                            BABYLON.Vector3.LerpToRef(inspectCamera.position, newCameraPosition, Math.min(Math.max(babylonMemoryRef.current.cameraAnimationTimer, 0), 1), inspectCamera.position);
                        }
                        babylonMemoryRef.current.ambientParticles.emitter = scene.activeCamera.position;


                        // Dash animation and camera shake
                        if (dashAnimationTimerRef.current < DASH_ANIMATION_LENGTH) {
                            playerCamera.position.x += 0.25 - (0.5 * Math.random());
                            playerCamera.position.y += 0.25 - (0.5 * Math.random());
                            dashAnimationTimerRef.current += engine.getDeltaTime();
                        }
                    });

                    scene.registerAfterRender(() => {
                        hasEmotedRef.current = false;
                    });

                    // Create websocket
                    const webSocket = new WebSocket(WEBSOCKET_URL);
                    webSocket.onopen = () => {
                        webSocket.onmessage = (rawMessage) => {
                            const message = JSON.parse(rawMessage.data);
                            const players = playersRef.current;
                            switch (message.type) {
                                case 'AssignId': {
                                    const clientId = message.contents.clientId;
                                    clientIdRef.current = clientId;
                                    players[clientId] = playerMesh;
                                    break;
                                }
                                case 'NewPlayers': {
                                    Object.keys(message.contents).forEach(newPlayerId => {
                                        if (newPlayerId in players) {
                                            return;
                                        }
                                        createPlayer(scene, true, newPlayerId).then(({ playerMesh }) => {
                                            playerMesh.position.z = -1000;
                                            players[newPlayerId] = playerMesh
                                        });
                                    });
                                    break;
                                }
                                case 'RoomUpdate': {
                                    const playerStates = message.contents;
                                    // Update existing players
                                    if (playerStates) {
                                        Object.keys(playerStates).forEach((playerId: string) => {
                                            if (!(playerId in players)) {
                                                // If we're missing a player, we might have missed an update.
                                                // Request the players again.
                                                MULTIPLAYER_ENABLED && webSocket.send(JSON.stringify({
                                                    type: 'RequestPlayers',
                                                    contents: {}
                                                }));
                                                return;
                                            }
                                            if (playerId === clientIdRef.current) {
                                                return;
                                            }
                                            const playerData = playerStates[playerId];
                                            const playerMesh = players[playerId];
                                            if (playerMaterials)
                                                if (playerMesh) {
                                                    const player = players[playerId];
                                                    player.rotationQuaternion.w = playerData.rotationW;
                                                    player.rotationQuaternion.x = playerData.rotationX;
                                                    player.rotationQuaternion.y = playerData.rotationY;
                                                    player.rotationQuaternion.z = playerData.rotationZ;
                                                    player.position.x = playerData.x;
                                                    player.position.y = playerData.y;
                                                    player.position.z = playerData.z;
                                                    if (playerData.emoting) {
                                                        player.getConnectedParticleSystems().forEach(particles => {
                                                            if (particles.name.includes('emote')) {
                                                                particles.start();
                                                            }
                                                        });
                                                    }
                                                }
                                        });
                                    }
                                    break;
                                }
                                case 'DeletePlayers': {
                                    const deletedPlayers = message.contents;
                                    if (deletedPlayers && Array.isArray(deletedPlayers)) {
                                        deletedPlayers.forEach((playerId: string) => {
                                            const deleteMesh = players[playerId];
                                            if (deleteMesh) {
                                                deleteMesh.getConnectedParticleSystems().forEach(system => system.dispose());
                                                scene.removeMesh(deleteMesh, true);
                                                delete players[playerId];
                                            }
                                        });
                                    }
                                    break;
                                }
                                default:
                                    break;
                            }
                        };
                        MULTIPLAYER_ENABLED && webSocket.send(JSON.stringify({ type: 'CreateOrJoinRoom', contents: { roomCode: 'mintAvatar' } }));
                    };
                    webSocketReference.current = webSocket;

                    // TODO: Only be sending if there is someone else in the space
                    scene.registerBeforeRender(() => {
                        // Send position update
                        if (webSocket.readyState === WebSocket.OPEN) {
                            MULTIPLAYER_ENABLED && webSocket.send(JSON.stringify({
                                type: 'UpdatePlayer',
                                contents: {
                                    x: playerMesh.position.x,
                                    y: playerMesh.position.y,
                                    z: playerMesh.position.z,
                                    rotationW: playerMesh.rotationQuaternion.w,
                                    rotationX: playerMesh.rotationQuaternion.x,
                                    rotationY: playerMesh.rotationQuaternion.y,
                                    rotationZ: playerMesh.rotationQuaternion.z,
                                    emoting: hasEmotedRef.current
                                }
                            }));
                        }
                    });
                });
            }
        }
        // Don't mess with these dependencies lol
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameState]);

    // Mount Babylon
    useEffect(() => {
        // Initialize variables

        // Get the canvas DOM element
        const canvas: HTMLCanvasElement = document.getElementById('renderCanvas') as HTMLCanvasElement;
        // Load the 3D engine
        const engine = new BABYLON.Engine(canvas, false, {
            preserveDrawingBuffer: true, stencil: true, xrCompatible: false,
            // doNotHandleContextLost: true,
            deterministicLockstep: true,
            lockstepMaxSteps: 1,
        }, false);
        engine.enableOfflineSupport = false;

        createScene(engine, babylonMemoryRef.current).then((scene: BABYLON.Scene) => {
            sceneReference.current = scene;

            preloadEnvironment(scene, babylonMemoryRef.current);

            // run the render loop
            engine.runRenderLoop(function () {
                scene.render();
            });

            // the canvas/window resize event handler
            window.addEventListener('resize', function () {
                engine.resize();
            });

            setReady(true);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const audio = document.getElementById('minting1') as HTMLAudioElement;

    // const uploadSpace = (fileInputRef: any, segmentPlace: number) => {
    //     const scene = sceneReference.current;
    //     const spaceUrl = URL.createObjectURL(fileInputRef.current.files[0]);
    //     scene.getMeshesByTags("level").forEach(mesh => {
    //         mesh.physicsImpostor && mesh.physicsImpostor.dispose();
    //         mesh.dispose();
    //     });
    //     BABYLON.SceneLoader.ImportMeshAsync(
    //         "",
    //         spaceUrl,
    //         "",
    //         scene,
    //         null,
    //         '.obj'
    //     ).then(result => {
    //         result.meshes.forEach(mesh => {
    //             BABYLON.Tags.AddTagsTo(mesh, "level");
    //             if (!mesh.name.includes('NoPhysics')) {
    //                 const levelPhysics = new BABYLON.PhysicsImpostor(mesh, BABYLON.PhysicsImpostor.MeshImpostor, { mass: 0, restitution: 0, friction: 0 }, scene);
    //                 mesh.physicsImpostor = levelPhysics;
    //                 mesh.isPickable = true;
    //             }
    //             mesh.cullingStrategy = BABYLON.AbstractMesh.CULLINGSTRATEGY_BOUNDINGSPHERE_ONLY;
    //         });
    //     });
    // };

    useEffect(() => {
        return () => {
            colorToMaterial = {};
            indexToParticleTexture = {};
            sceneReference.current && sceneReference.current.dispose();
            webSocketReference.current && webSocketReference.current.close();
        };
    }, []);

    useEffect(() => {
        let rightManager: nipplejs.JoystickManager = null;
        let leftManager: nipplejs.JoystickManager = null;
        if (gameState === 1) {
            const sharedOptions = {
                mode: 'static' as 'static',
                position: { top: '50%', left: '50%' },
                restOpacity: 1
            };
            const leftOptions = {
                zone: document.getElementById('leftJoystick'),
                ...sharedOptions
            };
            const rightOptions = {
                zone: document.getElementById('rightJoystick'),
                ...sharedOptions
            };

            leftManager = nipplejs.create(leftOptions);
            leftManager.on('move' as nipplejs.JoystickManagerEventTypes, (e, data) => {
                if (!babylonMemoryRef.current.dialogueAudioLoaded) {
                    activateDialogueAudio();
                }
                if (Math.sqrt(data.vector.x ** 2 + data.vector.y ** 2) > 0.5) {
                    joystickMoveRef.current.x = data.vector.x;
                    joystickMoveRef.current.y = data.vector.y;
                } else {
                    joystickMoveRef.current.x = 0;
                    joystickMoveRef.current.y = 0;
                }
            });
            leftManager.on('end' as nipplejs.JoystickManagerEventTypes, (e, data) => {
                joystickMoveRef.current.x = 0;
                joystickMoveRef.current.y = 0;
            });
            const leftFront = leftManager.get(undefined).ui.front;
            const leftBack = leftManager.get(undefined).ui.back;
            leftFront.classList.add('joystick-front');
            leftBack.classList.add('joystick-back');
            leftFront.innerHTML = 'move';

            rightManager = nipplejs.create(rightOptions);
            rightManager.on('move' as nipplejs.JoystickManagerEventTypes, (e, data) => {
                if (!babylonMemoryRef.current.dialogueAudioLoaded) {
                    activateDialogueAudio();
                }
                if (Math.sqrt(data.vector.x ** 2 + data.vector.y ** 2) > 0.5) {
                    joystickLookRef.current.x = data.vector.x;
                    joystickLookRef.current.y = data.vector.y;
                } else {
                    joystickLookRef.current.x = 0;
                    joystickLookRef.current.y = 0;
                }
            });
            rightManager.on('end' as nipplejs.JoystickManagerEventTypes, (e, data) => {
                joystickLookRef.current.x = 0;
                joystickLookRef.current.y = 0;
            });
            const rightFront = rightManager.get(undefined).ui.front;
            const rightBack = rightManager.get(undefined).ui.back;
            rightFront.classList.add('joystick-front');
            rightBack.classList.add('joystick-back');
            rightFront.innerHTML = 'look';
        }

        return () => {
            rightManager && rightManager.destroy();
            leftManager && leftManager.destroy();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameState]);

    // const bottomRightContent = (
    //     <>
    //         {!isMobile && gameState === 1 && <button className="game-button emote-button" onClick={() => { hasEmotedRef.current = true; document.getElementById('renderCanvas').focus(); }}></button>}
    //     </>
    // );

    const dialogueReady = dialogueSequence && dialogueSequenceIndex !== null;
    const dialogueOnContinue = (dialogueReady && DIALOGUE_SEQUENCES[dialogueSequence].pages[dialogueSequenceIndex].onContinue) || defaultOnContinue;

    return (
        <>
            <canvas id="renderCanvas" className="no-select" tabIndex={1}></canvas>
            {gameState === 1 && (
                // <button className={`game-button mute-button ${muted ? 'mute-button-muted' : ''}`} onClick={() => {
                //     if (muted) {
                //         setMuted(false);
                //         audio.play();
                //     } else {
                //         setMuted(true);
                //         audio.pause();
                //     }
                //     document.getElementById('renderCanvas').focus();
                // }}></button>
                null
            )}
            {/* {bottomRightContent} */}
            {gameState === 1 && (
                null
                // <div className="space-input">
                //     <div>
                //         <input
                //             type="file"
                //             id="space"
                //             name="space"
                //             accept=".obj"
                //             ref={initialSpaceInputRef}
                //         />
                //         <button onClick={() => uploadSpace(initialSpaceInputRef, 0)}>upload</button>
                //     </div>
                //     {/* <div>
                //         <input
                //             type="file"
                //             id="space"
                //             name="space"
                //             accept=".obj"
                //             ref={intermediateSpaceInputRef}
                //         />
                //         <button onClick={() => uploadSpace(intermediateSpaceInputRef, 1)}>upload</button>
                //     </div>
                //     <div>
                //         <input
                //             type="file"
                //             id="space"
                //             name="space"
                //             accept=".obj"
                //             ref={finalSpaceInputRef}
                //         />
                //         <button onClick={() => uploadSpace(finalSpaceInputRef, 2)}>upload</button>
                //     </div> */}
                // </div>
            )}
            <div className="joystick left-joystick" id="leftJoystick" style={(!(gameState === 1 && isMobile && !dialogueSequence)) ? { visibility: 'hidden' } : {}}></div>
            <div className="joystick right-joystick" id="rightJoystick" style={(!(gameState === 1 && isMobile && !dialogueSequence)) ? { visibility: 'hidden' } : {}}></div>
            {onTalk && !dialogueReady && <button className="dialogue-box-button" onClick={onTalk} style={{ position: 'absolute', bottom: 64, left: 'calc(50% - 113px)' }}>talk</button>}
            {/* {gameState === 1 && <button className={`game-button top-button close-button ${headerVisible ? '' : 'game-button-hidden'}`} onClick={onQuitInspect}></button>} */}
            <DialogueBox text={dialogueReady ? DIALOGUE_SEQUENCES[dialogueSequence].pages[dialogueSequenceIndex].text : null} renderContent={dialogueReady ? DIALOGUE_SEQUENCES[dialogueSequence].pages[dialogueSequenceIndex].renderContent : null} error={dialogueReady ? DIALOGUE_SEQUENCES[dialogueSequence].pages[dialogueSequenceIndex].error : null} frequencyMultiplier={dialogueReady ? DIALOGUE_SEQUENCES[dialogueSequence].frequencyMultiplier : 0} audio={dialogueAudio} onContinue={dialogueOnContinue} />
            {displayWeb3Address && (
                <div className="new-home-extra-small-button current-address-show-on-mobile" style={{ right: 16 }}><span className="current-address-text">{displayWeb3Address}</span></div>
            )}
        </>
    );
}

export default MintHyalikoAvatar;
