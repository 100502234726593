import { createContext } from 'react'

const ChatContext = createContext({ chat: [], setChat: (chat: any) => {} });
const PeopleContext = createContext({ people: [], setPeople: (people: any) => {} });
const ArtContext = createContext({ art: [], setArt: (art: any) => {} });

export type CurateEntryType = {
    hidden: boolean,
    name: string,
    imageUrl: string,
    imageThumbnailUrl: string,
    id: string
};

export type CurateType = {
    nfts: {
        [key: string]: CurateEntryType
    },
    collaborators: string[],
    spaces: any[]
}

const curateDefault: CurateType = { nfts: {}, collaborators: [], spaces: [] };
const CurateContext = createContext({ curate: curateDefault, setCurate: (curate: any) => {} });

export { ChatContext, PeopleContext, ArtContext, CurateContext };