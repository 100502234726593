import React, { useEffect, useState } from 'react';
import './About.css';
import { PARTICLE_SHAPE_FUNCTIONS } from './constants';

const HOME_PINK = '#ff85d8';
const HOME_BABYLON_PINK = BABYLON.Color3.FromHexString(HOME_PINK);

function getRandomRotationSpeed() {
    const randomBool = Math.random() - 0.5 <= 0;
    return (0.01 + (Math.random() * 0.01)) * (randomBool ? -1 : 1)
}

function getRandomSpeed() {
    return Math.random() * 0.05;
}

const numLines = 6;
function About() {
    const [slideCount, setSlideCount] = useState(0);
    useEffect(() => {
        setTimeout(() => {
            setSlideCount(1);
        }, 125);
        for (let i = 2; i < numLines; i++) {
            setTimeout(() => {
                setSlideCount(i);
            }, 500 * (i - 1));
        }
    }, []);
    useEffect(() => {
        // Initialize babylon animation
        // Get the canvas DOM element
        const canvas: HTMLCanvasElement = document.getElementById('aboutRenderCanvas') as HTMLCanvasElement;
        // Load the 3D engine
        const engine = new BABYLON.Engine(canvas, false, {
            preserveDrawingBuffer: true, stencil: true, xrCompatible: false
        }, false);

        // Create a basic BJS Scene object
        const scene = new BABYLON.Scene(engine);
        // scene.debugLayer.show();
        scene.fogEnabled = true;
        scene.fogMode = BABYLON.Scene.FOGMODE_EXP;
        scene.fogColor = BABYLON.Color3.White();
        scene.fogDensity = 0.005;

        // Ambient particle system
        const particles = new BABYLON.ParticleSystem('particles', 2000, scene);
        particles.emitRate *= 1;
        particles.minLifeTime = 10;
        particles.maxLifeTime = 10;
        particles.maxScaleX = 1;
        particles.maxScaleY = 1;
        particles.minScaleX = 1;
        particles.minScaleY = 1;
        particles.minEmitPower = 10;
        particles.maxEmitPower = 10;
        particles.particleTexture = BABYLON.Texture.LoadFromDataString('particleTexture', PARTICLE_SHAPE_FUNCTIONS[0]('#ffffff'), scene, true, true, true, BABYLON.Texture.NEAREST_SAMPLINGMODE);
        particles.blendMode = BABYLON.ParticleSystem.BLENDMODE_MULTIPLYADD;
        particles.color1 = new BABYLON.Color4(1, 1, 1, 1);
        particles.color2 = new BABYLON.Color4(1, 1, 1, 1);
        particles.colorDead = new BABYLON.Color4(1, 1, 1, 0);
        particles.emitter = new BABYLON.AbstractMesh('emitter', scene);
        const emitter = particles.emitter as BABYLON.AbstractMesh;
        emitter.position = new BABYLON.Vector3(0, 0, 0);
        particles.createBoxEmitter(BABYLON.Vector3.UpReadOnly, BABYLON.Vector3.UpReadOnly, new BABYLON.Vector3(-200, -50, 10), new BABYLON.Vector3(200, 200, 500));
        particles.start();

        const camera = new BABYLON.FreeCamera('camera', new BABYLON.Vector3(0, 0, 0), scene);
        // camera.attachControl(canvas, true);
        new BABYLON.PassPostProcess("Scene copy", 0.4, camera);

        const light = new BABYLON.DirectionalLight('light1', new BABYLON.Vector3(-1, -1, 0), scene);
        light.intensity = 0.5;
        scene.ambientColor = BABYLON.Color3.White();
        scene.clearColor = HOME_BABYLON_PINK.toColor4();

        const groundMaterial = new BABYLON.StandardMaterial("groundMaterial", scene);
        groundMaterial.ambientColor = HOME_BABYLON_PINK;
        groundMaterial.alpha = 1;
        groundMaterial.alphaMode = BABYLON.Material.MATERIAL_ALPHABLEND;

        // run the render loop
        engine.runRenderLoop(function () {
            scene.render();
        });

        // the canvas/window resize event handler
        window.addEventListener('resize', function () {
            engine.resize();
        });

        BABYLON.SceneLoader.LoadAssetContainerAsync('/models/', 'hyalikobasic.obj', scene).then(assetContainer => {
            const meshes = assetContainer.meshes;
            const mesh = meshes[0] as BABYLON.Mesh;
            mesh.material = groundMaterial;
            mesh.position.z = 10;
            mesh.isVisible = false;
            const playerFace = BABYLON.Mesh.CreateDisc(`playerFace`, 0.56, 16, scene);
            playerFace.isVisible = false;
            playerFace.rotation.y = Math.PI;
            playerFace.rotation.z = Math.PI;
            playerFace.position.y -= 0.1;
            playerFace.position.z += 1;
            playerFace.scaling = new BABYLON.Vector3(-1, 1, 1);
            const faceMaterials: { [key: string]: BABYLON.StandardMaterial } = {};
            [1, 2, 3, 4].forEach(i => {
                const faceLabel = `face${i}`;
                const playerFaceMaterial = new BABYLON.StandardMaterial(`playerFaceMaterial${faceLabel}`, scene);
                const playerFaceTexture = new BABYLON.Texture(`/textures/${faceLabel}.png`, scene, true);
                playerFaceTexture.hasAlpha = true;
                playerFaceMaterial.diffuseColor = BABYLON.Color3.White();
                playerFaceMaterial.emissiveColor = BABYLON.Color3.White();
                playerFaceMaterial.diffuseTexture = playerFaceTexture;
                faceMaterials[faceLabel] = playerFaceMaterial;
            });
            playerFace.material = faceMaterials['face1'];
            playerFace.parent = mesh;
            mesh.addChild(playerFace);
            mesh.rotation.y = Math.PI;
            assetContainer.addAllToScene();
            const instances: { mesh: BABYLON.InstancedMesh, speed: number, rotSpeed: number }[] = [];
            for (let i = 0; i < 100; i++) {
                const instance = mesh.createInstance("i" + i);
                const faceInstance = playerFace.createInstance("i" + i);
                faceInstance.parent = instance;
                instance.position.x = 100 - (Math.random() * 200);
                instance.position.y = -50 + (Math.random() * 150);
                instance.position.z = (Math.random() * 100);
                instances.push({ mesh: instance, speed: getRandomSpeed(), rotSpeed: getRandomRotationSpeed() });
            }
            scene.registerBeforeRender(() => {
                for (let i = 0; i < 100; i++) {
                    const instance = instances[i];
                    const mesh = instance.mesh;
                    if (mesh.position.y > 100) {
                        mesh.position.y = -50;
                        instance.speed = getRandomSpeed();
                        // instance.rotSpeed = getRandomRotationSpeed();
                    } else {
                        mesh.position.y += instance.speed;
                        instance.speed += 0.0001;
                        // mesh.rotation.y += instance.rotSpeed;
                        // mesh.rotation.z += instance.rotSpeed * 0.5;
                    }
                }
            });
        });
    }, []);

    // const images = [];
    // for (let i = 0; i < 100; i++) {
    //     images.push((<img src={`https://api.hyaliko.com/hyaliko/images/${i}`} width="125" height="125" alt="blah"></img>))
    // }
    // images.sort((a, b) => Math.random() - 0.5)
    return (
        <>
            <canvas id="aboutRenderCanvas"></canvas>
            <div className="container about-container">
                <div className="home-header opacity-transition" style={{ opacity: slideCount >= 1 ? 1 : 0, transform: slideCount >= 1 ? 'translate(0, 0)' : 'translate(0, -10px)' }}>
                    <div>
                    </div>
                    <span>
                        <a className="home-header-link" href="/">home</a>
                    </span>
                </div>
                <div className="inner-container about-inner-container">
                    <div className="about-section opacity-transition" style={{ opacity: slideCount >= 1 ? 1 : 0, transform: slideCount >= 1 ? 'translate(0, 0)' : 'translate(0, -10px)' }}>
                        <h2 className="home-subtitle about-title">these are the hyaliko.</h2>
                        <h2 className="home-subtitle">quantum particles imbued with sentience.</h2>
                        <h2 className="home-subtitle">existing here and nowhere, always and never.</h2>
                    </div>
                    <div className="about-section opacity-transition" style={{ opacity: slideCount >= 2 ? 1 : 0, transform: slideCount >= 2 ? 'translate(0, 0)' : 'translate(0, -10px)' }}>
                        <h2 className="home-subtitle about-title"><a href="https://opensea.io/collection/hyaliko-space-garden" target="_blank" rel="noreferrer">the hyaliko space garden</a></h2>
                        <h2 className="home-subtitle about-title">these are their dreams.</h2>
                        <h2 className="home-subtitle">the genesis hyaliko spaces.</h2>
                        <h2 className="home-subtitle">hidden away in higher dimensions.</h2>
                        <h2 className="home-subtitle">pre-minted upon conception.</h2>
                        <h2 className="home-subtitle">maximum supply of 265. current supply 185.</h2>
                    </div>
                    <div className="about-section opacity-transition" style={{ opacity: slideCount >= 3 ? 1 : 0, transform: slideCount >= 3 ? 'translate(0, 0)' : 'translate(0, -10px)' }}>
                        <h2 className="home-subtitle about-title"><a href="/mint" target="_blank">the hyaliko space factory</a></h2>
                        <h2 className="home-subtitle about-title">these are their creations.</h2>
                        <h2 className="home-subtitle">the hyaliko space variants.</h2>
                        <h2 className="home-subtitle">bridges between quantum hallucinations and the physical realm.</h2>
                        <h2 className="home-subtitle">maximum supply 2500.</h2>
                        <h2 className="home-subtitle about-title about-mint-button"><a className="important-link" href="/mint" target="_blank">available to mint now</a></h2>
                    </div>

                </div>
            </div>
            {/* <div className="container about-container" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                {images}
            </div> */}
        </>
    );
}

export default About;
