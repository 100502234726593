import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './SpaceFactory.css';

const numLines = 6;

function SpaceFactory() {
    const history = useHistory();
    const [slideCount, setSlideCount] = useState(0);
    useEffect(() => {
        setTimeout(() => {
            setSlideCount(1);
        }, 125);
        for (let i = 2; i < numLines; i++) {
            setTimeout(() => {
                setSlideCount(i);
            }, 500 * (i - 1));
        }
    }, []);
    return (
        <div className="container space-factory-container">
            <div className="inner-container space-factory-inner-container">
                <h1 className="space-factory-title opacity-transition" style={{ opacity: slideCount >= 1 ? 1 : 0, transform: slideCount >= 1 ? 'translate(0, 0)' : 'translate(0, -10px)' }}>welcome</h1>
                <h2 className="space-factory-subtitle opacity-transition" style={{ marginBottom: 64, opacity: slideCount >= 2 ? 1 : 0, transform: slideCount >= 2 ? 'translate(0, 0)' : 'translate(0, -10px)' }}>to the hyaliko space factory</h2>
                <h2 className="space-factory-subtitle opacity-transition" style={{ opacity: slideCount >= 3 ? 1 : 0, transform: slideCount >= 3 ? 'translate(0, 0)' : 'translate(0, -10px)' }}>the hyalikos have shared with us the <a href="https://opensea.io/collection/hyaliko-space-garden" target="_blank" rel="noreferrer">genesis hyaliko space archetypes</a></h2>
                <h2 className="space-factory-subtitle opacity-transition" style={{ marginBottom: 64, opacity: slideCount >= 3 ? 1 : 0, transform: slideCount >= 3 ? 'translate(0, 0)' : 'translate(0, -10px)' }}>and now they will forge the variants</h2>
                <h2 className="space-factory-subtitle opacity-transition" style={{ opacity: slideCount >= 4 ? 1 : 0, transform: slideCount >= 4 ? 'translate(0, 0)' : 'translate(0, -10px)' }}>they will forge no more than 50 variants of each space</h2>
                <h2 className="space-factory-subtitle opacity-transition" style={{ marginBottom: 64, opacity: slideCount >= 4 ? 1 : 0, transform: slideCount >= 4 ? 'translate(0, 0)' : 'translate(0, -10px)' }}>and no two spaces will be alike</h2>
                <button className="button big-button space-factory-begin opacity-transition" style={{ opacity: slideCount >= 5 ? 1 : 0, transform: slideCount >= 5 ? 'translate(0, 0)' : 'translate(0, -10px)' }} onClick={() => {
                    const soundtrackNode = document.getElementById('soundtrack') as HTMLAudioElement;
                    if (soundtrackNode && soundtrackNode.paused) {
                        soundtrackNode.play();
                    }
                    history.push('/mint/intro');
                }}>begin</button>
            </div>
        </div>
    );
}

export default SpaceFactory;