import React, { useContext, useEffect, useRef, useState } from 'react';
import './Curate.css';
import { AuthenticationModalContext, Web3AddressContext, Web3ModalContext } from './Web3Context';
import eye from './img/eye-slash.svg';
import caret from './img/caret-right-fill.svg';
import move from './img/arrows-move.svg';
import swap from './img/swap-canvases.svg';
import { NFT_BATCH_SIZE, NFT_LIMIT, NFT_SERVER_URL, UPDATE_CANVASES_URL, UPDATE_GALLERY_URL } from './constants';
import Modal from './Modal';
import InputModal from './InputModal';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ErrorModalContext } from './AppContext';
import { CurateContext } from './GalleryContext';

type CurateProps = {
    resolvedAddress: string,
    onChange: (value: boolean) => void,
    curateForm: any,
    setCurateForm: (value: any) => void,
    spacesForm: any,
    setSpacesForm: (value: any) => void,
    curateFormHasChanges: boolean,
    setCurateFormHasChanges: (value: boolean) => void,
    spacesFormHasChanges: boolean,
    setSpacesFormHasChanges: (value: boolean) => void
};

// const UPDATE_COLLABORATOR_URL = 'https://api.hyaliko.com/updateCollaborator';

const objectsForAddress = async (address: string, setCurateForm: (curateForm: any) => void) => {
    const curateForm: { [id: string]: any } = {};
    let offset = 0;
    let nftsRemaining = true;

    // Fetch all NFTs from OpenSea
    while (nftsRemaining) {
        const result = await fetch(`${NFT_SERVER_URL}?addresses=${address}&offset=${offset}`, {
            mode: 'cors',
            credentials: 'same-origin'
        });
        const json = await result.json();
        let nftBatch = json.nfts || [];
        offset += nftBatch.length;

        // const hiddenIds = hiddenIdsMap || {};
        nftBatch.forEach((nft: any) => {
            const nftId = `${nft.contract_address}:${nft.token_id}`;
            curateForm[nftId] = {
                name: nft.name || `${nft.collection_name}: ${nft.token_id}`,
                // hidden: !showNfts || hiddenIds[nftId]
                hidden: false
            }
        });
        setCurateForm({ ...curateForm });

        if (nftBatch.length < NFT_BATCH_SIZE) {
            nftsRemaining = false;
        }
    }
};


const getSpaceStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // styles we need to apply on draggables
    ...draggableStyle,
    opacity: isDragging ? 0.5 : 1
});

const getSpaceListStyle = (isDraggingOver: boolean) => ({
});

function Curate(props: CurateProps) {
    const { resolvedAddress: address, onChange, curateFormHasChanges, setCurateFormHasChanges, spacesFormHasChanges, setSpacesFormHasChanges, curateForm, setCurateForm, spacesForm, setSpacesForm } = props;
    const { curate, setCurate } = useContext(CurateContext);
    const [curationSections, setCurationSections] = useState([false, false, false, false, false]);
    const [addCollaboratorOpen, setAddCollaboratorOpen] = useState(false);
    const [addCollaboratorValue, setAddCollaboratorValue] = useState('');
    const addCollaboratorRef = useRef(null);
    const [saving, setSaving] = useState(false);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const { connectWeb3 } = useContext(Web3ModalContext);
    const { web3Address } = useContext(Web3AddressContext);
    const { authenticateWithModal } = useContext(AuthenticationModalContext);
    const { openErrorModal, closeErrorModal } = useContext(ErrorModalContext);
    const [collaboratorsForm, setCollaboratorsForm] = useState([]);
    const numDisplayedNFTs = Object.keys(curateForm).filter(curateId => !curateForm[curateId].hidden).length;
    const ownsGallery = web3Address && address && (web3Address.toLowerCase() === address.toLowerCase());
    // const isCollaborator = curate.collaborators.some(collaboratorAddress => collaboratorAddress.toLowerCase() === web3Address.toLowerCase());
    const isCollaborator = false;

    const reorder = (list: any[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };


    useEffect(() => {
        // If you don't own the gallery but you are a coollaborator, kick off request to fetch your nfts
        if (isCollaborator) {
            objectsForAddress(web3Address, setCurateForm);
        }
    }, [isCollaborator, web3Address, setCurateForm]);

    // useEffect(() => {
    //     setCollaboratorsForm(curate.collaborators);
    // }, [curate]);

    const addCollaborator = () => {
        setCollaboratorsForm([...collaboratorsForm, addCollaboratorValue]);
        setAddCollaboratorValue('');
        setAddCollaboratorOpen(false);
        if (!curateFormHasChanges) {
            setCurateFormHasChanges(true);
            onChange(true);
        }
    };

    const onSpaceDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }

        const newSpacesForm = reorder(
            spacesForm,
            result.source.index,
            result.destination.index
        );

        if (!spacesFormHasChanges) {
            setSpacesFormHasChanges(true);
            onChange(true);
        }

        setSpacesForm(newSpacesForm);
    };

    const onShowAll = () => {
        Object.keys(curateForm).forEach(curateId => curateForm[curateId].hidden = false);
        setCurateForm(Object.assign({}, curateForm));
        if (!curateFormHasChanges) {
            setCurateFormHasChanges(true);
            onChange(true);
        }
    };

    const onHideAll = () => {
        Object.keys(curateForm).forEach(curateId => curateForm[curateId].hidden = true);
        setCurateForm(Object.assign({}, curateForm));
        if (!curateFormHasChanges) {
            setCurateFormHasChanges(true);
            onChange(true);
        }
    };

    const curationContents = (
        <>
            {/* <div className="curation-section">
                <button className="curation-container-expand" onClick={() => { const newCurationSections = curationSections.slice(); newCurationSections[0] = !curationSections[0]; setCurationSections(newCurationSections); }}>add or remove collaborators</button>
                {curationSections[0] && (
                    <div className="curation-inner-section">
                        <button onClick={() => setAddCollaboratorOpen(true)}>add a collaborator</button>
                        {collaboratorsForm.map((collaborator, i) => (
                            <div key={collaborator} className="curation-collaborator-row"><div>{collaborator}</div><div><button onClick={e => {
                                collaboratorsForm.splice(i, 1); setCollaboratorsForm(collaboratorsForm.slice());
                                if (!hasChanges) {
                                    setHasChanges(true);
                                }
                            }}>remove</button></div></div>
                        ))}
                    </div>
                )}
            </div> */}
            <div className="curation-section">
                <button className="curation-container-expand" onClick={() => { const newCurationSections = curationSections.slice(); newCurationSections[1] = !curationSections[1]; setCurationSections(newCurationSections); }}>
                    <span>organize your art</span>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <span style={{ marginRight: 16 }}><div className={`curate-counter ${numDisplayedNFTs > NFT_LIMIT ? 'curate-counter-limit' : ''}`}>{numDisplayedNFTs} / {NFT_LIMIT}</div></span>
                        <img src={caret} alt="expand" style={{ transform: curationSections[1] ? 'rotate(90deg)' : '' }}></img>
                    </div>
                </button>
                {curationSections[1] && Object.keys(curateForm).length > 0 && (
                    <>
                        <div className="curation-inner-section">
                            <div className="curation-top-button-row">
                                <button className="button curation-show-all-button" onClick={onShowAll}>show all</button>
                                <button className="button curation-show-all-button" onClick={onHideAll}>hide all</button>
                            </div>
                            {Object.keys(curateForm).map(curateId => {
                                const curateItem = curateForm[curateId];
                                return (
                                    <div className={`curation-space-row ${curateItem.hidden ? 'curation-space-row-hidden' : ''}`} key={curateId}>
                                        <div className={`curation-space-row-inner ${curateItem.hidden ? 'curation-space-row-inner-hidden' : ''}`}>
                                            <img
                                                className={`curation-space-image ${curateItem.hidden ? 'curation-space-content-hidden' : ''}`}
                                                src={curateItem.imageThumbnailUrl}
                                                alt={curateItem.id}
                                                onError={({ currentTarget}) => { currentTarget.onerror = null; currentTarget.src="/icon.png" }}
                                            />
                                            <div className={`curation-space-text ${curateItem.hidden ? 'curation-space-content-hidden' : ''}`}>{curateItem.name}</div>
                                            <button className={`curate-space-checkbox ${curateItem.hidden ? 'curate-checkbox-hidden' : ''}`} onClick={() => {
                                                if (!(curateItem.hidden && numDisplayedNFTs >= NFT_LIMIT)) {
                                                    if (!curateFormHasChanges) {
                                                        setCurateFormHasChanges(true);
                                                        onChange(true);
                                                    }
                                                    curateForm[curateId].hidden = !curateItem.hidden;
                                                    setCurateForm(Object.assign({}, curateForm));
                                                }
                                            }}>{<img src={eye} alt="checkmark" width={20} height={20} />}</button>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </>
                )}
            </div>
            <div className="curation-section">
                <button className="curation-container-expand" onClick={() => { const newCurationSections = curationSections.slice(); newCurationSections[2] = !curationSections[2]; setCurationSections(newCurationSections); }}>
                    move canvases
                    <img src={caret} alt="expand" style={{ transform: curationSections[2] ? 'rotate(90deg)' : '' }}></img>
                </button>
                {curationSections[2] && (
                    <>
                        <div className="curation-inner-section curation-section-text" style={{ lineHeight: '36px' }}>to move canvases, approach a canvases and hit the <img src={move} style={{ backgroundColor: '#FF7CCF', padding: 6, borderRadius: 4, position: 'relative', top: 8, marginLeft: 4, marginRight: 4 }} alt="move" height="16" width="16"></img> button.<br></br><br></br>to swap the piece displayed in a given canvas, approach a canvas and hit the <img src={swap} alt="swap" height="16" width="16" style={{ backgroundColor: '#FF7CCF', padding: 6, borderRadius: 4, position: 'relative', top: 8, marginLeft: 4, marginRight: 4 }}></img> button.</div>
                    </>
                )}
            </div>
            <div className="curation-section">
                <button className="curation-container-expand" onClick={() => { const newCurationSections = curationSections.slice(); newCurationSections[3] = !curationSections[3]; setCurationSections(newCurationSections); }}>
                    organize your hyaliko spaces
                    <img src={caret} alt="expand" style={{ transform: curationSections[3] ? 'rotate(90deg)' : '' }}></img>
                </button>
                {curationSections[3] && Object.keys(spacesForm).length > 0 && (
                    <DragDropContext onDragEnd={onSpaceDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={getSpaceListStyle(snapshot.isDraggingOver)}
                                    className="curation-spaces"
                                >
                                    {spacesForm.map((space: any, index: number) => (
                                        <Draggable key={space.id} draggableId={space.id} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getSpaceStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                    className={`curation-space-row curation-space-row-hover ${spacesForm[index].hidden ? 'curation-space-row-hidden' : ''}`}
                                                >
                                                    <div className={`curation-space-row-inner ${spacesForm[index].hidden ? 'curation-space-row-inner-hidden' : ''}`}>
                                                        <img className={`curation-space-image ${spacesForm[index].hidden ? 'curation-space-content-hidden' : ''}`} src={space.image_url} alt={space.id}></img>
                                                        <div className={`curation-space-text ${spacesForm[index].hidden ? 'curation-space-content-hidden' : ''}`}>{space.name}</div>
                                                        <button className={`curate-space-checkbox ${spacesForm[index].hidden ? 'curate-checkbox-hidden' : ''}`} onClick={() => {
                                                            spacesForm[index].hidden = !spacesForm[index].hidden;
                                                            setSpacesForm(spacesForm.slice());
                                                            if (!spacesFormHasChanges) {
                                                                setSpacesFormHasChanges(true);
                                                                onChange(true);
                                                            }
                                                        }}>{<img src={eye} alt="checkmark" width={20} height={20} />}</button>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                )}
            </div>
            <div className="curation-section">
                <button className="curation-container-expand" onClick={() => { const newCurationSections = curationSections.slice(); newCurationSections[4] = !curationSections[4]; setCurationSections(newCurationSections); }}>
                    reset your hyaliko space
                    <img src={caret} alt="expand" style={{ transform: curationSections[4] ? 'rotate(90deg)' : '' }}></img>
                </button>
                {curationSections[4] && (
                    <div className="curation-inner-section" style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="curation-section-text" style={{ lineHeight: '36px' }}>
                            if things are looking strange or you just want a fresh start, reset your hyaliko space!<br></br><br></br>this will undo your canvas placements and your hyaliko space organization.
                        </div>
                        <button onClick={() => {
                            const authenticationCallback = async () => {
                                setSaving(true);
                                try {
                                    const [firstResponse, secondResponse] = await Promise.all([
                                        fetch(UPDATE_GALLERY_URL, {
                                            method: 'POST',
                                            mode: 'cors',
                                            credentials: 'include',
                                            cache: 'no-cache',
                                            body: JSON.stringify({
                                                address,
                                                spaces: null,
                                                hidden: null
                                            })
                                        }),
                                        await fetch(UPDATE_CANVASES_URL, {
                                            method: 'POST',
                                            mode: 'cors',
                                            credentials: 'include',
                                            cache: 'no-cache',
                                            body: JSON.stringify({
                                                address,
                                                canvases: null
                                            })
                                        })
                                    ]);
                                    if (firstResponse.status === 401 || secondResponse.status === 401) {
                                        setSaving(false);
                                        openErrorModal('error', 'looks like something went wrong while authenticating. please try again.', 'okay', () => {
                                            closeErrorModal();
                                        }, null, null);
                                    } else if (firstResponse.status !== 200 || secondResponse.status !== 200) {
                                        throw new Error();
                                    } else {
                                        setCurateFormHasChanges(false);
                                        setSpacesFormHasChanges(false);
                                        onChange(false);
                                        setSuccessModalOpen(true);
                                        setSaving(false);
                                    }
                                } catch {
                                    openErrorModal('error', 'looks like something went wrong while saving. please contact us on discord or twitter!', 'okay', () => {
                                        closeErrorModal();
                                        setSaving(false);
                                    }, null, null);
                                }
                            };
                            authenticateWithModal(web3Address, authenticationCallback, () => {
                                openErrorModal('error', 'looks like something went wrong while authenticating. please contact us on discord or twitter!', 'okay', closeErrorModal, null, null);
                            });
                        }} className="big-button menu-button curate-button" style={{ marginTop: 16, maxWidth: 240 }}>reset your curation</button>
                    </div>
                )}
            </div>
        </>
    );

    return (
        <>
            <div className="curate-outer-container">
                <div className={`menu-body curate-body ${saving ? 'curate-saving' : ''}`}>
                    {(ownsGallery || isCollaborator) ? curationContents : !web3Address ? (
                        <div className="curate-empty-state">
                            <button onClick={connectWeb3} className="big-button menu-button">sign in with web3</button>
                            <div className="curate-empty-state-text">to curate your gallery</div>
                        </div>
                    ) : (
                        <div className="curate-empty-state">
                            looks like you don't own this gallery!
                        </div>
                    )}
                </div>
                {(ownsGallery || isCollaborator) && <div className="curate-control">
                    <button className="big-button menu-button curate-button" disabled={!(spacesFormHasChanges || curateFormHasChanges) || numDisplayedNFTs > NFT_LIMIT} onClick={async () => {
                        const authenticationCallback = async () => {
                            setSaving(true);
                            // Only update what has changed
                            const body: any = {
                                address,
                                // collaborators: collaboratorsForm
                            };
                            if (curateFormHasChanges) {
                                body.hidden = Object.keys(curateForm).filter(curateId => curateForm[curateId].hidden).map(curateId => curateId);
                                Object.keys(curateForm).forEach(curateId => {
                                    curate.nfts[curateId].hidden = curateForm[curateId].hidden;
                                });
                                setCurate(Object.assign({}, curate));
                                // Reset canvas positions for newly hidden
                                fetch(UPDATE_CANVASES_URL, {
                                    method: 'POST',
                                    mode: 'cors',
                                    credentials: 'include',
                                    cache: 'no-cache',
                                    body: JSON.stringify({
                                        address,
                                        canvases: Object.keys(curateForm).filter(curateId => curateForm[curateId].hidden).map(curateId => ({ id: curateId }))
                                    })
                                });
                            }
                            if (spacesFormHasChanges) {
                                // Need space ID and whether or not its hidden
                                body.spaces = {};
                                spacesForm.forEach((space: any, i: number) => {
                                    body.spaces[space.id] = {
                                        id: space.id,
                                        order: i,
                                        hidden: space.hidden
                                    };
                                });
                            }
                            const bodyJson = JSON.stringify(body);
                            try {
                                const response = await fetch(UPDATE_GALLERY_URL, {
                                    method: 'POST',
                                    mode: 'cors',
                                    credentials: 'include',
                                    cache: 'no-cache',
                                    body: bodyJson
                                });
                                if (response.status === 401) {
                                    setSaving(false);
                                    openErrorModal('error', 'looks like something went wrong while authenticating. please try again.', 'okay', () => {
                                        closeErrorModal();
                                    }, null, null);
                                } else if (response.status !== 200) {
                                    throw new Error();
                                } else {
                                    setCurateFormHasChanges(false);
                                    setSpacesFormHasChanges(false);
                                    onChange(false);
                                    setSuccessModalOpen(true);
                                    setSaving(false);
                                }
                                // await fetch(UPDATE_COLLABORATOR_URL, {
                                //     method: 'POST',
                                //     mode: 'cors',
                                //     credentials: 'include',
                                //     cache: 'no-cache',
                                //     body: JSON.stringify({
                                //         gallery: address,
                                //         address: '0x9B6EE9a354E71e0311D329066231A1F549ae627B',
                                //         shown: Object.keys(curateForm).filter(curateId => curateForm[curateId].hidden).map(curateId => curateId)
                                //     })
                                // });

                            } catch {
                                openErrorModal('error', 'looks like something went wrong while saving. please contact us on discord or twitter!', 'okay', () => {
                                    closeErrorModal();
                                    setSaving(false);
                                }, null, null);
                            }
                        };
                        authenticateWithModal(web3Address, authenticationCallback, () => {
                            openErrorModal('error', 'looks like something went wrong while authenticating. please contact us on discord or twitter!', 'okay', closeErrorModal, null, null);
                        });
                    }}>{saving ? 'saving...' : 'save changes'}</button>
                </div>}
            </div>
            {/* eslint-disable-next-line no-restricted-globals */}
            <Modal isOpen={successModalOpen} title="curation saved successfully" text="refresh to see the changes" leftButtonTitle="refresh" leftButtonOnClick={() => location.reload()} rightButtonTitle="close" rightButtonOnClick={() => setSuccessModalOpen(false)} />
            <InputModal title="add a collaborator" placeholder="eth address or ens name" onChange={e => setAddCollaboratorValue(e.target.value)} value={addCollaboratorValue} isOpen={addCollaboratorOpen} leftButtonOnClick={() => { setAddCollaboratorOpen(false); setAddCollaboratorValue(''); }} leftButtonTitle="close" rightButtonOnClick={addCollaborator} rightButtonTitle="add" inputRef={addCollaboratorRef} />
        </>
    );
}

export default Curate;
