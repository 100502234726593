import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { BACKGROUND_COLOR_OPTIONS, COLOR_OPTIONS, PARTICLE_COLOR_OPTIONS, PARTICLE_SHAPE_FUNCTIONS, TOKEN_DATA } from './constants';
import './ViewSpace.css';

type ViewSpaceParams = {
    space: string,
    terrain: string,
    background: string,
    particleShape: string,
    particleColor: string
}

function ViewSpace() {
    // Refs
    const sceneRef: React.MutableRefObject<BABYLON.Scene> = useRef(null);
    const rotationPointRef: React.MutableRefObject<BABYLON.Vector3> = useRef(null);
    const { space: rawSpace, terrain: rawTerrain, background: rawBackground, particleShape: rawParticleShape, particleColor: rawParticleColor } = useParams() as ViewSpaceParams;
    const space = parseInt(rawSpace);
    let tokenId: number;
    if (space <= 4) {
        tokenId = space + 5;
    } else {
        tokenId = 15 + (((space - 5) * 10) + 1);
    }
    const terrain = parseInt(rawTerrain);
    const background = parseInt(rawBackground);
    const particleShape = parseInt(rawParticleShape);
    const particleColor = parseInt(rawParticleColor);
    // Mount Babylon
    useEffect(() => {
        // Get the canvas DOM element
        const canvas: HTMLCanvasElement = document.getElementById('spaceViewCanvas') as HTMLCanvasElement;
        // Load the 3D engine
        const engine = new BABYLON.Engine(canvas, false, {
            preserveDrawingBuffer: true, stencil: true, xrCompatible: false
        }, false);

        // Create a basic BJS Scene object
        const scene = new BABYLON.Scene(engine);
        // scene.debugLayer.show();
        sceneRef.current = scene;

        const camera = new BABYLON.FreeCamera('camera', new BABYLON.Vector3(0, 500, -800), scene);
        camera.attachControl(canvas, true);
        camera.rotation.x = 20 * (Math.PI / 180);
        // new BABYLON.PassPostProcess("Scene copy", 0.4, camera);

        const light = new BABYLON.DirectionalLight('light1', new BABYLON.Vector3(-1, -1, 0), scene);
        light.intensity = 0.5;
        scene.ambientColor = BABYLON.Color3.FromHexString(COLOR_OPTIONS[terrain]);
        scene.clearColor = BABYLON.Color3.FromHexString(BACKGROUND_COLOR_OPTIONS[background]).toColor4(1);

        const groundMaterial = new BABYLON.StandardMaterial("groundMaterial", scene);
        groundMaterial.ambientColor = BABYLON.Color3.FromHexString(COLOR_OPTIONS[terrain]);
        groundMaterial.alpha = 1;
        groundMaterial.alphaMode = BABYLON.Material.MATERIAL_ALPHABLEND;

        // Ambient particle system
        const particles = new BABYLON.ParticleSystem('particles', 2000, scene);
        particles.emitRate *= 1;
        particles.minLifeTime = 10;
        particles.maxLifeTime = 10;
        particles.maxScaleX = 30;
        particles.maxScaleY = 30;
        particles.minScaleX = 30;
        particles.minScaleY = 30;
        particles.minEmitPower = 300;
        particles.maxEmitPower = 320;
        particles.particleTexture = BABYLON.Texture.LoadFromDataString('particleTexture', PARTICLE_SHAPE_FUNCTIONS[particleShape](PARTICLE_COLOR_OPTIONS[particleColor]), scene, true, true, true, BABYLON.Texture.NEAREST_SAMPLINGMODE);
        particles.blendMode = BABYLON.ParticleSystem.BLENDMODE_MULTIPLYADD;
        particles.color1 = new BABYLON.Color4(1, 1, 1, 1);
        particles.color2 = new BABYLON.Color4(1, 1, 1, 1);
        particles.colorDead = new BABYLON.Color4(1, 1, 1, 0);
        particles.emitter = new BABYLON.AbstractMesh('emitter', scene);
        const emitter = particles.emitter as BABYLON.AbstractMesh;
        emitter.position = new BABYLON.Vector3(0, -300, 0);
        particles.createBoxEmitter(BABYLON.Vector3.UpReadOnly, BABYLON.Vector3.UpReadOnly, new BABYLON.Vector3(-6, -3, 0).scale(100), new BABYLON.Vector3(6, 3, 10).scale(100));

        // Abstract mesh to rotate
        const parentMesh = new BABYLON.AbstractMesh('parentMesh');

        // run the render loop
        engine.runRenderLoop(function () {
            scene.render();
        });

        // the canvas/window resize event handler
        window.addEventListener('resize', function () {
            engine.resize();
        });

        fetch(`/tokens/${tokenId}`)
            .then(res => res.json())
            .then(async tokenData => {
                // Start particles if this is the first space we're loading
                const particleEmitter = sceneRef.current.getMeshByName('emitter');
                const particles = particleEmitter.getConnectedParticleSystems()[0];
                const museumModel = tokenData.intermediateLevelSegment;
                const splitMuseumModelURL = museumModel.split('/');
                const rootURL = splitMuseumModelURL.slice(0, splitMuseumModelURL.length - 1).join('/') + '/';
                if (!particles.isStarted()) {
                    particles.start();
                }
                const assetContainer = await BABYLON.SceneLoader.LoadAssetContainerAsync(rootURL, splitMuseumModelURL[splitMuseumModelURL.length - 1], sceneRef.current);
                const meshes = assetContainer.meshes;
                const currentSpace = new BABYLON.AbstractMesh('currentSpace');
                const groundMaterial = sceneRef.current.getMaterialByName('groundMaterial');
                const displayTokenData = TOKEN_DATA[tokenId];
                meshes.forEach(mesh => {
                    mesh.material = groundMaterial;
                    mesh.parent = currentSpace;
                    mesh.scaling = new BABYLON.Vector3(tokenData.scale, tokenData.scale, tokenData.scale).scale(displayTokenData.scale);
                    mesh.position.y = displayTokenData.yPosition;
                });
                rotationPointRef.current = displayTokenData.rotationPoint.scale(tokenData.scale * displayTokenData.scale);
                currentSpace.parent = parentMesh;
                assetContainer.addAllToScene();
                let hasBegunRotation = false;
                setTimeout(() => {
                    const el = document.createElement('div');
                    el.id = "spaceInitialized";
                    document.getElementById('root').append(el);
                    scene.registerBeforeRender(() => {
                        if (rotationPointRef.current) {
                            parentMesh.rotateAround(rotationPointRef.current, BABYLON.Vector3.UpReadOnly, 0.01);
                            const yRot = parentMesh.rotationQuaternion.toEulerAngles().y;
                            if (hasBegunRotation && (yRot > 0)) {
                                const el = document.getElementById('spaceViewCanvas');
                                if (el) {
                                    el.remove();
                                }
                            }
                            if (!hasBegunRotation && yRot < 0) {
                                hasBegunRotation = true;
                            }
                        }
                    });
                }, 1000);
            });
    }, [space, terrain, background, particleShape, particleColor, tokenId]);

    return (
        <canvas id="spaceViewCanvas" className="space-view-canvas"></canvas>
    );
}

export default ViewSpace;